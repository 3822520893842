import { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const TableUsers = ({ listaUsuarios }) =>
    <Fragment>
        <div className="table-responsive">
            <table className="table table-striped animation_input">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Región</th>
                        <th scope="col">Comuna</th>
                        <th scope="col">Correo electrónico</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listaUsuarios.map((values, index) => <tr key={index}>
                            <td><span className={index % 2 == 0 ? "iniciales rounded-circle" : "iniciales rounded-circle blue-bg-directorio"} >{values.iniciales}</span></td>
                            <td>{values.nombreCompleto} <Link style={{ display: 'block', fontSize: '13px', textDecoration: 'none', color: '#999' }} to={`/detalle-usuario/${values.idDirectorio}`}>Ver perfil</Link></td>
                            <td>{values.region}</td>
                            <td>{values.comuna}</td>
                            <td>{values.email}</td>
                        </tr>)
                    }

                </tbody>
            </table>
        </div>
    </Fragment>



export default TableUsers;

TableUsers.propTypes = {
    listaUsuarios: PropTypes.array.isRequired,
}

TableUsers.defaultProps = {
    listaUsuarios: []
}