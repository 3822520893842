import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { faCircleNotch, faHeart, faHeartCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAddLikesGuardadosMutation, useDeleteLikesGuardadosMutation, useGetLikesGuardadosQuery } from "../../redux/features/ApiSlice";
import toast from "react-hot-toast";
import axios from "axios";
import axiosApi from "../../api/axios/axiosApi";
import { OFERTA_LIKES } from "../../api/axios/constants";
import SesionModal from "../convocatoriaAssets/SesionModal";


const OfertaHeader = ({ oferta }) => {
    const { idDirectorio } = useSelector((state) => state.userActive);
    let skip = false;
    if (!idDirectorio) {
        skip = true;
    }

    const { data: dataLikes = [] , isFetching} = useGetLikesGuardadosQuery(idDirectorio, { skip });
    const [addLikesGuardados] = useAddLikesGuardadosMutation();
    const [deleteLikesGuardados] = useDeleteLikesGuardadosMutation();

    const [load, setLoad] = useState(false);
    const [ofertaLike, setOfertaLike] = useState(0);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        setLoad(true)
        if (oferta && oferta.idOferta) {
            axiosApi.get(OFERTA_LIKES(oferta.idOferta), { cancelToken: cancelToken.token })
                .then(response => {
                    setLoad(false);
                    console.log(response)
                    setOfertaLike(response.data.data[0].total);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log("cancelado");
                    } else {
                        console.error(error)
                        setLoad(false);
                    }
                });

            return () => {
                cancelToken.cancel();
            }
        }

    }, [oferta])

    const handleLikeOferta = () => {
        if (idDirectorio) {
            const like = dataLikes.find(element => { return (element.idOferta === oferta.idOferta) })
            if (like) {
                setLoad(true)
                deleteLikesGuardados(like.idMeGusta).unwrap().then((response) => {
                    setOfertaLike(ofertaLike - 1)
                    setLoad(false)
                }).catch(error => {
                    setLoad(false)
                    toast.error(error.message)
                });
            } else {
                setLoad(true)
                addLikesGuardados({
                    id_oferta: oferta.idOferta,
                    id_directorio: idDirectorio
                }).unwrap().then((response) => {
                    setOfertaLike(ofertaLike + 1)
                    setLoad(false)
                }).catch(error => {
                    setLoad(false)
                    toast.error(error.message)
                });
            }
        } else {
            setOpen(true)
        }
    }

    return (<div className="not-dest">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <img className="not-dest-img" src={oferta.imagen} alt="" />
                </div>

                <div className="col-md-6">
                    <span className="t-dest" style={{ marginBottom: "5px" }}>{oferta.nombreOferta}</span>
                    <ul className="cat-of" style={{ marginBottom: "20px", overflow: "hidden" }}>
                        {oferta.categorias ? oferta.categorias.map((values, index) => <li key={index}><Link to={`/busqueda?categoria=${values.id_categoria}&modo_categoria=true`} className='cat-of-a'>{values.categoria}</Link></li>) : ""}
                    </ul>

                    <p className="p-dest " style={{ wordBreak: "break-word" }}>{oferta.descripcion}</p>
                    <div className="mt-2 ms-2 text-end">
                        <button className="btn btn-sm btn-danger" onClick={handleLikeOferta} disabled={load || isFetching}><FontAwesomeIcon icon={!load && !isFetching ? (dataLikes.length > 0 && dataLikes.find(element => { return (element.idOferta === oferta.idOferta) }) && idDirectorio) ? faHeartCircleCheck : faHeart : faCircleNotch} className={load || isFetching ? "fa-spin" : ""}></FontAwesomeIcon>  {load || isFetching ? "" : ofertaLike}</button>
                    </div>
                    <div className="ref-dest">
                        <img className="rounded-circle" src={oferta.imagen_perfil} alt="" />
                        <Link to={`/detalle-usuario/${oferta.idDirectorio}`} className="text-decoration-none">
                            <div>
                                <span>{oferta.creadoPor}</span>
                                {oferta.creadoPorEmail}
                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
        <SesionModal open={open} setOpen={setOpen} />

    </div>);
}

export default OfertaHeader;

OfertaHeader.propTypes = {
    oferta: PropTypes.object,
}

OfertaHeader.defaultProps = {
}