import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axiosApi from "../../api/axios/axiosApi";
import { OFERTA_RELACIONADOS_URI } from "../../api/axios/constants";
import ResultadoItem from "../busquedaAssets/ResultadoItem";

const OfertasRelacionadas = ({ id_region, id_oferta }) => {
    const [relacionados, setRelacionados] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (id_region) {
            axiosApi.get(OFERTA_RELACIONADOS_URI(id_region), { cancelToken: cancelToken.token }).then(response => {

                const myArray = response.data.data.filter(function (obj) {
                    return obj.idOferta != id_oferta;
                });
                setRelacionados(myArray);
                setLoading(false)
            }).catch(error => {
                if (axios.isCancel(error)) {
                    console.log("cancelado");
                } else {
                    console.error(error);
                }
            });
        }

        return () => {
            cancelToken.cancel();
        }

    }, [id_region, id_oferta])


    return (<div className="noticias">
        <div className="container">
            <div className="row">
                <div className="col-12 mt-4 mb-4">
                    <span className="t-not">Ofertas en la región</span>

                </div>
                {
                    loading ? <div className="mt-5 mb-5 ">
                        <div style={{ textAlign: "center" }} >
                            <Spinner ></Spinner>
                        </div>
                    </div> : relacionados.length == 0 ? <span>No hay más ofertas en la región</span> : relacionados.slice(0, 3).map((oferta, index) => {
                        return (<ResultadoItem
                            key={index}
                            id={oferta.idOferta}
                            img={oferta.imagen}
                            nombre={oferta.nombreOferta}
                            agente={oferta.tipoPerfil}
                            categoria={oferta.categoria}
                            region={oferta.region}

                        />)
                    })
                }







            </div>
        </div>
    </div>)
}

export default OfertasRelacionadas;