//TIPO_PERFIL
export const TIPO_USUARIO_PERSONA = "1";
export const TIPO_USUARIO_ORGANIZACION = "2";

//TIPO_CATEGORIA
export const TIPO_ONLINE = 1;
export const TIPO_DESCARGABLE = 2;
export const TIPO_ESPECIALIZADO = 3;

//ESTADO
export const ESTADO_ABIERTO_STRING = "abierto";

//ESTADO OFERTAS
export const ESTADO_ENVIADO = 1;
export const ESTADO_APROBADO = 2;
export const ESTADO_RECHAZADA = 3;


//ESTADO Convocatorias
export const CONVO_ESTADO_REVISION = "Revisión";
export const CONVO_ESTADO_APROBADO = "Aprobada";
export const CONVO_ESTADO_RECHAZADA = "Rechazada";


//ESTADO OFERTAS
export const ID_REPRO_VIDEO_CINE = 1;
export const ID_AMPLIFICACION = 2;
export const ID_ILUMINACION = 3;
export const ID_OTRO = 4;
export const ID_DIMENSIONES_OTRO = 7;

//ID TEXTOS
export const ID_POLITICAS_CONVOCATORIAS = 1;
export const ID_HOME_PRINCIPAL = 2;
export const ID_HOME_OFERTAS = 3;
export const ID_HOME_ARTISTAS = 4;
export const ID_HOME_CONVOCATORIAS = 5;
export const ID_HOME_CHILECULTURA = 6;
export const ID_DIRECTORIO = 7;
export const ID_CONVOCATORIAS = 8;
export const ID_CATALOGOS = 9;
export const ID_NOTICIAS = 10;
export const ID_CARTELERA_CHILECULTURA = 11;
export const ID_RECURSOS = 12;
export const ID_AYUDA = 13;
export const ID_VIDEO_PRESENTACION = 14;



//MAP
export const PAIS_STRING = ', Chile';

