import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import CarteleraBuscador from "./CarteleraBuscador";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import { EVENTOS_CULTURALES_URI } from "../../api/axios/constants";
import { Spinner } from "react-bootstrap";
import CarteleraList from "./CarteleraList";
import axiosApi from "../../api/axios/axiosApi";
import { ID_HOME_CHILECULTURA } from "../../constants/Constants";
import { useGetTextoQuery } from "../../redux/features/ApiSlice";

const Cartelera = (props) => {
  const [actividades, setActividades] = useState([]);
  const [region, setRegion] = useState("");
  const [mes, setMes] = useState("");
  const [nombre, setNombre] = useState("");
  const [disciplina, setDisciplina] = useState("");
  const [loading, setLoading] = useState(true);

  const {
    data: texto_chilecultura,
    isSuccess
  } = useGetTextoQuery(ID_HOME_CHILECULTURA);


  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    axiosApi
      .get(EVENTOS_CULTURALES_URI, { cancelToken: cancelToken.token })
      .then((response) => {

        const result = response.data?.data?.results || [];
        const resultFiltered = result.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        )
        response.data.data.results = resultFiltered;
        setActividades(response?.data?.data?.results);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("cancelado");
        } else {
          console.error(error);
          setLoading(false);
        }
      });
    return () => {
      cancelToken.cancel();
    };
  }, []);

  const searchHandle = () => {
    const cancelToken = axios.CancelToken.source();
    setLoading(true);


    const params = {
      categoria: disciplina,
      region: region,
      actividad: nombre,
      mes: mes,
    };
    Object.keys(params).forEach((k) => !params[k] && delete params[k]);

    axiosApi
      .get(EVENTOS_CULTURALES_URI, {
        cancelToken: cancelToken.token,
        params: params,
      })
      .then((response) => {
        const result = response.data?.data?.results || [];
        const resultFiltered = result.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        )
        response.data.data.results = resultFiltered;

        setActividades(response?.data?.data?.results);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("cancelado");
        } else {
          console.error(error);
          setLoading(false);
        }
      });
    return () => {
      cancelToken.cancel();
    };
  };

  return (
    <div className="elige">
      <div className="container">
        <div className="row">
          <div className="col">
            <span className="t1b">{isSuccess ? texto_chilecultura.data[0].titulo : ''}</span>
            <p className="p1b">{isSuccess ? texto_chilecultura.data[0].contenido : ''}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Link to="/carteleras" className="masofertas rounded">
              Más actividades{" "}
              <FontAwesomeIcon
                style={{ marginLeft: "10px" }}
                icon={faArrowRight}
              ></FontAwesomeIcon>
            </Link>
          </div>
        </div>

        <div className="row">
          <CarteleraBuscador
            setRegion={setRegion}
            setMes={setMes}
            setDisciplina={setDisciplina}
            setNombre={setNombre}
            searchHandle={searchHandle}
          />

          <div className="col-md-10">
            <div className="row">
              {loading ? (
                <div className="mt-5 mb-5 ">
                  <div style={{ textAlign: "center" }}>
                    <Spinner></Spinner>
                  </div>
                </div>
              ) : (
                <CarteleraList actividades={actividades}></CarteleraList>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cartelera;

Cartelera.propTypes = {
  arrayCarteleras: PropTypes.array.isRequired,
};

Cartelera.defaultProps = {
  arrayCarteleras: [],
};
