import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetPerfilesGuardadosDetailQuery } from "../../../redux/features/ApiSlice";
import SelectOrden from "../../busquedaAssets/SelectOrden";
import Paginacion from "../../utiles/Paginacion";

const PerfilesElementos = (props) => {
    const [arrayPerfiles, setArrayPerfiles] = useState("");
    const [currentPagePerfiles, setCurrentPagePerfiles] = useState(1);
    const [orderPerfiles, setOrderPerfiles] = useState('desc');
    const perPage = 6;

    const handlePageClickPerfiles = (data) => {
        const page = data.selected + 1;

        setCurrentPagePerfiles(page);
    }

    let skip = false;
    if (!props.user.idDirectorio) {
        skip = true;
    }
    const { data = [], isFetching, isLoading, isSuccess } = useGetPerfilesGuardadosDetailQuery(props.user.idDirectorio, { skip });

    useEffect(() => {
        if (isSuccess) {
            let queryPerf = [];
            data.forEach(element => {
                queryPerf.push(element)
            });
            const arrayOrder = queryPerf.sort((a, b) => (a.idDirectorio < b.idDirectorio) ? 1 : (a.idDirectorio > b.idDirectorio) ? -1 : 0);
            setArrayPerfiles(arrayOrder);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, data]);

    useEffect(() => {
        if (isSuccess && arrayPerfiles) {
            let order;
            if (orderPerfiles === "desc") {
                order = arrayPerfiles.sort((a, b) => (a.idDirectorio < b.idDirectorio) ? 1 : (a.idDirectorio > b.idDirectorio) ? -1 : 0);
            } else if (orderPerfiles === "asc") {
                order = arrayPerfiles.sort((a, b) => (a.idDirectorio > b.idDirectorio) ? 1 : (a.idDirectorio < b.idDirectorio) ? -1 : 0);
            }
            setArrayPerfiles([...order]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderPerfiles]);



    return (<div className="gris mt-4 rounded bIPerfilesGuardados">
        <div className="container" style={{ overflow: "hidden" }}>

            <div className="row">
                <div className="col">
                    <span className="t1g">Perfiles guardados</span>
                </div>
            </div>

            {
                arrayPerfiles.length === 0 && isSuccess ? <span style={{ color: "white" }}>No se encontraron perfiles guardados</span> : <div>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="row g-3" style={{ float: "right", marginTop: "-10px" }}>
                                {
                                    !arrayPerfiles || arrayPerfiles.length === 0 ? "" : <SelectOrden handleOrdering={setOrderPerfiles} order={orderPerfiles} styleOrder={{ color: 'white' }} />
                                }


                            </div>
                        </div>
                    </div>



                    <div className="row">
                        {isFetching || isLoading || !arrayPerfiles ? <Spinner variant="light" className="mx-auto mb-4" /> : arrayPerfiles.slice((currentPagePerfiles - 1) * perPage, currentPagePerfiles * perPage).map((values, index) => {
                            return (
                                <div className="col-md-4 mb-4 animation_input" key={index}>
                                    <Link to={`/detalle-usuario/${values.idDirectorio}`} className="oferta rounded">
                                        <img src={values.imagen_perfil} alt="" className="rounded-top" />
                                        <div className="ic">
                                            <span className="tipoc1 rounded">{values.tipo}</span>
                                            <div className="clear"></div>
                                            <span className="tc1">{values.nombre}</span>
                                            <span className="rc1">{values.region}</span>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                        {
                            !arrayPerfiles || arrayPerfiles.length === 0 ? "" : <Paginacion
                                pageCount={Math.ceil(arrayPerfiles.length / perPage)}
                                currentpage={currentPagePerfiles}
                                handlePageClick={handlePageClickPerfiles}
                            />

                        }

                    </div>
                </div>

            }

        </div>
    </div>)
}

export default PerfilesElementos;