import PropTypes from "prop-types";

const CarteleraItem = ({
  url,
  img,
  nombre,
  agente,
  fechaInicio,
  fechaFin,
  categoria,
}) => {
  return (
    <div className="col-md-4">
      <div className="oferta rounded">
        <a href={url} target="_blank" style={{ textDecoration: "none", outline: "none" }}>
          <img src={img} alt="" className="rounded-top" />
        </a>
        <div className="ic">
          <span className="tc1">{nombre}</span>
          <p>
            <em>{categoria}</em>
          </p>
          <span className="pc1">{agente}</span>

          <div className="row mt-2 cd">
            <div className="col-md-4">
              Fecha inicio: <br />
              {fechaInicio}
            </div>
            <div className="col-md-4">
              Fecha fin: <br />
              {fechaFin}
            </div>
            <div className="col-md-4">{}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarteleraItem;

CarteleraItem.propTypes = {
  id: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  agente: PropTypes.string.isRequired,
  fechaInicio: PropTypes.string.isRequired,
  fechaFin: PropTypes.string.isRequired,
  categoria: PropTypes.string.isRequired,
};

CarteleraItem.defaultProps = {
  id: 0,
  img: "",
  nombre: "",
  agente: "",
  fechaInicio: "",
  fechaFin: "",
  categoria: "",
};
