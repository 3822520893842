import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { useGetCategoriasQuery } from "../../redux/features/ApiSlice";
import ValidationError from "../utiles/ValidationError";

const FieldCategorias = (props) => {
    const {
        values: { categorias },
    } = useFormikContext();


    const {
        data: categoriasArray = [],
    } = useGetCategoriasQuery();

    return (
        <div className="card">
            <div className="card-header">
                <strong>Seleccionar Categoría</strong>  (Máximo 3 categorías)
            </div>
            <div className="card-body">
                <div className="row g-3">

                    {
                        categoriasArray?.data ? categoriasArray.data.map((categoria, index) => {
                            return (
                                <div className="col-md-4" key={index+"categoria"}>
                                    <div className="form-check">
                                        <label className="form-check-label" >
                                            <Field className="form-check-input" type="checkbox" name="categorias" value={categoria.idCategoria} {...props} checked={categorias.find((value) => categoria.idCategoria == value) ? true : false} />
                                            {categoria.categoria}
                                        </label>
                                    </div>
                                </div>
                            )
                        }) : ""
                    }

                </div>
                <ErrorMessage name="categorias" component={ValidationError} />
            </div>
        </div>
    )
}

export default FieldCategorias;