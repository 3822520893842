import { faAddressCard, faBookmark, faUser } from "@fortawesome/free-regular-svg-icons";
import { faAlignCenter, faBriefcase, faBullhorn, faCalendarCheck, faFileArrowUp, faHouseUser, faInfoCircle, faRightFromBracket, faTents, faVideo } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { USUARIO_GET_URI } from "../api/axios/constants";
import CambiarPass from "../components/componentsPrivateHome/CambiarPass";
import EditProfile from "../components/componentsPrivateHome/EditProfile";
import MisElementos from "../components/componentsPrivateHome/MisElementos";
import MisOfertas from "../components/componentsPrivateHome/MisOfertas";
import Perfil from "../components/componentsPrivateHome/Perfil";
import PrivateHome from "../components/componentsPrivateHome/PrivateHome";
import SubirOferta from "../components/componentsPrivateHome/SubirOferta";
import Convocatorias from "../components/componentsPrivateHome/Convocatorias";
import Titulo from "../components/utiles/Titulo";
import { TAB_BIOGRAFIA, TAB_CAMBIAR_PASS, TAB_CONVO, TAB_ELEMENTOS_GUARDADOS, TAB_ESPACIO_CULTURAL, TAB_INFO_PERSONAL, TAB_INICIO, TAB_LOGOUT, TAB_MIS_OFERTAS, TAB_MI_PERFIL, TAB_OFERTAS_PROGRAMACION, TAB_ORGANIZACION_DETALLE, TAB_SUBIR_OFERTA, TAB_VIDEO_PRESENTACION } from "../constants/ConstantsTabsPrivado";
import { setTitle } from "../constants/utiles/Functions";
import { userLogout } from "../redux/features/userActiveSlice";
import EspacioCulturalEdit from "../components/componentsPrivateHome/EspacioCulturalEdit";
import OrganizacionEdit from "../components/componentsPrivateHome/OrganizacionEdit";
import { TIPO_USUARIO_ORGANIZACION } from "../constants/Constants";
import OfertasProgramadas from "../components/componentsPrivateHome/OfertasProgramadas";
import BiografiaEdit from "../components/componentsPrivateHome/BiografiaEdit";
import VideoPresentacion from "../components/componentsPrivateHome/VideoPresentacion";



const HomePrivado = (props) => {
    const { userInfo } = useSelector((state) => state.userActive);
    const [user, setUser] = useState({});
    const { active_tab } = useParams();
    const dispatch = useDispatch();
    // Modal open state
    const [open, setOpen] = useState(false)



    const handleLogout = () => {
        const redirect_url_cu = encodeURIComponent(process.env.REACT_APP_FRONTEND_URL);
        const url_cu_logout = process.env.REACT_APP_URL_LOGOUT + "?redirect=" + redirect_url_cu;
        axios.get(url_cu_logout).then((response) => { console.log(response) }).catch((error) => console.log(error));
        setOpen(false)
        dispatch(userLogout())

        window.location.replace(url_cu_logout);

    }



    const handleUser = (data) => {
        setUser(data);
    }

    useEffect(() => {

        const cancelTokenGen = axios.CancelToken.source();
        if (userInfo.rut) {
            axiosApi.get(USUARIO_GET_URI(userInfo.rut), { cancelToken: cancelTokenGen.token })
                .then(response => {
                    setUser(response?.data?.data[0]);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log("cancelado");
                    } else {
                        console.error(error)
                    }
                });

        }

        return () => {
            cancelTokenGen.cancel();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);



    const handleTabChange = () => {
        switch (active_tab) {
            /* case TAB_INICIO:
                setTitle('Página Personal - Inicio');
                return <PrivateHome user={user} /> */
            case TAB_MI_PERFIL:
                setTitle('Página Personal - Mi perfil');
                return <Perfil user={user} />;
            case TAB_INFO_PERSONAL:
                setTitle('Página Personal - Información Personal');
                return <EditProfile user={user} handleUser={handleUser} />;
            case TAB_CAMBIAR_PASS:
                setTitle('Página Personal - Cambiar contraseña');
                return <CambiarPass user={user} />;
            case TAB_ELEMENTOS_GUARDADOS:
                setTitle('Página Personal - Elementos guardados');
                return <MisElementos user={user}></MisElementos>
            case TAB_OFERTAS_PROGRAMACION:
                setTitle('Página Personal - Ofertas Programadas');
                return <OfertasProgramadas user={user}></OfertasProgramadas>

            case TAB_MIS_OFERTAS:
                setTitle('Página Personal - Mis ofertas publicadas');
                return <MisOfertas user={user} />;
            case TAB_SUBIR_OFERTA:
                setTitle('Página Personal - Subir oferta');
                return <SubirOferta user={user} />;
            case TAB_CONVO:
                setTitle('Página Personal - Convocatorias');
                return <Convocatorias user={user} />;
            case TAB_ESPACIO_CULTURAL:
                setTitle('Página Personal - Detalles espacio cultural');
                return <EspacioCulturalEdit user={user} />;

            case TAB_ORGANIZACION_DETALLE:
                setTitle('Página Personal - Detalles organización');
                return <OrganizacionEdit user={user} />;

            case TAB_BIOGRAFIA:
                setTitle('Página Personal - Biografía o presentación');
                return <BiografiaEdit user={user} />;

            case TAB_VIDEO_PRESENTACION:
                setTitle('Página Personal - Video de presentación');
                return <VideoPresentacion user={user} />;
            default:
                return '';

        }

    }



    return (
        <Fragment>
            <Titulo>
                <div className="breadcrumbs">
                    <ul>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }}>Página personal</Link></li>
                    </ul>
                </div>

                <div className="clear"></div>
                <span className="t1b">Te damos la bienvenida a tu página personal del Catálogo Digital</span>

            </Titulo>



            <div className="gris2">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="personal rounded">
                                <div className="row">
                                    <div className="col-md-3">
                                        <ul id="personal-menu" className="animation_input">
                                            {
                                                /*   <li>
                                                      <NavLink className={active_tab === TAB_INICIO ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_INICIO}`}>
                                                          <FontAwesomeIcon icon={faHouseUser} style={{ marginLeft: '10px' }} /> Inicio
                                                      </NavLink>
                                                  </li> */
                                            }
                                            <li>
                                                <NavLink className={active_tab === TAB_MI_PERFIL ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_MI_PERFIL}`}>
                                                    <FontAwesomeIcon icon={faUser} style={{ marginLeft: '10px' }} /> Mi perfil
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className={active_tab === TAB_INFO_PERSONAL ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_INFO_PERSONAL}`}>
                                                    <FontAwesomeIcon icon={faAddressCard} style={{ marginLeft: '7px' }} /> Información personal
                                                </NavLink>
                                            </li>

                                            <li className="animation_input">
                                                <NavLink className={active_tab === TAB_BIOGRAFIA ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_BIOGRAFIA}`}>
                                                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '6px' }} /> Biografía
                                                </NavLink>
                                            </li>

                                            <li className="animation_input">
                                                <NavLink className={active_tab === TAB_VIDEO_PRESENTACION ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_VIDEO_PRESENTACION}`}>
                                                    <FontAwesomeIcon icon={faVideo} style={{ marginLeft: '6px' }} /> Video presentación
                                                </NavLink>
                                            </li>

                                            {
                                                //Menu solo para usuarios registrados como Organización
                                                user.idTipoPerfil == TIPO_USUARIO_ORGANIZACION ?
                                                    <li className="animation_input">
                                                        <NavLink className={active_tab === TAB_ORGANIZACION_DETALLE ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_ORGANIZACION_DETALLE}`}>
                                                            <FontAwesomeIcon icon={faBriefcase} style={{ marginLeft: '8px' }} /> Representante Legal
                                                        </NavLink>
                                                    </li> : ""

                                            }
                                            {
                                                //Menu solo para usuarios registrados como espacio cultural
                                                user.espacioCultural ?
                                                    <li className="animation_input">
                                                        <NavLink className={active_tab === TAB_ESPACIO_CULTURAL ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_ESPACIO_CULTURAL}`}>
                                                            <FontAwesomeIcon icon={faTents} style={{ marginLeft: '4px' }} /> Datos espacio cultural
                                                        </NavLink>
                                                    </li> : ""

                                            }




                                            {
                                                // Usuario sesion CU ahora puede cambiar la password interna

                                                <li className="animation_input">
                                                    <NavLink className={active_tab === TAB_CAMBIAR_PASS ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_CAMBIAR_PASS}`}>
                                                        <FontAwesomeIcon icon={faAddressCard} style={{ marginLeft: '5px' }} /> Cambiar contraseña
                                                    </NavLink>
                                                </li>

                                            }

                                            <li>
                                                <NavLink className={active_tab === TAB_ELEMENTOS_GUARDADOS ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_ELEMENTOS_GUARDADOS}`}>
                                                    <FontAwesomeIcon icon={faBookmark} style={{ marginLeft: '7px' }} /> Elementos guardados
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink className={active_tab === TAB_OFERTAS_PROGRAMACION ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_OFERTAS_PROGRAMACION}`}>
                                                    <FontAwesomeIcon icon={faCalendarCheck} style={{ marginLeft: '5px' }} /> Ofertas programadas
                                                </NavLink>
                                            </li>


                                            <li><NavLink className={active_tab === TAB_MIS_OFERTAS ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_MIS_OFERTAS}`}>
                                                <FontAwesomeIcon icon={faBullhorn} style={{ marginLeft: '6px' }} /> Mis ofertas
                                            </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className={active_tab === TAB_SUBIR_OFERTA ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_SUBIR_OFERTA}`}>
                                                    <FontAwesomeIcon icon={faFileArrowUp} style={{ marginLeft: '6px' }} /> Subir oferta
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className={active_tab === TAB_CONVO ? "btn-link current rounded" : "rounded"} to={`/home-privado/${TAB_CONVO}`}>
                                                    <FontAwesomeIcon icon={faAlignCenter} style={{ marginLeft: '5px' }} /> Convocatorias
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink className={active_tab === TAB_LOGOUT ? "btn-link current rounded" : "rounded"} onClick={() => { setOpen(true) }}>
                                                    <FontAwesomeIcon icon={faRightFromBracket} style={{ marginLeft: '6px' }} /> Cerrar sesión
                                                </NavLink>
                                            </li>



                                        </ul>
                                    </div>
                                    <div className="col-md-9">

                                        {handleTabChange()}

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Modal show={open} onHide={() => { setOpen(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>¡Aviso!</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Seguro que desea cerrar sesión?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleLogout}>
                        Cerrar sesión
                    </Button>
                    <Button variant="primary" onClick={() => { setOpen(false) }}>
                        Seguir navegando
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>



    );
}

export default HomePrivado;

HomePrivado.propTypes = {
}

HomePrivado.defaultProps = {
}