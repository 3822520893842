import { faDownload, faGlobe, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { CATALOGOS_URI } from "../api/axios/constants";
import Paginacion from "../components/utiles/Paginacion";
import Titulo from "../components/utiles/Titulo";
import { ID_CATALOGOS, TIPO_DESCARGABLE, TIPO_ESPECIALIZADO, TIPO_ONLINE } from "../constants/Constants";
import {
    setTitle, truncateString
} from "../constants/utiles/Functions";
import { useGetCategoriasCatalogoQuery, useGetTextoQuery } from "../redux/features/ApiSlice";
import Image from "../components/utiles/Image";

const Catalogos = (props) => {
    const [listadoOnline, setListadoOnline] = useState([]);
    const [listadoDescargable, setListadoDescargable] = useState([]);
    const [listadoEspecializado, setListadoEspecializado] = useState([]);
    const [listado, setListado] = useState([]);
    const [cargaPagination, setCargaPagination] = useState(false)


    const [subtitulo, setSubtitulo] = useState('Catálogos online');
    const [loading, setLoading] = useState(true);
    const [loadingCategoria, setLoadingCategoria] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [categoria, setCategoria] = useState("");

    const perPage = 9;

    const {
        data: texto_catalogos,
        isSuccess: isSuccessTexto
    } = useGetTextoQuery(ID_CATALOGOS);

    const { data: categorias, isSuccess } = useGetCategoriasCatalogoQuery();

    const handleTabChange = (index) => {
        setCargaPagination(true)
        setActiveTab(index);
        setCurrentPage(1);
        setCategoria('');


        if (index === TIPO_ONLINE) {
            setListado(listadoOnline);
            setSubtitulo('Catálogos online');
        } else if (index === TIPO_DESCARGABLE) {
            setListado(listadoDescargable);
            setSubtitulo('Catálogos descargables');
        } else {
            setListado(listadoEspecializado);
            setSubtitulo('Catálogos especializados');
        }



    }


    useEffect(() => {
        setCargaPagination(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cargaPagination]);


    useEffect(() => {
        setTitle(props.title)
        //API listaDirectorio
        const cancelToken = axios.CancelToken.source();
        axiosApi.get(CATALOGOS_URI(TIPO_ONLINE), { cancelToken: cancelToken.token }).then(response => {
            setListadoOnline(response?.data?.data)
            setListado(response?.data?.data)
            setLoading(false);
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log("cancelado");
            } else {
                console.error(error);
            }
        });


        const cancelTokenOrg = axios.CancelToken.source();
        axiosApi.get(CATALOGOS_URI(TIPO_DESCARGABLE), { cancelToken: cancelTokenOrg.token }).then(response => {
            setListadoDescargable(response?.data?.data)

        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log("cancelado");
            } else {
                console.error(error);
            }
        });


        const cancelTokenEsp = axios.CancelToken.source();
        axiosApi.get(CATALOGOS_URI(TIPO_ESPECIALIZADO), { cancelToken: cancelTokenEsp.token }).then(response => {
            setListadoEspecializado(response?.data?.data)

        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log("cancelado");
            } else {
                console.error(error);
            }
        });



        return () => {
            cancelToken.cancel();
            cancelTokenOrg.cancel();
            cancelTokenEsp.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageClick = (data) => {
        const page = data.selected + 1;

        setCurrentPage(page);
    }

    const handleCategoria = (id) => {
        setCurrentPage(1);
        setCategoria(id);
        if (id == '') {
            if (activeTab === TIPO_ONLINE) {
                setListado(listadoOnline);
            } else if (activeTab === TIPO_DESCARGABLE) {
                setListado(listadoDescargable);
            } else {
                setListado(listadoEspecializado);
            }
        } else {
            setLoadingCategoria(true);
            axiosApi.get(CATALOGOS_URI(activeTab), { params: { id_categoria: `${id}` } }).then(response => {
                setListado(response?.data?.data)
                setLoadingCategoria(false);
            }).catch(error => {
                if (axios.isCancel(error)) {
                    console.log("cancelado");
                } else {
                    console.error(error);
                }
            });
        }

    }

    return (
        <div>
            <Titulo>
                <div className="breadcrumbs">
                    <ul>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }} to="/catalogos">Cátalogos</Link></li>
                    </ul>
                </div>
                <div className="clear"></div>
                <span className="t1b">{isSuccessTexto ? texto_catalogos?.data[0].titulo : ''}</span>
                <p>{isSuccessTexto ? texto_catalogos?.data[0].contenido : ''}</p>
            </Titulo>



            {loading ? <div className="mt-5 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> :
                <div className="gris2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="filtros rounded" style={{ minHeight: 0 }}>
                                    <ul id="personal-menu">
                                        <li><a onClick={() => handleTabChange(TIPO_ONLINE)} className={activeTab === TIPO_ONLINE ? "current rounded " : "rounded"}> <span className="ms-1"><FontAwesomeIcon icon={faGlobe} /> Catálogos Online</span></a></li>
                                        <li><a onClick={() => handleTabChange(TIPO_DESCARGABLE)} className={activeTab === TIPO_DESCARGABLE ? "current rounded " : "rounded"}> <span className="ms-1"><FontAwesomeIcon icon={faDownload} /> Catálogos Descargables</span></a></li>
                                        <li><a onClick={() => handleTabChange(TIPO_ESPECIALIZADO)} className={activeTab === TIPO_ESPECIALIZADO ? "current rounded " : "rounded"}> <span className="ms-1"><FontAwesomeIcon icon={faStar} /> Catálogos Especializados</span></a></li>

                                    </ul>
                                </div>
                            </div>


                            <div className="col-md-9">
                                <div className="rb">

                                    <div className="rbl">
                                        <span>{subtitulo}</span>
                                    </div>
                                    <div className="rbr">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="inputPassword6" className="col-form-label" >Filtro de categoría</label>
                                            </div>
                                            <div className="col-auto">
                                                <select style={{ width: "250px" }} className="form-select form-select-sm" onChange={(e) => handleCategoria(e.target.value)} aria-label="Default select example" value={categoria}>
                                                    <option value=''>Ninguno</option>
                                                    {
                                                        isSuccess ? categorias.data.map((values, index) => <option key={index} value={values.idCatalogoCategoria}>{values.categoriaCatalogo}</option>) : ""
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="clear"></div>

                                {loadingCategoria ? <div className="mt-4  ">
                                    <div style={{ textAlign: "center" }} >
                                        <Spinner ></Spinner>
                                    </div>
                                </div> : <div>
                                    <div className="row mt-4">

                                        {

                                            listado.slice((currentPage - 1) * perPage, currentPage * perPage).map((values, index) => <div className="col-md-4 animation_input" key={(currentPage - 1) * 10 + index}>
                                                {
                                                    activeTab === TIPO_ONLINE || activeTab === TIPO_ESPECIALIZADO ?
                                                        <a href={values.link} className="noticia oferta rounded" style={{ padding: "10px", height: "auto" }} target="_blank">
                                                            <Image className="rounded mb-2" src={values.imagen} />
                                                            <span className="tit-not" style={{ height: "60px" }}>{truncateString(values.detalle, 40)}</span>
                                                            <div className="ref-not"><span className="rounded" style={{ marginLeft: "0" }}>{values.categoria}</span></div>
                                                        </a>
                                                        :
                                                        <a href={values.pdf} className="noticia oferta rounded" style={{ padding: "10px", height: "auto" }} target="_blank" >
                                                            <Image className="rounded mb-2" src={values.imagen} />
                                                            <span className="tit-not">{values.detalle}</span>
                                                            <div className="ref-not"><span className="rounded" style={{ marginLeft: "0" }}>{values.categoria}</span></div>
                                                        </a>
                                                }

                                            </div>)
                                        }



                                    </div>
                                    {!cargaPagination ?

                                        <Paginacion
                                            pageCount={Math.ceil(listado.length / perPage)}
                                            currentpage={currentPage}
                                            handlePageClick={handlePageClick}
                                        /> : ""
                                    }
                                </div>

                                }
                            </div>


                        </div>

                    </div>
                </div>
            }
        </div>



    )
}

export default Catalogos;