import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import * as Yup from 'yup';
import { toast } from "react-hot-toast";
import ValidationError from "../utiles/ValidationError";

import { Spinner } from "react-bootstrap";

import { GET_DETALLE_ORGANIZACION_URI, POST_DETALLE_ORGANIZACION_URI, PUT_DETALLE_ORGANIZACION_URI, } from "../../api/axios/constants";
import axiosApi from "../../api/axios/axiosApi";
import { TAB_MI_PERFIL } from "../../constants/ConstantsTabsPrivado";
import { useNavigate } from "react-router";
import { format, validate } from 'rut.js';
import { handleInputRut } from "../../constants/utiles/Functions";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrganizacionEdit = ({ user }) => {
    const [userLoad, setUserLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [detalle, setDetalle] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (user.idDirectorio) {
            axiosApi.get(GET_DETALLE_ORGANIZACION_URI(user.idDirectorio))
                .then(response => {

                    if (response.data.error === false) {
                        setDetalle(response.data.data[0])
                        setIsUpdate(true);
                    }
                    setLoading(false);
                    setUserLoad(false);
                }).catch(error => {
                    setLoading(false)
                })
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);



    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const initialValues = {
        nombre_representante_legal: detalle.nombreRepresentanteLegal || "",
        nombre_organizacion: detalle.nombreOrganizacion || "",
        rut_organizacion: detalle.rutOrganizacion || "",
        personalidad_juridica: detalle.personalidadJuridica ? "1" : "0",
    };


    const validationSchema = Yup.object({
        nombre_representante_legal: Yup.string().required('Campo requerido').max(200, 'El texto ingresado no debe sobrepasar los 200 caracteres'),
        nombre_organizacion: Yup.string().required('Campo requerido').max(200, 'El texto ingresado no debe sobrepasar los 200 caracteres'),
        personalidad_juridica: Yup.string(),
        rut_organizacion: Yup.string().required('Campo requerido').test('rut-formato', 'Rut con formato invalido',
            (value) => {
                return validate(value)
            }
        ),

    })

    const handleOnSubmit = (values) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');
        const data = {
            nombre_representante_legal: values.nombre_representante_legal,
            nombre_organizacion: values.nombre_organizacion,
            rut_organizacion: values.rut_organizacion,
            personalidad_juridica: values.personalidad_juridica ? "true" : "false",
        }

        let query;
        if (isUpdate) {
            query = axiosApi.put(PUT_DETALLE_ORGANIZACION_URI(detalle.idRepresentante), data)
        } else {
            query = axiosApi.post(POST_DETALLE_ORGANIZACION_URI(user.idDirectorio), data)
        }

        query.then(response => {
            if (response.data.error === false) {
                toast.success(response.data.message, {
                    id: toastId,
                });
                navigate('/home-privado/' + TAB_MI_PERFIL);
            } else {
                toast.error('Ocurrio un error al intentar ingresar la oferta, intente nuevamente', {
                    id: toastId,
                });
            }

            setLoading(false)
        }).catch(error => {
            toast.error('Ocurrio un error al intentar ingresar la oferta, intente nuevamente', {
                id: toastId,
            });
            setLoading(false)
        })



    }




    return (<div className="col-md-9">

        <div className="rb">
            <div className="rbl mb-4">
                <span>Datos de Representante Legal </span>
            </div>
        </div>

        <div className="clear"></div>

        {
            userLoad ? <div className="mt-1 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> :

                <div className="animation_input">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}

                    >
                        {props => (
                            <Form className="">
                                <div>
                                    <div className="row mb-2">
                                        <label htmlFor="nombre_representante_legal" className="col-sm-5 col-form-label">Nombre del representante legal <span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="nombre_representante_legal" name='nombre_representante_legal' />
                                        </div>
                                        <ErrorMessage name="nombre_representante_legal" component={ValidationError} />
                                    </div>
                                    <div className="row mb-2">
                                        <label htmlFor="nombre_organizacion" className="col-sm-5 col-form-label">Nombre de la organización <span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="nombre_organizacion" name='nombre_organizacion' />
                                        </div>
                                        <ErrorMessage name="nombre_organizacion" component={ValidationError} />
                                    </div>
                                    <div className="row mb-2">
                                        <label htmlFor="rut_organizacion" className="col-sm-5 col-form-label">Rut de la organización <span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field type="rut" className="form-control" id="rut_organizacion" name="rut_organizacion" aria-describedby="rutHelp" maxLength={13}
                                                placeholder="Ej: 12.345.678-K"
                                                pattern="[0-9]{1,3}.[0-9]{3}.[0-9]{3}-[0-9Kk]{1}"
                                                onBlur={(event) => {
                                                    const formatted = format(props.values.rut_organizacion);
                                                    props.setFieldValue("rut_organizacion", formatted);
                                                    props.handleBlur(event);
                                                }}

                                                onKeyDown={handleInputRut}
                                            />
                                        </div>
                                        <ErrorMessage name="rut_organizacion" component={ValidationError} />
                                    </div>
                                    <div className="row mb-2">
                                        <label htmlFor="personalidad_juridica" className="col-sm-5 col-form-label">¿Cuenta con personalidad jurídica la organización a la cual representa? </label>
                                        <div className="col-sm-7">
                                            <Field name="personalidad_juridica" as="select" className="form-control" >
                                                <option value={'1'}>Si</option>
                                                <option value={'0'}>No</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="personalidad_juridica" component={ValidationError} />
                                    </div>



                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger btn-lg" disabled={loading}>Guardar <FontAwesomeIcon className='ms-1' icon={faArrowRight}></FontAwesomeIcon></button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div >
        }


        <div className="clear"></div>
    </div >)
}

export default OrganizacionEdit;