import PropTypes from "prop-types";
import CarteleraItem from "./CarteleraItem";

const CarteleraList = ({ actividades }) => {


  return actividades.length === 0
    ? <div className="mt-3">No se han encontrado coincidencias</div>
    : actividades
        .slice(0, 3)
        .map((value, index) => (
          <CarteleraItem
            key={index}
            url={value.url}
            img={value.image}
            nombre={value.name}
            agente={value.institution}
            fechaInicio={value.start_date}
            fechaFin={value.end_date}
            categoria={value.main_discipline}
          />
        ));
};

export default CarteleraList;

CarteleraList.propTypes = {
  actividades: PropTypes.array.isRequired,
};

CarteleraList.defaultProps = {
  actividades: [],
};
