import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import {
  DISCIPLINAS_CULTURALES_URI,
  REGIONES_CULTURALES_URI,
} from "../../api/axios/constants";
import { getMonths } from "../../constants/utiles/DateFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import axiosApi from "../../api/axios/axiosApi";

const FiltroForm = (params) => {
  const [regionForm, setRegionForm] = useState(params.region || "");
  const [mesForm, setMesForm] = useState(params.mes || "");
  const [nombreForm, setNombreForm] = useState(params.nombre || "");
  const [disciplinaForm, setDisciplinaForm] = useState(params.disciplina || "");

  const [regiones, setRegiones] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [loading, setLoading] = useState(true);
  const months = getMonths();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    axios
      .all([
        axiosApi.get(REGIONES_CULTURALES_URI, {
          cancelToken: cancelToken.token,
        }),

        axiosApi.get(DISCIPLINAS_CULTURALES_URI, {
          cancelToken: cancelToken.token,
        }),
      ])

      .then(
        axios.spread((response1, response2) => {
          setRegiones(response1.data?.data);
          setDisciplinas(response2.data?.data);

          setLoading(false);
        })
      )
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("cancelado");
        } else {
          console.error(error);
          setLoading(false);
        }
      });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const handleLimpiar = () => {
    setRegionForm("");
    setMesForm("");
    setNombreForm("");
    setDisciplinaForm("");

    params.handleBuscar("", "", "", "");
  };

  const handleSubmit = () => {
    params.handleBuscar(regionForm, mesForm, nombreForm, disciplinaForm);
  };

  const handleTags = (regionTag, mesTag, disciplinaTag) => {
    return (
      <ul>
        {regionTag != "" ? (
          <li className="rounded">
            {regionTag}{" "}
            <a
              onClick={() => removeTag("region")}
              style={{ cursor: "pointer" }}
            >
              x
            </a>
          </li>
        ) : (
          ""
        )}
        {mesTag !== "" ? (
          <li className="rounded">
            {months[mesTag - 1].charAt(0).toUpperCase() +
              months[mesTag - 1].slice(1)}{" "}
            <a onClick={() => removeTag("mes")} style={{ cursor: "pointer" }}>
              x
            </a>
          </li>
        ) : (
          ""
        )}

        {disciplinaTag != "" ? (
          <li className="rounded">
            {disciplinaTag}{" "}
            <a
              onClick={() => removeTag("disciplina")}
              style={{ cursor: "pointer" }}
            >
              x
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
    );
  };

  const removeTag = (input) => {
    let regiontmp = regionForm;
    let disciplinatmp = disciplinaForm;
    let mestmp = mesForm;

    switch (input) {
      case "disciplina":
        setDisciplinaForm("");
        disciplinatmp = "";
        break;
      case "region":
        setRegionForm("");
        regiontmp = "";
        break;
      case "mes":
        setMesForm("");
        mestmp = "";
        break;
      default:
        break;
    }

    params.handleBuscar(regiontmp, mestmp, nombreForm, disciplinatmp);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="filtros rounded">
      <div className="ft">
        <span>
        <FontAwesomeIcon className='me-1' icon={faFilter}></FontAwesomeIcon> Filtros
        </span>
      </div>

      <div className="animation_input">
        <div
          className="loading_class"
          style={{ display: params.loading ? "" : "none" }}
        >
          <div style={{ position: "relative", top: "50%" }}>
            <div style={{ textAlign: "center" }}>
              <Spinner></Spinner>
            </div>
          </div>
        </div>
        <div className="ft2">
          <p>Palabra clave</p>
          <input
            type="text"
            className="form-control"
            name="palabra"
            id="palabra"
            placeholder="Texto a buscar"
            value={nombreForm}
            onChange={(e) => setNombreForm(e.target.value)}
            onKeyUp={handleKeyDown}
          />
        </div>
        <div className="ft2">
          <div className="mt-1">
            <button onClick={handleSubmit} type="button" className="btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={faFilter} /> Aplicar filtros</button>
            <button onClick={handleLimpiar} type="button" className="ft2l pe-auto btn btn-outline-secondary btn-sm"> Limpiar todo</button>
          </div>
          
          <div className="clear"></div>
          {handleTags(params.region, params.mes, params.disciplina)}

          <div className="clear"></div>
        </div>
        <div className="ft3">
          <select
            id="disciplinaCartelera"
            className="form-select "
            onChange={(e) => setDisciplinaForm(e.target.value)}
            value={disciplinaForm}
          >
            <option value={""}>Categorias</option>

            {disciplinas.map((disciplina, index) => (
              <option key={index} value={disciplina.name}>
                {disciplina.name}
              </option>
            ))}
          </select>

          <select
            id="mesCartelera"
            className="form-select"
            onChange={(e) => setMesForm(e.target.value)}
            value={mesForm}
          >
            <option value={""}>Mes</option>

            {months.map((values, index) => (
              <option value={index + 1} key={index}>
                {values.charAt(0).toUpperCase() + values.slice(1)}
              </option>
            ))}
          </select>

          <select
            id="regionCartelera"
            className="form-select "
            onChange={(e) => setRegionForm(e.target.value)}
            value={regionForm}
          >
            <option value={""}>Región</option>

            {regiones.map((region, index) => (
              <option key={index} value={region.name}>
                {region.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FiltroForm;

FiltroForm.propTypes = {
  region: PropTypes.string.isRequired,
  mes: PropTypes.string.isRequired,
  disciplina: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

FiltroForm.defaultProps = {
  region: "",
  mes: "",
  disciplina: "",
  nombre: "",
  loading: false,
};
