import { Fragment, useState } from "react";
import ConvocatoriasInteresInicio from "./ConvocatoriasInteresInicio";

import { useGetConvoGuardadasDetailQuery } from "../../redux/features/ApiSlice";
import PerfilesElementos from "./MisElementosComponents/PerfilesElementos";

const PrivateHome = (props) => {

    let skip = false;
    if (!props.user.idDirectorio) {
        skip = true;
    }
    const { data = [], isLoading } = useGetConvoGuardadasDetailQuery(props.user.idDirectorio, { skip });

    return (
        <Fragment>
            <ConvocatoriasInteresInicio listaConvocatorias={data} isLoading={isLoading} />
            <PerfilesElementos user={props.user} />
        </Fragment>
    );
}


export default PrivateHome;

PrivateHome.propTypes = {
}

PrivateHome.defaultProps = {
}