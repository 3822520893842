import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DirConvocatoriaItem = ({ convocatoria }) => {
    const fecha_inicio = dayjs(convocatoria.fechaInicio, 'DD/MM/YYYY').locale("es");
    return (
        <div className="mb-3 animation_input col-md-4">
            <div className="convocatoria oferta rounded" style={{ margin: "0 5% 20px 5%", padding: "5px" }}>
                <Link style={{ textDecoration: "none" }} to={`/convocatoria/${convocatoria.idConvocatoria}`}>
                    <div className='ps-4'>
                        <div className="ct" style={{ minHeight: '120px' }}>
                            <div className="fecha rounded">
                                <span>{fecha_inicio.format('DD')}</span>
                                <p>{fecha_inicio.format('MMM')}</p>
                            </div>
                            <div className="info">
                                <span>{convocatoria.convocatoriaNombre}</span>
                                <p>{convocatoria.categoria}</p>
                            </div>
                        </div>
                        <div className="cb">

                            <span>Inicio</span>
                            <p>{convocatoria.fechaInicio} a las {convocatoria.HoraInicio}</p>


                            <span>Plazo</span>
                            <p>{convocatoria.fechaPlazo} a las {convocatoria.HoraFin}</p>



                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default DirConvocatoriaItem;

DirConvocatoriaItem.propTypes = {
    id: PropTypes.number.isRequired,
    fechaInicio: PropTypes.string.isRequired,
    horaInicio: PropTypes.string.isRequired,
    fechaPlazo: PropTypes.string.isRequired,
    horaPlazo: PropTypes.string.isRequired,
    estado: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    categoria: PropTypes.string.isRequired,
}

DirConvocatoriaItem.defaultProps = {
    id: 0,
    fechaInicio: '',
    horaInicio: '',
    fechaPlazo: '',
    horaPlazo: '',
    estado: '',
    nombre: '',
    categoria: '',
}