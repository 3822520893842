import { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { EVENTOS_CULTURALES_URI } from "../api/axios/constants";
import Titulo from "../components/utiles/Titulo";
import { setTitle } from "../constants/utiles/Functions";
import PropTypes from "prop-types";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

import FiltroForm from "../components/carteleraAssets/FiltroForm";


import ResultadoItem from "../components/carteleraAssets/ResultadoItem";
import Paginacion from "../components/utiles/Paginacion";
import axiosApi from "../api/axios/axiosApi";
import { ID_CARTELERA_CHILECULTURA } from "../constants/Constants";
import { useGetTextoQuery } from "../redux/features/ApiSlice";


const Carteleras = (props) => {
  const [resultadoBusqueda, setResultadoBusqueda] = useState([]);

  const [region, setRegion] = useState("");
  const [mes, setMes] = useState("");
  const [nombre, setNombre] = useState("");
  const [disciplina, setDisciplina] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const perPage = 9;


  const {
    data: texto_chilecultura,
    isSuccess: isSuccessTexto
  } = useGetTextoQuery(ID_CARTELERA_CHILECULTURA);




  useEffect(() => {
    setTitle(props.title);
    const cancelToken = axios.CancelToken.source();
    setLoading(true);
    axiosApi
      .get(EVENTOS_CULTURALES_URI, {
        cancelToken: cancelToken.token,
      })
      .then((response) => {
        const result = response.data?.data?.results || [];
        const resultFiltered = result.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        )
        response.data.data.results = resultFiltered;
        setResultadoBusqueda(response.data?.data);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("cancelado");
        } else {
          console.error(error);

          dispatch(setLoading(false));
        }
      });



    return () => cancelToken.cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBuscar = (regionForm, mesForm, nombreForm, disciplinaForm) => {
    setLoading(true);

    setCurrentPage(1);

    const params = {
      categoria: disciplinaForm,
      region: regionForm,
      actividad: nombreForm,
      mes: mesForm,
    };

    setRegion(regionForm);
    setDisciplina(disciplinaForm);
    setNombre(nombreForm);
    setMes(mesForm);

    Object.keys(params).forEach((k) => !params[k] && delete params[k]);

    axiosApi
      .get(EVENTOS_CULTURALES_URI, {
        params: params,
      })
      .then((response) => {
        const result = response.data?.data?.results || [];
        const resultFiltered = result.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        )
        response.data.data.results = resultFiltered;
        setResultadoBusqueda(response.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handlePageClick = (data) => {
    const page = data.selected + 1;

    setCurrentPage(page);
  };

  return (
    <Fragment>
      <Titulo>
        <div className="breadcrumbs">
          <ul>
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link
                className="bc"
                style={{ borderRight: "solid 0px #ccc !important" }}
                to="/carteleras"
              >
                Cartelera Chile_Cultura
              </Link>
            </li>
          </ul>
        </div>

        <div className="clear"></div>
        <span className="t1b">{isSuccessTexto ? texto_chilecultura?.data[0].titulo : ''}</span>
        <p>{isSuccessTexto ? texto_chilecultura?.data[0].contenido : ''}</p>
      </Titulo>

      <div className="gris2">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mb-md-4">
              <FiltroForm
                disciplina={disciplina}
                nombre={nombre}
                region={region}
                mes={mes}
                loading={loading}
                handleBuscar={handleBuscar}
              />
            </div>
            <div className="col-lg-9">
              <div className="rb">
                <div className="rbl">
                  <span>Resultados de búsqueda</span>
                  <p>{resultadoBusqueda.results?.length ?? 0} resultados</p>
                </div>
              </div>
              <div className="clear"></div>

              {loading ? (
                <div className="mt-4">
                  <div style={{ textAlign: "center" }}>
                    <Spinner></Spinner>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row mt-4">
                    {resultadoBusqueda.results != null
                      ? resultadoBusqueda.results
                        .slice((currentPage - 1) * perPage, currentPage * perPage)
                        .map((cartelera, index) => (
                          <ResultadoItem
                            key={(currentPage - 1) * 10 + index}
                            url={cartelera.url}
                            img={cartelera.image}
                            nombre={cartelera.name}
                            agente={cartelera.institution}
                            fechaInicio={cartelera.start_date}
                            fechaFin={cartelera.end_date}
                            categoria={cartelera.main_discipline}
                          />
                        ))
                      : ""}
                  </div>

                  <Paginacion
                    pageCount={Math.ceil(resultadoBusqueda.results?.length / perPage)}
                    currentpage={currentPage}
                    handlePageClick={handlePageClick}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Carteleras;

Carteleras.propTypes = {
  title: PropTypes.string,
};

Carteleras.defaultProps = {};
