import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faSquareFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import HeaderUser from "./HeaderUser";
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGetRedesSocialesQuery } from '../redux/features/ApiSlice';
import { Container, Form, Nav, Navbar } from 'react-bootstrap';

const Header = (props) => {

    const { data: data = [],
        isSuccess } = useGetRedesSocialesQuery()

    return (<header>
        <Navbar bg="light" expand="lg">
            <Container className="container" style={{ position: "relative" }}>
                <div className="lineas" style={{ margin: '-8px 0 0 0' }}>
                    <div className="azul"></div>
                    <div className="roja"></div>
                </div>
                <Navbar.Brand><NavLink className="navbar-brand" to="/">Catálogo digital</NavLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
                    <Nav className="d-lg-none .d-xl-block ">
                        <Nav.Link ><NavLink className="nav-link  pt-0 pb-0" to="/">Inicio</NavLink></Nav.Link>
                        <Nav.Link ><NavLink className="nav-link  pt-0 pb-0" to="/directorio">Directorio</NavLink></Nav.Link>
                        <Nav.Link ><NavLink className="nav-link  pt-0 pb-0" to="/convocatorias">Convocatorias</NavLink></Nav.Link>
                        <Nav.Link ><NavLink className="nav-link  pt-0 pb-0" to="/busqueda">Ofertas</NavLink></Nav.Link>
                        <Nav.Link ><NavLink className="nav-link  pt-0 pb-0" to="/catalogos">Catálogos</NavLink></Nav.Link>
                        <Nav.Link ><NavLink className="nav-link  pt-0 pb-0" to="/catalogo-noticias">Noticias</NavLink></Nav.Link>
                        <Nav.Link ><a className="nav-link  pt-0 pb-0" href="http://eligecultura.gob.cl/events" target={'_blank'} title="Cartelera Chile Cultura" rel="noreferrer">Cartelera</a></Nav.Link>
                    </Nav>

                    <hr className="d-lg-none .d-xl-block "></hr>

                    <Nav className="menu-top ">
                        <Nav.Link ><NavLink className="nav-link pt-0 pb-0" to='/ayuda/criterios' title="Criterios de ingreso.">Criterios</NavLink></Nav.Link>
                        <Nav.Link ><NavLink className="nav-link pt-0 pb-0" to="/recursos">Recursos</NavLink></Nav.Link>
                        <Nav.Link ><NavLink className="nav-link pt-0 pb-0" to="/ayuda/contacto">Ayuda</NavLink></Nav.Link>

                    </Nav>
                    <hr className="d-lg-none .d-xl-block "></hr>
                    <Nav className=" d-flex" >
                        <HeaderUser />
                        {
                            isSuccess ?
                                <div className='row g-3 ms-2 justify-content-end'>
                                    <div className="col-1 col-lg-3">
                                        <a href={data?.data[0]?.link} className="nav-link " target={'_blank'} rel="noreferrer">
                                            <FontAwesomeIcon icon={faInstagram} style={{ color: '#000' }} />
                                        </a>
                                    </div>
                                    <div className="col-1 col-lg-3">
                                        <a href={data?.data[1]?.link} className="nav-link" target={'_blank'} rel="noreferrer">
                                            <FontAwesomeIcon icon={faSquareFacebook} style={{ color: '#000' }} />
                                        </a>
                                    </div>
                                    <div className="col-1 col-lg-3">
                                        <a href={data?.data[2]?.link} className="nav-link" target={'_blank'} rel="noreferrer">
                                            <FontAwesomeIcon icon={faTwitter} style={{ color: '#000' }} />
                                        </a></div>
                                </div>
                                : ""
                        }


                    </Nav>

                </Navbar.Collapse>



            </Container>

        </Navbar>


    </header>)


}




export default Header;

Header.propTypes = {

}

Header.defaultProps = {

}