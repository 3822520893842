import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { truncateString } from "../../constants/utiles/Functions";
import Image from "../utiles/Image";
const OfertaItem = ({ id, img, nombre}) => {
    return (
        <div className="col-md-4 mb-4 animation_input" style={{ minHeight: "250px" }} >
            <Link to={`/oferta/${id}`} className="oferta rounded">
                <Image src={img} className={"rounded-top"}></Image>

                <div className="ic">
                    <div className="clear"></div>
                    <span className="tc1 ps-3">{truncateString(nombre, 45)}</span>

                </div>
            </Link>
        </div>
    );
}

export default OfertaItem;

OfertaItem.propTypes = {
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
}

OfertaItem.defaultProps = {
    id: 0,
    img: '',
    nombre: '',
}