import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from 'yup';
import axiosApi from "../../api/axios/axiosApi";
import { CAMBIAR_PASS_URI } from "../../api/axios/constants";
import ValidationError from "../utiles/ValidationError";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CambiarPass = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const initialValues = {
        pass: '',
        confirmPass: '',
    };


    const validationSchema = Yup.object({
        pass: Yup.string().required('Campo requerido'),
        confirmPass: Yup.string().required('Campo requerido').when("pass", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("pass")],
                "Las contraseñas ingresadas deben ser iguales"
            )
        }),
    })

    const handleOnSubmit = (values, { resetForm }) => {
        const toastId = toast.loading('Cargando...');
        setLoading(true)
        axiosApi.put(CAMBIAR_PASS_URI(user.idDirectorio), { password: values.pass }).then(response => {
            toast.success(response.data.message, {
                id: toastId,
            });

            if (response.data.error === false) {
                resetForm({ values: '' })
            }

            setLoading(false)

        }).catch(error => {
            toast.error('Ocurrió un error al cambiar la contraseña, intente nuevamente', {
                id: toastId,
            });

            setLoading(false)
        });


    }



    return (
        <div className="">

            <div className="rb">
                <div className="rbl mb-3">
                    <span>Cambiar Contraseña <a className="edit1" href="#"></a></span>
                </div>
            </div>

            <div className="clear"></div>
            <div className="  mb-4">
                <span>Cambiar contraseña interna de ingreso al sistema.</span>
            </div>
            <div className="animation_input">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleOnSubmit}

                >

                    <Form className="">
                        <div className="row mb-2">
                            <label htmlFor="pass" className="col-sm-5 col-form-label">Nueva Contraseña<span className="rojo">*</span></label>
                            <div className="col-sm-7">
                                <Field type="password" className='form-control' id="pass" name='pass' />
                            </div>
                            <ErrorMessage name="pass" component={ValidationError} />
                        </div>


                        <div className="row mb-2">
                            <label htmlFor="confirmPass" className="col-sm-5 col-form-label">Repita su nueva contraseña <span className="rojo">*</span></label>
                            <div className="col-sm-7">
                                <Field type="password" className='form-control' id="pass" name="confirmPass" />
                            </div>
                            <ErrorMessage name="confirmPass" component={ValidationError} />
                        </div>

                        <div className="col-12 offset-md-10">
                            <button type="submit" className="btn btn-danger btn-lg" disabled={loading}>Aceptar <FontAwesomeIcon className='ms-1' icon={faArrowRight}></FontAwesomeIcon></button>
                        </div>

                    </Form>
                </Formik>
            </div >


            <div className="clear"></div>
        </div >
    )
}

export default CambiarPass;