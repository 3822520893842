import { faCircleQuestion, faEnvelope, faFileInvoice } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment } from "react"
import { Link, NavLink, useNavigate, useParams } from "react-router-dom"
import Contacto from "../components/componentsAyuda/Contacto"
import Criterios from "../components/componentsAyuda/Criterios"
import Faq from "../components/componentsAyuda/Faq"
import Titulo from "../components/utiles/Titulo"
import { TAB_CONTACTO, TAB_CONTACTO_TEL, TAB_FAQ, TAB_OFICINAS, TAB_CRITERIOS } from "../constants/ConstantsTabsAyuda"
import { setTitle } from "../constants/utiles/Functions"
import { useGetTextoQuery } from "../redux/features/ApiSlice"
import { ID_AYUDA } from "../constants/Constants"

const Ayuda = () => {

    const { active_tab } = useParams();
    const navigate = useNavigate();

    const {
        data: texto_ayuda,
        isSuccess: isSuccessTexto
      } = useGetTextoQuery(ID_AYUDA);

    const handleTabChange = () => {
        switch (active_tab) {
            case TAB_CONTACTO_TEL:
                setTitle('Ayuda - Contacto telefónico');
                return <p>Contacto telefónico</p>
            case TAB_CONTACTO:
                setTitle('Ayuda - Contacto');
                return <Contacto />
            case TAB_OFICINAS:
                setTitle('Ayuda - Oficinas');
                return <p>Oficinas</p>
            case TAB_CRITERIOS:
                setTitle('Ayuda - Criterios de ingreso');
                return <Criterios />
            case TAB_FAQ:
                setTitle('Ayuda - FAQ');
                return <Faq />
            default:
                return '';

        }

    }

    return (<Fragment>
        <Titulo>
            <div className="breadcrumbs">
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }} to="/soporte">¿Necesitas ayuda?</Link></li>
                </ul>
            </div>
            <span className="t1b mb-4">{isSuccessTexto ? texto_ayuda?.data[0].titulo : ''}</span>
            <p className="p1b">{isSuccessTexto ? texto_ayuda?.data[0].contenido : ''}</p>
        </Titulo>

        <div className="menu2">
            <div className="container">
                <ul>
                    <li><NavLink to={`/ayuda/${TAB_CONTACTO}`} className={active_tab === TAB_CONTACTO ? " m2c" : ""}><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faEnvelope} ></FontAwesomeIcon> Contacto</NavLink></li>
                    <li><NavLink to={`/ayuda/${TAB_CRITERIOS}`} className={active_tab === TAB_CRITERIOS ? "m2c" : ""}><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faFileInvoice} ></FontAwesomeIcon> Criterios</NavLink></li>
                    <li><NavLink to={`/ayuda/${TAB_FAQ}`} className={active_tab === TAB_FAQ ? "m2c" : ""}><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faCircleQuestion} ></FontAwesomeIcon> FAQ</NavLink></li>
                </ul>
            </div>
        </div>


        <div className="gris2">
            <div className="container">

                <div className="row">
                    <div className="col">


                    </div>
                </div>

                {handleTabChange()}

            </div>
        </div>
    </Fragment>)
}
export default Ayuda;