import { Modal } from "react-bootstrap";
import { useGetTextoQuery } from "../../redux/features/ApiSlice";

const CondicionesModal = ({ open, setOpen, textId }) => {
    const {
        data,
        isSuccess
    } = useGetTextoQuery(textId);

    //TODO: Redux politicas
    return (<Modal show={open} onHide={() => { setOpen(false) }}  size="xl" scrollable>
        <Modal.Header closeButton>
            <Modal.Title>{isSuccess ? data?.data[0]?.titulo : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body >{isSuccess ? data?.data[0]?.contenido : ""} </Modal.Body>

    </Modal>)
}

export default CondicionesModal;