import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGetCategoriasQuery, useGetDuracionFuncionQuery, useGetEtiquetasOfertaQuery, useGetPreciosQuery, useGetPublicoQuery, useGetRegionesQuery, useGetTipoPerfilesQuery } from '../../redux/features/ApiSlice';
import InputComuna from '../form/InputComuna';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';


const FiltroForm = (params) => {
    const [categoria, setCategoria] = useState(params.categoria || '');
    const [region, setRegion] = useState(params.region || '');
    const [tipoPerfil, setTipoPerfil] = useState(params.agente || '');
    const [comuna, setComuna] = useState(params.comuna || '');
    const [palabra, setPalabra] = useState(params.palabra || '');
    const [rangoPrecio, setRangoPrecio] = useState(params.rangoPrecio || '');

    const [duracion, setDuracion] = useState(params.duracion || '');

    const [publico, setPublico] = useState(params.publicoObjetivo || '');
    const [mediacion, setMediacion] = useState(params.mediacion || '');
    const [etiqueta, setEtiqueta] = useState(params.etiqueta || '');

    const [reload, setReload] = useState(false);

    const navigate = useNavigate();

    useEffect(() => { if (reload === true) { handleSubmit(); setReload(false); } }, [reload])

    const {
        data: arrayCategorias = [],
        isSuccess: categoriasSuccess
    } = useGetCategoriasQuery();

    const {
        data: arrayPrecios = [],
        isSuccess: preciosSuccess,
    } = useGetPreciosQuery()

    const {
        data: regiones = [],
        isSuccess: regionesSuccess,
    } = useGetRegionesQuery();

    const {
        data: perfiles = [],
        isSuccess: perfilesSuccess,
    } = useGetTipoPerfilesQuery();

    const {
        data: duracion_funcion = [],
        isSuccess: duracion_fucnionSuccess,
    } = useGetDuracionFuncionQuery();

    const {
        data: publico_obj = [],
        isSuccess: publico_objSuccess,
    } = useGetPublicoQuery();

    const {
        data: etiquetas = [],
        isSuccess: etiquetasSuccess,
    } = useGetEtiquetasOfertaQuery();


    const handleLimpiar = () => {
        setCategoria('');
        setRegion('');
        setTipoPerfil('');
        setComuna('');
        setPalabra('');
        setRangoPrecio('');
        setDuracion('');
        setPublico('');
        setMediacion('');
        setEtiqueta('');

        setReload(true);
    }

    const handleSubmit = () => {
        let data = {}
        if (tipoPerfil) {
            data.agente = tipoPerfil;
        }
        if (categoria) {
            data.categoria = categoria;
        }
        if (region) {
            data.id_region = region;
        }
        if (comuna) {
            data.comuna = comuna;
        }
        if (palabra) {
            data.palabra = encodeURIComponent(palabra);
        }

        if (rangoPrecio) {
            data.rango_precio = rangoPrecio;
        }

        if (duracion) {
            data.duracion = duracion;
        }

        if (publico) {
            data.publico_obj = publico;
        }
        if (mediacion) {
            data.mediacion = mediacion;
        }

        if (etiqueta) {
            data.etiqueta = etiqueta;
        }


        params.setOrder('desc');
        navigate({
            pathname: "/busqueda",
            search: createSearchParams(data).toString()
        });
    }

    const handleTags = () => {

        return (<ul>
            {
                params.filtros ? params.filtros.map((values, index) => {
                    return <li key={index} className="rounded">{values.nombre} <a onClick={() => removeTag(values.input)} style={{ cursor: 'pointer' }}>x</a></li>
                }) : ""
            }
        </ul>)
    }

    const removeTag = (input) => {
        switch (input) {
            case "tipoPerfil":
                setTipoPerfil('');
                break;
            case "categoria":
                setCategoria('');
                break;
            case "region":
                setRegion('');
            case "comuna":
                setComuna('');
                break;
            case "rangoPrecio":
                setRangoPrecio('');
                break;
            case "duracion":
                setDuracion('');
                break;

            case "publicoObjetivo":
                setPublico('');
                break;
            case "mediacion":
                setMediacion('');
                break;
            case "etiqueta":
                setEtiqueta('');
                break;

            default:
                break;
        }

        setReload(true);

    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="filtros rounded" >


            <div className="ft">
                <span><FontAwesomeIcon className='me-1' icon={faFilter}></FontAwesomeIcon> Filtros</span>
            </div>
            <div className='animation_input'>
                <div className="loading_class" style={{ display: params.loading ? "" : "none" }}>
                    <div style={{ position: "relative", top: "50%" }}>
                        <div style={{ textAlign: "center" }} >
                            <Spinner ></Spinner>
                        </div>
                    </div>
                </div>
                <div className="ft2">
                    <p>Palabra clave</p>
                    <input type="text" className="form-control" name="palabra" id="palabra" placeholder="Texto a buscar" value={palabra} onChange={e => setPalabra(e.target.value)} onKeyUp={handleKeyDown} />
                </div>
                <div className="ft2">
                    <div className="mt-1">
                        <button onClick={handleSubmit} type="button" className="btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={faFilter} /> Aplicar filtros</button>
                        <button onClick={handleLimpiar} type="button" className="ft2l pe-auto btn btn-outline-secondary btn-sm"> Limpiar todo</button>
                    </div>
                    <div className="clear"></div>
                    {
                        handleTags()
                    }


                    <div className="clear"></div>
                </div>


                <div className="ft3">

                    <select className="form-select" aria-label="Default select example" value={tipoPerfil} onChange={e => { setTipoPerfil(e.target.value); }}>
                        <option value={''}>Tipo de perfil</option>
                        {
                            perfilesSuccess ? perfiles.data.map((perfil, index) =>
                                <option key={index}
                                    value={perfil.idTipoPerfil}
                                >{perfil.tipoPerfil}</option>
                            ) : ""
                        }
                    </select>

                    <select className="form-select" aria-label="Default select example" value={categoria} onChange={e => { setCategoria(e.target.value); }}>
                        <option value={''}>Categoría</option>
                        {
                            categoriasSuccess ? arrayCategorias.data.map((categorias, index) =>
                                <option key={index}
                                    value={categorias.idCategoria}
                                >{categorias.categoria}</option>
                            ) : ""
                        }
                    </select>

                    <select className="form-select" aria-label="Default select example" value={region} onChange={e => {
                        setRegion(e.target.value);
                        setComuna('');
                    }}>
                        <option value={''}>Región</option>
                        {
                            regionesSuccess ? regiones.data.map((values, index) =>
                                <option key={index}
                                    value={values.idRegion}
                                >{values.region}</option>
                            ) : ""
                        }

                    </select>

                    <InputComuna region={region} handleComuna={setComuna} comuna={comuna}></InputComuna>

                    <select className="form-select" aria-label="Default select example" value={rangoPrecio} onChange={e => {
                        setRangoPrecio(e.target.value);
                    }}>
                        <option value={''}>Rango de precio</option>
                        {
                            preciosSuccess ? arrayPrecios.data.map((rango, index) =>
                                <option key={index}
                                    value={rango.idRango}
                                >{rango.rango}</option>
                            ) : ""
                        }

                    </select>

                    <select className="form-select" aria-label="Default select example" value={duracion} onChange={e => {
                        setDuracion(e.target.value)

                    }}>
                        <option value={''}>Duración</option>
                        {
                            duracion_fucnionSuccess ? duracion_funcion.data.map((values, index) =>
                                <option key={index}
                                    value={values.idDuracion}
                                >{values.duracion}</option>
                            ) : ""
                        }
                    </select>


                    <select className="form-select" aria-label="Default select example" value={publico} onChange={e => {
                        setPublico(e.target.value)

                    }}>
                        <option value={''}>Público objetivo</option>
                        {
                            publico_objSuccess ? publico_obj.data.map((values, index) =>
                                <option key={index}
                                    value={values.idPublico}
                                >{values.publico}</option>
                            ) : ""
                        }
                    </select>

                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={mediacion}
                        onChange={(e) => {
                            setMediacion(e.target.value);
                        }}
                    >
                        <option value={""}>Mediación</option>
                        <option value={"1"}>Si</option>
                        <option value={"0"}>No</option>
                    </select>


                    <select className="form-select" aria-label="Default select example" value={etiqueta} onChange={e => {
                        setEtiqueta(e.target.value)

                    }}>
                        <option value={''}>Etiqueta</option>
                        {
                            etiquetasSuccess ? etiquetas.data.map((values, index) =>
                                <option key={index}
                                    value={values.idEtiqueta}
                                >{values.etiqueta}</option>
                            ) : ""
                        }
                    </select>
                </div>
            </div>

        </div>
    );
}

export default FiltroForm;

FiltroForm.propTypes = {
    arrayCategorias: PropTypes.array,
    loading: PropTypes.bool
}

FiltroForm.defaultProps = {
    arrayCategorias: [],
    loading: false
}