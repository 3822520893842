import { ErrorMessage, Field, useFormikContext } from "formik";
import { useGetDuracionFuncionQuery, useGetDuracionTotalQuery, useGetEspaciosExpositivosQuery, useGetEspaciosRequeridosQuery } from "../../../redux/features/ApiSlice";
import ValidationError from "../../utiles/ValidationError";
import { ID_DIMENSIONES_OTRO } from "../../../constants/Constants";

const CaracteristicasEspacios = (props) => {

    const {
        values: { requeridos,expositivo },
    } = useFormikContext();

    const {
        data: expositivosArray = [],
        isLoading: expositivosLoad,
    } = useGetEspaciosExpositivosQuery();

    const {
        data: requeridosArray = [],
        isLoading: requeridosLoad,
    } = useGetEspaciosRequeridosQuery();

    const {
        data: funcionArray = [],
        isLoading: funcionLoad,
    } = useGetDuracionFuncionQuery();

    const {
        data: totalArray = [],
        isLoading: totalLoad,
    } = useGetDuracionTotalQuery();


    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                {requeridosArray?.data ? requeridosArray.data.map((requerido, index) => {
                    return (<div className="col-md-4" key={index}>
                        <div className="form-check">

                            <label className="form-check-label" >
                                <Field className="form-check-input" type="checkbox" name="requeridos" value={requerido.idEspacio} {...props} checked={requeridos.find((value) => requerido.idEspacio == value) ? true : false} />
                                {requerido.espacioRequerido}
                            </label>
                        </div>
                    </div>)
                }) : ""}

                <div className="col-md-12">
                    <label htmlFor="especifiqueOtro" className="form-label">Especifique</label>
                    <Field type="text" className='form-control' id="especifiqueOtro" name='especifiqueOtro' />

                    <ErrorMessage name="especifiqueOtro" component={ValidationError} />
                </div>
                <div className="col-md-12">
                    <label htmlFor="expositivo" className="form-label">Si requiere de un espacio expositivo ¿De que tamaño? <span className="rojo">*</span></label>
                    <Field className='form-control' id="expositivo" name="expositivo" as='select' >
                        <option value=''>Seleccionar</option>
                        {
                            expositivosLoad ? "" : expositivosArray.data.map((espacio, index) => <option key={index} value={espacio.idExpositivo}>
                                {espacio.espacioExpositivo}
                            </option>)
                        }

                    </Field>
                    <ErrorMessage name="expositivo" component={ValidationError} />
                </div>


                {expositivo == ID_DIMENSIONES_OTRO ?
                    <div className="col-md-12">
                        <label htmlFor="dimensiones" className="form-label">Dimensiones de espacio expositivo <span className="rojo">*</span></label>
                        <Field type="text" className='form-control' id="dimensiones" name='dimensiones' />
                        <ErrorMessage name="dimensiones" component={ValidationError} />
                    </div> : ""
                }

                <div className="col-md-12">
                    <label htmlFor="duracion" className="form-label">En caso de ser una obra / presentación / película / etc. su duración por función es de: <span className="rojo">*</span></label>
                    <Field className='form-control' id="duracion" name="duracion" as='select' >
                        <option value=''>Seleccionar</option>
                        {
                            funcionLoad ? "" : funcionArray.data.map((value, index) => <option key={index} value={value.idDuracion}>
                                {value.duracion}
                            </option>)
                        }

                    </Field>
                    <ErrorMessage name="duracion" component={ValidationError} />
                </div>


                <div className="col-md-12">
                    <label htmlFor="duracionTotal" className="form-label">En caso de ser un seminario / taller /exposición su duración total es de: <span className="rojo">*</span></label>
                    <Field className='form-control' id="duracionTotal" name="duracionTotal" as='select' >
                        <option value=''>Seleccionar</option>
                        {
                            totalLoad ? "" : totalArray.data.map((value, index) => <option key={index} value={value.idDuracionTotal}>
                                {value.duracionTotal}
                            </option>)
                        }

                    </Field>
                    <ErrorMessage name="duracionTotal" component={ValidationError} />
                </div>
            </div>
        </div>
    </div>)

}

export default CaracteristicasEspacios;