import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import axiosApi from "../api/axios/axiosApi";
import { NOTICIAS_REL_URI, NOTICIA_GET_URI } from "../api/axios/constants";
import HeaderNoticias from "../components/catalogoNoticias/HeaderNoticias";
import ItemNoticia from "../components/catalogoNoticias/ItemNoticia";
import NoticiaAdjuntos from "../components/noticiaAssets/NoticiaAdjuntos";
import NoticiasRelacionadas from "../components/noticiaAssets/NoticiasRelacionadas";
import { getDateFormatNoticia } from "../constants/utiles/DateFunctions";
import { setTitle, truncateString } from "../constants/utiles/Functions";

const Noticia = (props) => {
    const { id_noticia } = useParams();
    const [loading, setLoading] = useState(true);
    const [noticia, setNoticia] = useState({})

    useEffect(() => {
        setTitle()
        const cancelToken = axios.CancelToken.source();
        axiosApi.get(NOTICIA_GET_URI(id_noticia), { cancelToken: cancelToken.token }).then(response => {
            setNoticia(response?.data?.data[0]);
            setLoading(false);

        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log("cancelado");
            } else {
                console.error(error);
                setLoading(false);
            }




        });
        return () => {
            cancelToken.cancel();
        }
    }, [id_noticia])


    return (
        <Fragment>
            {
                loading ? <div className="mt-5 mb-5 ">
                    <div style={{ textAlign: "center" }} >
                        <Spinner ></Spinner>
                    </div>
                </div> : <div className="animation_input">
                    <HeaderNoticias>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><NavLink to="/">Inicio</NavLink></li>
                                            <li><NavLink to="/catalogo-noticias">Noticias</NavLink></li>
                                            <li><NavLink className="bc-w" style={{ borderRight: "solid 0px #ccc !important" }} >{truncateString(noticia.titulo, 50)}</NavLink></li>
                                        </ul>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="not-dest" style={{ margin: "20px 0 0 0" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span className="t-dest">{noticia.titulo}</span>
                                            <p className="p-dest">{noticia.bajada}</p>
                                            <div className="info-dest mt-3">
                                                <span>{getDateFormatNoticia(noticia.fechaCreacion)} / {noticia.minutosLectura} min de lectura <span className="rounded"
                                                    style={{ backgroundColor: "#da355e", color: "#fff" }}>{noticia.categoria}</span></span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <img className="not-dest-img" src={noticia.imagen_destacada} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </HeaderNoticias>



                    <div className="container mt-4">
                        <div className="comparte">
                            <span>Comparte</span>
                            <ul>
                                <li><TwitterShareButton url={window.location.href} title={noticia.titulo} via={"culturas_cl"}> <Link><FontAwesomeIcon icon={faTwitter} /></Link> </TwitterShareButton> </li>
                                <li><FacebookShareButton url={window.location.href} quote={noticia.titulo}><Link><FontAwesomeIcon icon={faFacebook} /></Link></FacebookShareButton></li>
                                <li><LinkedinShareButton url={window.location.href} title={noticia.titulo} summary={noticia.bajada} source={"Ministerio de cultura"}><Link><FontAwesomeIcon icon={faLinkedin} /></Link></LinkedinShareButton></li>
                            </ul>
                            <div className="clear"></div>
                        </div>
                        <div className="cont-not">
                            <p className="fw-bold">{noticia.bajada}</p>
                            <div dangerouslySetInnerHTML={{ __html: noticia.cuerpo }}></div>

                            {
                                noticia.facebook || noticia.twitter || noticia.instagram ? <div>
                                    <span className="tit-3">Links / Sitios de interés</span>
                                    <ul className="int">
                                        {
                                            noticia.facebook ? <li><a className="rounded int-face" href={noticia.facebook} target="_blank"><FontAwesomeIcon icon={faFacebook} /> Facebook</a></li> : ""
                                        }
                                        {
                                            noticia.twitter ? <li><a className="rounded int-twitter" href={noticia.twitter} target="_blank"><FontAwesomeIcon icon={faTwitter} /> Twitter</a></li> : ""
                                        }
                                        {
                                            noticia.instagram ? <li><a className="rounded int-insta" href={noticia.instagram} target="_blank"><FontAwesomeIcon icon={faInstagram} /> Instagram</a></li> : ""
                                        }

                                    </ul>
                                </div>
                                    : ""
                            }


                            <div className="clear"></div>

                            <NoticiaAdjuntos id_noticia={id_noticia}></NoticiaAdjuntos>



                        </div>
                    </div>




                    <div className="gris2 mt-4">
                        <div className="container">
                            <NoticiasRelacionadas id_noticia={noticia.idNoticia} id_categoria={noticia.idCategoria}></NoticiasRelacionadas>


                        </div>
                    </div>
                </div>

            }


        </Fragment>
    );
}

export default Noticia;