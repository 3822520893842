/** @link Documentacion https://day.js.org/en/ */
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { es } from "dayjs/locale/es";

dayjs.extend(localeData);

dayjs.extend(customParseFormat);
export const FORMATE_DATETIME_LOCAL_FOR_INPUT = "YYYY-MM-DDTHH:mm";
export const FORMATE_DATETIME_FULL_YEAR_MONTH_DAY_HOURS_DAY_SECONDS =
  "DD/MM/YYYY HH:mm:ss";

/**
 * @description Compara que dateB es una fecha posterior a dateA
 * @param {string} dateA 'yyyy-mm-dd' example '2010-10-20'
 * @param {string} dateB 'yyyy-mm-dd' example '2010-10-21
 */
export const isBefore = (dateA, dateB) => {
  return dayjs(dateA).isBefore(dayjs(dateB));
};

export const getDateNowSubstractMonth = (
  substract = 1,
  formant = "YYYY-MM-DD"
) => {
  return dayjs().subtract(substract, "month").format(formant);
};

export const getDateNow = (formant = "YYYY-MM-DD") => {
  return dayjs().format(formant);
};

/**
 * @see https://day.js.org/docs/en/display/unix-timestamp
 * @param {string} date fecha en formato DateTimeLocal('YYYY-MM-DDTHH:mm' = '2022-01-05T15:09')
 * @returns {string} retorno string de fecha en timestamp formato unix(10 caracteres)
 */
export const dateTimeLocalToTimestamp = (date) => {
  return dayjs(date).unix();
};

/**
 * @description Formate fecha
 * SQL server yyyy-DD-mm hisu (Ojo: Sintaxis SQL SERVER DATE) a
 * Javascript DD/MM/YYYY HH:mm (Ojo: Sintaxis Javascript Date)
 * @param {string} dayWithoutFormat
 * @returns {string} dayFormatted
 */
export const getDateFormat = (
  dayWithoutFormat,
  format = "DD/MM/YYYY HH:mm"
) => {
  return dayjs(dayWithoutFormat).format(format);
};

export const getDateFormatNoticia = (date) => {
  const data = dayjs(date, "DD/MM/YYYY").locale("es").format("MMMM DD, YYYY");
  return data.charAt(0).toUpperCase() + data.slice(1);
};

export const getMonths = () => {
  dayjs.locale("es");
  return dayjs.months();
};

export const getYearsSinceStartYear = (startYear) => {
  const currentYear = dayjs().year();
  const years = [];
  startYear = startYear || 2023;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const getLastDayOfMonth = (month) => {
  const dateDay = dayjs().set("month", month - 1).endOf("month");
  return dateDay.format("YYYY-MM-DD");
};

export const getFirstDayOfMonth = (month) => {
  const dateDay = dayjs().set("month", month - 1).startOf("month");
  return dateDay.format("YYYY-MM-DD");
};
