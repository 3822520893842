import { Fragment, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link, useLocation } from "react-router-dom";
import { faShieldHalved, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleDown } from "@fortawesome/free-regular-svg-icons";
import TableUsers from "../components/directorioAssets/TableUsers";
import { setTitle } from "../constants/utiles/Functions";
import Titulo from "../components/utiles/Titulo";
import PropTypes from "prop-types";
import { DIRECTORIO_TIPO_URI } from "../api/axios/constants";
import axiosApi from "../api/axios/axiosApi";
import axios from "axios";
import {
  ID_DIRECTORIO,
  TIPO_USUARIO_ORGANIZACION,
  TIPO_USUARIO_PERSONA,
} from "../constants/Constants";
import ExportExcel from "../components/utiles/ExcelExport";
import { Spinner } from "react-bootstrap";
import Paginacion from "../components/utiles/Paginacion";
import FiltroForm from "../components/directorioAssets/FiltroForm";
import { useGetTextoQuery } from "../redux/features/ApiSlice";

const Directorio = (props) => {
  const [listadoPersonas, setListadoPersonas] = useState([]);
  const [listadoOrg, setListadoOrg] = useState([]);
  const [listado, setListado] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("");
  const [cargaPagination, setCargaPagination] = useState(false);
  const location = useLocation();
  const perPage = 30;
  const [activeTab, setActiveTab] = useState(
    location?.state?.tipo || TIPO_USUARIO_PERSONA
  );

  const [filtros, setFiltros] = useState({
    palabra: "",
    region: "",
    comuna: "",
    espacioCultura: "",
  });

  const [tags, setTags] = useState({});

  useEffect(() => {
    setTitle(props.title);
    //API listaDirectorio
    const cancelToken = axios.CancelToken.source();
    axiosApi
      .get(DIRECTORIO_TIPO_URI(TIPO_USUARIO_PERSONA), {
        cancelToken: cancelToken.token,
      })
      .then((response) => {
        setListadoPersonas(response?.data?.data);
        if (activeTab == TIPO_USUARIO_PERSONA) {
          setListado(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("cancelado");
        } else {
          console.error(error);
        }
      });

    const cancelTokenOrg = axios.CancelToken.source();
    axiosApi
      .get(DIRECTORIO_TIPO_URI(TIPO_USUARIO_ORGANIZACION), {
        cancelToken: cancelTokenOrg.token,
      })
      .then((response) => {
        setListadoOrg(response?.data?.data);

        if (activeTab == TIPO_USUARIO_ORGANIZACION) {
          setListado(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("cancelado");
        } else {
          console.error(error);
        }
      });
    return () => {
      cancelToken.cancel();
      cancelTokenOrg.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageClickPerfiles = (data) => {
    const page = data.selected + 1;

    setCurrentPage(page);
  };

  const handleTabChange = (index) => {
    setLoading(true);
    handleClearFilter();
    setCargaPagination(true);
    setOrder("");
    setActiveTab(index);
    setCurrentPage(1);

    if (index === TIPO_USUARIO_PERSONA) {
      setListado(listadoPersonas);
    } else if (index === TIPO_USUARIO_ORGANIZACION) {
      setListado(listadoOrg);
    }
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  useEffect(() => {
    setCargaPagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargaPagination]);

  const handleClearFilter = (clear = false) => {
    //TODO: Reemplazar resultado endpoint
    setFiltros({
      palabra: "",
      region: "",
      comuna: "",
    });

    setTags({});

    if (clear) {
      setLoading(true);
      setCurrentPage(1);

      if (activeTab === TIPO_USUARIO_PERSONA) {
        setListado(listadoPersonas);
      } else if (activeTab === TIPO_USUARIO_ORGANIZACION) {
        setListado(listadoOrg);
      }

      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  const handleFilter = (data) => {
    setLoading(true);
    axiosApi
      .get(DIRECTORIO_TIPO_URI(activeTab), {
        params: {
          texto_buscar: data.palabra,
          id_region: data.region,
          id_comuna: data.comuna,
          espacio_cultural: data.espacioCultura,
        },
      })
      .then((response) => {
        setListado(response?.data?.data);
        setLoading(false);
        setFiltros({
          palabra: response?.data?.textoBuscar,
          region: response?.data?.idRegion,
          comuna: response?.data?.idComuna,
          espacioCultura: response?.data?.espacio_cultural,
        });

        const response_tags = {};
        if (response?.data?.idRegion != null) {
          response_tags["region"] = response.data.region;
        }

        if (response?.data?.idComuna != null) {
          response_tags["comuna"] = response.data.comuna;
        }

        if (response?.data?.espacio_cultural != "" && typeof (response?.data?.espacio_cultural) != "undefined") {
          response_tags["espacioCultura"] =
            "Espacio cultural: " + response.data.texto_espacio;
        }

        setTags(response_tags);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("cancelado");
        } else {
          console.error(error);
        }
      });
  };

  const downloadExcel = () => {
    const arrayExport = listado.map((input) => {
      const result = {
        "Tipo de perfil": input.tipoUsuario,
        "Nombre Contacto": input.nombreCompleto,
        "Correo electronico": input.email,
        Telefono: input.telefono,
        Región: input.region,
        Comuna: input.comuna,
        "¿Corresponde a espacio cultural?": input.espacioCultural ? "Si" : "No",
        "Nombre Espacio Cultural": input.nombreEspacioCultural,
        "Tipo Espacio Cultural": input.tipoEspacioCultural,
        "Sitio Web": input.web,
        "Canal Youtube": input.youtube,
        Facebook: input.facebook,
        "Instagram ": input.instagram,
        "Otra RRSS": input.otraRrss,
      };

      if (activeTab == TIPO_USUARIO_ORGANIZACION) {
        result["Nombre del representante"] = input.nombreRepresentante;
        result["Nombre de la organización"] = input.nombreOrganizacion;
      }

      return result;
    });

    let tipo_usuario = "";

    if (activeTab == TIPO_USUARIO_PERSONA) {
      tipo_usuario = "Personas";
    } else {
      tipo_usuario = "Organizaciones";
    }

    ExportExcel(arrayExport, "Directorio-" + tipo_usuario, tipo_usuario);
  };

  const handleOrdering = (order) => {
    setOrder(order);

    if (order === "desc") {
      listado.sort((a, b) =>
        a.nombreCompleto < b.nombreCompleto
          ? 1
          : a.nombreCompleto > b.nombreCompleto
            ? -1
            : 0
      );
    } else if (order === "asc") {
      listado.sort((a, b) =>
        a.nombreCompleto > b.nombreCompleto
          ? 1
          : a.nombreCompleto < b.nombreCompleto
            ? -1
            : 0
      );
    } else {
      listado.sort((a, b) =>
        a.idDirectorio > b.idDirectorio
          ? 1
          : a.idDirectorio < b.idDirectorio
            ? -1
            : 0
      );
    }
  };


  const {
    data: texto_directorio,
    isSuccess
  } = useGetTextoQuery(ID_DIRECTORIO);

  return (
    <Fragment>
      <Titulo>
        <div className="breadcrumbs">
          <ul>
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link
                className="bc"
                style={{ borderRight: "solid 0px #ccc !important" }}
                to="/directorio"
              >
                Directorio
              </Link>
            </li>
          </ul>
        </div>
        <div className="clear"></div>
        <span className="t1b">{isSuccess ? texto_directorio.data[0].titulo : ''}</span>
        <p>{isSuccess ? texto_directorio?.data[0].contenido : ''}</p>
      </Titulo>

      <div className="gris2">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <FiltroForm
                filtros={filtros}
                setFiltros={setFiltros}
                handleFilter={handleFilter}
                tags={tags}
                loading={loading}
                handleClearFilter={handleClearFilter}
              />
            </div>
            <div className="col-md-9">
              <div className="form rounded mb-4">
                {loading ? (
                  <Spinner></Spinner>
                ) : (
                  <div>
                    <div className="menu3 mb-2">
                      <div className="container">
                        <ul>
                          <li>
                            <button
                              onClick={() => {
                                if (activeTab !== TIPO_USUARIO_PERSONA) {
                                  handleTabChange(TIPO_USUARIO_PERSONA);
                                }
                              }}
                              className={
                                activeTab === TIPO_USUARIO_PERSONA
                                  ? "btn btn-link m3c"
                                  : "btn btn-link"
                              }
                            >
                              <FontAwesomeIcon
                                style={{ marginRight: "7px" }}
                                icon={faUserPlus}
                              />{" "}
                              Personas
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                if (activeTab !== TIPO_USUARIO_ORGANIZACION) {
                                  handleTabChange(TIPO_USUARIO_ORGANIZACION);
                                }
                              }}
                              className={
                                activeTab === TIPO_USUARIO_ORGANIZACION
                                  ? "btn btn-link m3c"
                                  : "btn btn-link"
                              }
                            >
                              <FontAwesomeIcon
                                style={{ marginRight: "7px" }}
                                icon={faShieldHalved}
                              />{" "}
                              Organizaciones
                            </button>
                          </li>
                        </ul>

                        <button
                          type="button"
                          className="btn btn-danger btn-sm descargar1"
                          onClick={downloadExcel}
                        >
                          <FontAwesomeIcon icon={faCircleDown} /> Descargar
                          excel
                        </button>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="row g-3 justify-content-end">
                        <div className="col-auto">
                          <label
                            htmlFor="inputPassword6"
                            className="col-form-label"
                          >
                            Orden alfabético
                          </label>
                        </div>
                        <div className="col-auto">
                          <select
                            style={{ width: "250px" }}
                            className="form-select form-select-sm"
                            onChange={(e) => handleOrdering(e.target.value)}
                            aria-label="Default select example"
                            value={order}
                          >
                            <option value="">Ninguno</option>
                            <option value="asc">Ascendente</option>
                            <option value="desc">Descendente</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div>
                      <TableUsers
                        listaUsuarios={listado.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        )}
                      />
                    </div>
                    {!cargaPagination ? (
                      <Paginacion
                        pageCount={Math.ceil(listado.length / perPage)}
                        currentpage={currentPage}
                        handlePageClick={handlePageClickPerfiles}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Directorio;

Directorio.propTypes = {
  title: PropTypes.string,
};

Directorio.defaultProps = {};
