import { ErrorMessage, Field } from "formik";
import ValidationError from "../../utiles/ValidationError";

const PrecioOferta = (props) => {
    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                <div className="col-md-6">
                    <label htmlFor="valorOferta" className="col-form-label">Valor de la oferta <span className="rojo">*</span></label>
                    <Field type="text" className='form-control' id="valorOferta" name='valorOferta' />
                    <ErrorMessage name="valorOferta" component={ValidationError} />

                </div>


                <div className="col-md-6">
                    <label htmlFor="contemplaIVA" className="col-form-label">¿Valor incluye IVA?<span className="rojo">*</span></label>
                    <Field name="contemplaIVA" as="select" className="form-control" >
                        <option value=''>Seleccionar</option>
                        <option value={'1'}>Contempla IVA</option>
                        <option value={'0'}>Exención de IVA</option>
                    </Field>
                    <ErrorMessage name="contemplaIVA" component={ValidationError} />


                </div>





                <div className="col-md-6">
                    <label htmlFor="ofertaProduccion" className="col-form-label">¿Valor incluye producción?<span className="rojo">*</span></label>
                    <Field name="ofertaProduccion" as="select" className="form-control" >
                        <option value=''>Seleccionar</option>
                        <option value={'1'}>Si</option>
                        <option value={'0'}>No</option>
                    </Field>
                    <ErrorMessage name="ofertaProduccion" component={ValidationError} />


                </div>

                <div className="col-md-6">
                    <div className="col-form-label">
                        <span>Revisa Ley Exención de IVA   <a href="https://www.bcn.cl/leychile/navegar?idNorma=1198024" target='_blank' rel="noreferrer"> aquí</a></span>
                    </div>

                </div>
            </div>
        </div>
    </div>)

}

export default PrecioOferta;