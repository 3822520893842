import { faDownload, faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axiosApi from "../../api/axios/axiosApi";
import { NOTICIAS_ADJUNTOS_URI } from "../../api/axios/constants";

const NoticiaAdjuntos = ({ id_noticia }) => {
    const [loading, setLoading] = useState(true);
    const [documentos, setDocumentos] = useState([]);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        setLoading(true);
        axiosApi.get(NOTICIAS_ADJUNTOS_URI(id_noticia), { cancelToken: cancelToken.token }).then(response => {
            setDocumentos(response?.data?.data);
            setLoading(false);

        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log("cancelado");
            } else {
                console.error(error);
                setLoading(false)
            }
        });
        return () => {
            cancelToken.cancel();
        }
    }, [id_noticia])


    return (
        <div>
            {loading ? <div className="mt-5 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> : documentos.length === 0 ? "" :
                <div className="animation_input">
                    <span className="tit-4">Documentos descargables</span>

                    <ul className="ul-recursos">
                        {documentos.map((adjunto, index) => {
                            return (<li key={index}><a href={adjunto.adjunto} target={'_blank'}>
                                <FontAwesomeIcon icon={adjunto.extension === 'pdf' ? faFilePdf : faFileWord} className="i-1"></FontAwesomeIcon>
                                <div>
                                    <span>{adjunto.titulo}</span>
                                    <p style={{ maxWidth: "660px" }}>{adjunto.descripcion}</p>
                                </div>
                                <FontAwesomeIcon icon={faDownload} className="i-2"></FontAwesomeIcon>
                            </a></li>)
                        })}
                    </ul>
                </div>}
        </div>)
}

export default NoticiaAdjuntos;