import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import OfertaDetalles from "../components/ofertaAssets/OfertaDetalles";
import OfertaHeader from "../components/ofertaAssets/OfertaHeader";
import OfertaSociales from "../components/ofertaAssets/OfertaSociales";
import { setTitle, truncateString } from "../constants/utiles/Functions";
import { OFERTA_GET_ADMIN_URI } from "../api/axios/constants";

const OfertaAdmin = (props) => {
    const { id_oferta } = useParams();
    const [oferta, setOferta] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTitle(props.title)
        const cancelToken = axios.CancelToken.source();
        axiosApi.get(OFERTA_GET_ADMIN_URI(id_oferta), { cancelToken: cancelToken.token }).then(response => {
            if (response?.data.data == null) {
                setOferta(null);
            } else {
                setOferta(response?.data?.data[0]);
            }

            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log("cancelado");
            } else {
                console.error(error);
            }
        });
        return () => {
            cancelToken.cancel();
        }

    }, [id_oferta])

    return (
        <div>
            {loading ? <div className="mt-5 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> : !oferta ? <div className="container mt-4 mb-4">No se encontró la oferta </div> :
                <div className="animation_input">
                    <div className="gris g-blog" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Inicio</Link></li>
                                            <li><Link to={'/busqueda'}>Ofertas</Link></li>
                                            <li><a className="bc-w" style={{ borderRight: "solid 0px #ccc !important" }} >{truncateString(oferta.nombreOferta, 50)}</a></li>
                                        </ul>
                                    </div>
                                    <div className="clear"></div>
                                    <span className="t1b">Ofertas Culturales</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <OfertaHeader oferta={oferta} />

                    <hr />
                    <div className="container mt-4 mb-4">
                        <OfertaSociales oferta={oferta} />
                        <OfertaDetalles oferta={oferta} />

                    </div>
                </div>}
        </div >
    )
}

export default OfertaAdmin;