export const APP_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000';
export const SERVER_URL = '';
export const BASE_URI = 'api';

//HttpCodeResponse
export const HTTP_CODE_200_STANDAR_RESPONSE_OK_SUCCESSFUL = 200;
export const HTTP_CODE_210_USER_IS_NOT_LOGIN = 210;

export const HTTP_CODE_422_ERROR_REQUEST_VALIDATIONS = 422;

export const HTTP_CODE_401_CAST_UNAUTHENTICATED = 401;
export const HTTP_CODE_403_FORBIDDEN_NOT_ACCESS_ON_SERVER = 403;
export const HTTP_CODE_405_ERROR_EXCEPTION = 405;
export const HTTP_CODE_505_SERVER_ERROR_EXCEPTION = 405;


//Generales
export const CANTIDADES_TOTALES_URI = `totales`;
export const REDES_SOCIALES_URI = `sociales`;
export const TEXTOS_URI = id => `textos/${id}`;

//Categorias
export const CATEGORIAS_GET_URI = `categorias`;

//Perfiles
export const PERFILES_GET_URI = `perfiles`;
export const REGISTRO_DIRECTORIO = `directorio`;
export const UPDATE_REGISTRO_DIRECTORIO = id => `directorio/${id}`;
export const USUARIO_GET_URI = rut => `directorio/${rut}`
export const USUARIO_GET_URI_ID_DIR = id => `directorioPorId/${id}`
export const LOGIN_URI = `login`;
export const REGISTRO_URI = `registro`;
export const RECUPERAR_PASSWORD_URI = email => `recuperar/${email}`;
export const CAMBIAR_PASS_URI = id => `password/${id}`;
export const DIRECTORIO_TIPO_URI = id_tipo => `directorios/${id_tipo}`;
export const GUARDAR_PERFIL_URI = `perfil-guardar`;
export const GET_PERFILES_GUARDADOS_URI = id_directorio => `directorio/perfiles/${id_directorio}`;
export const GET_TIPO_PERSONAS_URI = `tipo-personas`;
export const GET_TIPO_ORGANIZACION_URI = `tipo-organizaciones`;
export const GET_TIPO_ESPACIOS_URI = `tipo-espacios-culturales`;

export const POST_DETALLE_ESPACIO_URI = id_directorio => `detalle-espacio-cultural/${id_directorio}`;
export const PUT_DETALLE_ESPACIO_URI = id_detalle_espacio => `detalle/espacio-cultural/${id_detalle_espacio}`;
export const GET_DETALLE_ESPACIO_URI = id_directorio => `directorio/espacio-cultural/${id_directorio}`;


export const POST_DETALLE_ORGANIZACION_URI = id_directorio => `representante-legal/${id_directorio}`;
export const PUT_DETALLE_ORGANIZACION_URI = id_detalle_org => `detalle/representante-legal/${id_detalle_org}`;
export const GET_DETALLE_ORGANIZACION_URI = id_directorio => `datos-representante-legal/${id_directorio}`;

export const PUT_BIOGRAFIA_URI = id_directorio => `directorio/biografia/${id_directorio}`;
export const PUT_VIDEO_URI = id_directorio => `directorio/presentacion/${id_directorio}`;


//Generos
export const GENEROS_GET_URI = `generos`;

//Locaciones
export const REGIONES_GET_URI = `regiones`;
export const COMUNAS_GET_URI = id_comuna => `comunas/${id_comuna}`;

//Noticias
export const NOTICIAS_GET_URI = `noticias`;
export const NOTICIA_GET_URI = id => `noticia/${id}`;
export const NOTICIAS_REL_URI = id_categoria => `noticias/${id_categoria}`;
export const NOTICIAS_CATEGORIAS_URI = `noticias-categorias`;
export const NOTICIAS_ADJUNTOS_URI = id_noticia => `noticias/adjuntos/${id_noticia}`;


//Ofertas
export const CRITERIOS_GET_URI = `criterios`;
export const DURACION_FUNCION_GET_URI = `duracion-funcion`;
export const DURACION_TOTAL_GET_URI = `duracion-total`;
export const ESPACIOS_EXPOSITIVOS_URI = `espacios-expositivos`;
export const ESPACIOS_REQUERIDOS_URI = `espacios-requeridos`;
export const OFERTA_URI = `oferta`;
export const PUBLICO_URI = `publico`;
export const MIS_OFERTAS_URI = id_usuario => `ofertas/${id_usuario}`;
export const RANGO_PRECIOS_URI = `precios`;
export const OFERTA_GET_URI = id_oferta => `oferta/ver/${id_oferta}`;
export const OFERTA_PRIVADO_GET_URI = id_oferta => `oferta/perfil/ver/${id_oferta}`;
export const OFERTA_DELETE_URI = id_oferta => `oferta/${id_oferta}`;
export const OFERTA_UPDATE_URI = id_oferta => `oferta/${id_oferta}`;
export const OFERTA_RELACIONADOS_URI = id_region => `ofertas/relacionadas/${id_region}`
export const OFERTA_RELACIONADOS_TIPO_URI = id_tipo => `ofertas/tipos/${id_tipo}`
export const DELETE_PARTICIPANTES_URI = id_participante => `participante/${id_participante}`

export const OFERTA_TIPOS_URI = `tipos-ofertas`;
export const OFERTA_ETIQUETAS_URI = `etiquetas`;

export const OFERTA_GUARDARFAV_URI = `programar-oferta`;
export const OFERTA_GUARDADAS_URI = id => `ofertas/programadas/${id}`;
export const OFERTA_DEL_GUARDADAS_URI = id => `programada/${id}`;

export const OFERTA_IMAGEN_BASE64 = id => `oferta/imagen/${id}`;

export const OFERTA_LIKES = id => `ofertas/gustas-totales/${id}`;
export const OFERTA_SET_LIKE = `ofertas/me-gusta`;
export const OFERTA_GUARDADAS_LIKES_URI = id => `ofertas/listados-me-gusta/${id}`;
export const OFERTA_DEL_LIKES_URI = id => `ofertas/eliminar-megusta/${id}`;



//Convocatorias
export const CONVOCATORIAS_URI = `convocatorias`;
export const CONVOCATORIA_URI = id => `convocatoria/${id}`;
export const CONVOCATORIA_CATEGORIAS_URI = `convocatorias/categorias`;
export const CONVOCATORIA_ADD_URI = `convocatoria`;
export const CONVOCATORIA_GUARDARFAV_URI = `convocatoria-guardar`;
export const CONVOCATORIA_GUARDADAS_URI = id => `convocatorias/guardadas/${id}`;
export const CONVOCATORIAS_CREADAS_URI = id => `convocatorias/creadas/${id}`;
export const CONVOCATORIAS_ETIQUETAS_URI = `convocatorias/etiquetas`;

//export const CONVOCATORIAS_PERF_GET_URI = id_directorio => `convocatoria/perfil/${id_directorio}`;
export const CONVOCATORIA_PERF_GET_URI = id_convocatoria => `convocatoria/perfil/${id_convocatoria}`;
export const CONVOCATORIAS_PERF_DELETE_URI = id_convocatoria => `convocatorias/${id_convocatoria}`
export const CONVOCATORIAS_PERF_UPDATE_URI = id_convocatoria => `convocatoria/editar/${id_convocatoria}`

//Catalogo
export const CATALOGOS_URI = id_tipo => `catalogos/${id_tipo}`
export const CATALOGOS_CATEGORIAS_URI = `catalogos-categorias`

//newsletter
export const NEWSLETTER_POST_URI = `newsletter`;

//Ayuda
export const CONTACTO_URI = `contacto`;
export const FAQ_URI = `faqs`;
export const CRITERIOS_PDF_URI = `pdf/criterios`;


//Busquedas
export const BUSQUEDA_GENERAL_URI = `buscador/general`;
export const BUSQUEDA_NOTICIAS_URI = `buscador/noticias`;
export const BUSQUEDA_CATEGORIA_URI = id => `buscador/ofertasPorCategoria/${id}`;
export const BUSQUEDA_CONVO_URI = `buscador/convocatorias`;
export const BUSQUEDA_RECURSOS_URI = `buscador/recursos`;




//CU_API
export const APP_URL_CU = process.env.REACT_APP_URL_CU || 'http://localhost:8080';
export const TOKEN_CU = 'token'
export const USER_INFO_CU = 'userinfo'


//MAP_API
export const SEARCH_MAP_API = (direccion) => `https://api.maptiler.com/geocoding/${encodeURIComponent(direccion)}.json?key=${process.env.REACT_APP_TOKEN_MAPTILES}`


//API_ADMIN
export const OFERTA_GET_ADMIN_URI = id_oferta => `oferta/evaluacion/${id_oferta}`;


//API_CHILE_CULTURA
export const EVENTOS_CULTURALES_URI = `chilecultura/eventos`;
export const DISCIPLINAS_CULTURALES_URI = `chilecultura/categorias`;
export const REGIONES_CULTURALES_URI = `chilecultura/regiones`;
