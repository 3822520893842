import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import { checkIfFileIsTooBig, forEachValues, getBase64, validateDocType } from "../../constants/utiles/Functions";

import * as Yup from 'yup';
import { toast } from "react-hot-toast";
import ValidationError from "../utiles/ValidationError";

import { Spinner } from "react-bootstrap";
import { faArrowRight, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PUT_BIOGRAFIA_URI, USUARIO_GET_URI_ID_DIR } from "../../api/axios/constants";
import axiosApi from "../../api/axios/axiosApi";
import { useNavigate } from "react-router";
import axios from "axios";

const BiografiaEdit = ({ user }) => {
    const [fileAdjunto, setFileAdjunto] = useState(null);
    const [userBiografia, setUserBiografia] = useState("");

    const [userLoad, setUserLoad] = useState(true);
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate();

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (user.idDirectorio) {
            axiosApi.get(USUARIO_GET_URI_ID_DIR(user.idDirectorio), { cancelToken: cancelToken.token })
                .then(response => {

                    if (response.data.error === false) {
                        setFileAdjunto(response.data.data[0].biografia_adjunto || null);
                        setUserBiografia(response.data.data[0].biografia || null)
                    }
                    setLoading(false);
                    setUserLoad(false);
                }).catch(error => {

                    setLoading(false)
                })
        }

        return () => {
            cancelToken.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);



    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const initialValues = {
        biografia: userBiografia || "",
        adjunto: null,

    };


    const validationSchema = Yup.object({
        biografia: Yup.string().required('Campo requerido'),
        adjunto: Yup.mixed()
            .nullable()
            .test('adjunto-tamanio', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
            .test(
                'adjunto-formato',
                'Solo se aceptan los siguientes formatos de archivo: .pdf',
                validateDocType
            ),
    })

    const handleOnSubmit = (values) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');
        const data = {
            biografia: values.biografia,

        }
        let arrayFiles = [];

        if (values.adjunto) {
            const doc_func = () => getBase64(values.adjunto)
                .then(result => {
                    const base64result = result.split(',')[1];
                    data['adjunto_name'] = values.adjunto.name;
                    data['adjunto_data'] = base64result;
                    data['adjunto_mime_type'] = values.adjunto.type;

                })
                .catch(err => {
                    console.error(err);
                });


            arrayFiles.push(doc_func);
        }





        forEachValues(arrayFiles)
            .then(() => {
                axiosApi.put(PUT_BIOGRAFIA_URI(user.idDirectorio), data).then(response => {
                    if (response.data.error === false) {
                        toast.success(response.data.message, {
                            id: toastId,
                        });

                    } else {
                        toast.error('Ocurrio un error al guardar los datos, intente nuevamente', {
                            id: toastId,
                        });
                    }

                    setLoading(false)
                }).catch(error => {
                    toast.error('Ocurrio un error al guardar los datos, intente nuevamente', {
                        id: toastId,
                    });
                    setLoading(false)
                })
            })


    }




    return (<div>

        <div className="rb">
            <div className="rbl mb-4">
                <span>Datos de biografía o presentación </span>
            </div>
        </div>

        <div className="clear"></div>

        {
            userLoad ? <div className="mt-1 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> :

                <div className="animation_input">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}

                    >
                        {props => (
                            <Form className="">
                                <div>
                                    <div className="row mb-2">
                                        <label htmlFor="biografia" className="form-label">Biografía o presentación</label>

                                        <Field as="textarea" style={{ minHeight: "350px" }} className='form-control' id="biografia" name="biografia" />
                                        <ErrorMessage name="biografia" component={ValidationError} />

                                    </div>


                                    <div className="row mb-2">
                                        <label htmlFor="adjunto" className="col-sm-5 col-form-label">Subir biografía o presentación</label>
                                        <div className="col-sm-7">
                                            <input id="adjunto" name="adjunto" type="file" className={`form-control  ${props.errors.adjunto ? "is-invalid" : ""}`} onChange={(event) => {
                                                props.touched.adjunto = true;
                                                props.setFieldValue("adjunto", event.currentTarget.files[0]);
                                                setFileAdjunto(URL.createObjectURL(event.target.files[0]));
                                            }} accept="application/pdf, application/x-pdf" />
                                            <ErrorMessage name="adjunto" component={ValidationError} />
                                            {
                                                (props.errors.adjunto && props.touched.adjunto) || !fileAdjunto ? "" :
                                                    <div className="pt-2 mx-auto" >
                                                        <a href={fileAdjunto} target={'_blank'} rel="noreferrer"> <FontAwesomeIcon className={'mx-1'} icon={faFile} /> Documento de biografía o presentación</a>
                                                    </div>

                                            }
                                        </div>
                                    </div>


                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger btn-lg" disabled={loading}>Guardar <FontAwesomeIcon className='ms-1' icon={faArrowRight}></FontAwesomeIcon></button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div >
        }


        <div className="clear"></div>
    </div >)
}

export default BiografiaEdit;