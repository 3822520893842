import { useState } from "react";
import { Spinner } from "react-bootstrap";


const Image = ({ src, style , className}) => {
    const [loading, setLoading] = useState(true);

    return (
        <div>
            <img src={src} style={{
                display: loading ? "none" : "block",
                width: "100%",
                ...style
            }}
                alt=""
                className={className}
                onLoad={(e) => { setLoading(false) }}
                onError={(e) => { setLoading(false) }}></img>

            {
                loading ? <div style={{ textAlign: "center", height: "200px" }} className="rounded-top pt-5">
                    <Spinner></Spinner>
                </div> : ""
            }

        </div>
    )
}


export default Image;