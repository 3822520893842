import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  DISCIPLINAS_CULTURALES_URI,
  REGIONES_CULTURALES_URI,
} from "../../api/axios/constants";
import { Spinner } from "react-bootstrap";
import { getMonths } from "../../constants/utiles/DateFunctions";
import axiosApi from "../../api/axios/axiosApi";

const CarteleraBuscador = (props) => {
  const [regiones, setRegiones] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [loading, setLoading] = useState(true);
  const months = getMonths();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();


    axios
      .all([
        axiosApi.get(REGIONES_CULTURALES_URI, {
          cancelToken: cancelToken.token,
        }),

        axiosApi.get(DISCIPLINAS_CULTURALES_URI, {
          cancelToken: cancelToken.token,
        }),
      ])

      .then(
        axios.spread((response1, response2) => {
          setRegiones(response1.data?.data);
          setDisciplinas(response2.data?.data);

          setLoading(false);
        })
      )
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("cancelado");
        } else {
          console.error(error);
          setLoading(false);
        }
      });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.searchHandle();
    }
  };

  return (
    <div className="col-md-2">
      {loading ? (
        <div className="mt-5 mb-5 ">
          <div style={{ textAlign: "center" }}>
            <Spinner></Spinner>
          </div>
        </div>
      ) : (
        <div>
          <div className="ef rounded">
            <input
              type="text"
              className="form-control"
              id="nombreCartelera"
              placeholder="Actividad"
              onChange={(e) => props.setNombre(e.target.value)}
              onKeyUp={handleKeyDown}
            />

            <select
              id="disciplinaCartelera"
              className="form-select form-select-sm"
              onChange={(e) => props.setDisciplina(e.target.value)}
            >
              <option value={""}>Categorias</option>

              {disciplinas.map((disciplina, index) => (
                <option key={index} value={disciplina.name}>
                  {disciplina.name}
                </option>
              ))}
            </select>

            <select
              id="mesCartelera"
              className="form-select form-select-sm"
              onChange={(e) => props.setMes(e.target.value)}
            >
              <option value={""}>Mes</option>

              {months.map((values, index) => (
                <option value={index + 1} key={index}>
                  {values.charAt(0).toUpperCase() + values.slice(1)}
                </option>
              ))}
            </select>

            <select
              id="regionCartelera"
              className="form-select form-select-sm"
              onChange={(e) => props.setRegion(e.target.value)}
            >
              <option value={""}>Región</option>

              {regiones.map((region, index) => (
                <option key={index} value={region.name}>
                  {region.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-grid gap-2">
            <button
              className="btn btn-primary mt-2"
              type="button"
              onClick={props.searchHandle}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarteleraBuscador;

CarteleraBuscador.propTypes = {
  setRegion: PropTypes.func.isRequired,
  setMes: PropTypes.func.isRequired,
  setDisciplina: PropTypes.func.isRequired,
  setNombre: PropTypes.func.isRequired,
};

CarteleraBuscador.defaultProps = {};
