import { ErrorMessage, Field, useFormikContext } from "formik";
import ValidationError from "../../utiles/ValidationError";


const MediadorOferta = (props) => {

    const {
        values: { mediador },

    } = useFormikContext();

    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                <div className="col-md-12">
                    <label htmlFor="mediador" className="form-label">¿Cuenta con actividad de mediación? <span className="rojo">*</span></label>
                    <Field name="mediador" as="select" className="form-control" >
                        <option value=''>Seleccionar</option>
                        <option value={'1'}>Si</option>
                        <option value={'0'}>No</option>
                    </Field>
                    <ErrorMessage name="mediador" component={ValidationError} />
                </div>

                {mediador === '1' ?
                    <div className="col-md-12">
                        <label htmlFor="detalleMediacion" className="form-label">Detalles de mediación <span className="rojo">*</span></label>
                        <Field type="text" className='form-control' id="detalleMediacion" name='detalleMediacion' />
                        <ErrorMessage name="detalleMediacion" component={ValidationError} />
                    </div> : ""
                }
            </div>
        </div>
    </div>)

}

export default MediadorOferta;