import { Fragment } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import Paginacion from "../utiles/Paginacion";
import { useDeleteOfertasGuardadosMutation, useGetOfertasGuardadosDetailQuery } from "../../redux/features/ApiSlice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OfertasProgramadasPDF from "../../templates/OfertasProgramadasPDF";
import { Button, Modal } from "react-bootstrap";
import OfertaItem from "./ProgramadosComponents/OfertaItem";
import toast from "react-hot-toast";

const OfertasProgramadas = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [idDelete, setIdDelete] = useState('');
    const perPage = 6;


    let skip = false;
    if (!props.user.idDirectorio) {
        skip = true;
    }
    const { data = [], isLoading, isSuccess } = useGetOfertasGuardadosDetailQuery(props.user.idDirectorio, { skip });
    const [deleteOfertaGuardados] = useDeleteOfertasGuardadosMutation();


    const handlePageClick = (data) => {
        const page = data.selected + 1;

        setCurrentPage(page);
    }


    const openDelete = (id) => {
        setIdDelete(id);
        setOpen(true);

    }

    const deleteCancelar = () => {
        setIdDelete("");
        setOpen(false);
    }

    const deleteConfirmar = () => {
        const toastId = toast.loading('Cargando...');
        deleteOfertaGuardados(idDelete).unwrap().then((response) => {
            toast.success(response.message, {
                id: toastId,
            })
        }).catch(error => {
            toast.error(error.message, {
                id: toastId,
            })
        });
        setIdDelete('');
        setOpen(false);
    }



    return (
        <Fragment>
            <div className="gris mt-4 rounded bIPerfilesGuardados">
                <div className="container" style={{ overflow: "hidden" }}>

                    <div className="row">
                        <div className="col">
                            <span className="t1g">Programación de ofertas</span>
                        </div>
                    </div>

                    {
                        isSuccess && data.length === 0 ? <span style={{ color: "white" }}>No se encontraron ofertas programadas</span> : <div>
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="row g-3 me-3" style={{ float: "right", marginTop: "-10px" }}>
                                        {!isLoading && props.user.nombreCompleto && data.length > 0 ?
                                            <PDFDownloadLink document={<OfertasProgramadasPDF data={data} user={props.user} />} fileName="Programacion.pdf" className="btn btn-danger" title="Ofertas Programadas">
                                                {({ blob, url, loading, error }) =>
                                                    loading ? 'Cargando documento...' : 'Descargar Programación'
                                                }
                                            </PDFDownloadLink> : ""
                                        }

                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                {isLoading ? <Spinner variant="light" className="mx-auto mb-4" /> : data.slice((currentPage - 1) * perPage, currentPage * perPage).map((values, index) =>

                                    <OfertaItem
                                        key={(currentPage - 1) * 10 + index}
                                        id={values.idProgramacion}
                                        idOferta={values.idOferta}
                                        img={values.imagen}
                                        nombre={values.nombreOferta}
                                        agente={values.tipoPerfil}
                                        categoria={values.categoria}
                                        region={values.region}
                                        deleteOferta={openDelete}
                                    />)

                                }

                                {
                                    !data || data.length === 0 ? "" : <Paginacion
                                        pageCount={Math.ceil(data.length / perPage)}
                                        currentpage={currentPage}
                                        handlePageClick={handlePageClick}
                                    />

                                }

                            </div>
                        </div>

                    }

                </div>
            </div>
            <Modal show={open} onHide={() => { setOpen(false) }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>¡Aviso!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Esta acción eliminará la oferta seleccionada de su lista de programación. ¿Desea continuar?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { deleteCancelar() }}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => { deleteConfirmar() }}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}


export default OfertasProgramadas;

OfertasProgramadas.propTypes = {
}

OfertasProgramadas.defaultProps = {
}


