import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import axiosApi from "../../api/axios/axiosApi";
import { COMUNAS_GET_URI } from "../../api/axios/constants";
import PropTypes from 'prop-types';
import { useGetPreciosQuery, useGetTextoQuery, useGetTipoPerfilesQuery } from "../../redux/features/ApiSlice";
import { ID_HOME_PRINCIPAL } from "../../constants/Constants";

const Buscador = (props) => {
    const [agente, setAgente] = useState('');
    const [categoria, setCategoria] = useState('');
    const [region, setRegion] = useState('');
    const [comuna, setComuna] = useState('');
    const [precio, setPrecio] = useState('');
    const [palabra, setPalabra] = useState('');
    const [arrayComunas, setArrayComunas] = useState([]);

    const navigate = useNavigate();

    const {
        data: perfiles = [],
        isLoading: perfilesLoad,
    } = useGetTipoPerfilesQuery()



    const {
        data: precios = [],
        isLoading: preciosLoad,
    } = useGetPreciosQuery()


    const {
        data : texto_principal,
        isSuccess
    } = useGetTextoQuery(ID_HOME_PRINCIPAL);

    const handleSubmit = () => {
        let data = {}
        if (agente) {
            data.agente = agente
        }
        if (categoria) {
            data.categoria = categoria
        }
        if (region) {
            data.id_region = region
        }
        if (comuna) {
            data.comuna = comuna
        }
        if (palabra) {
            data.palabra = encodeURIComponent(palabra)
        }
        if (precio) {
            data.rango_precio = precio
        }

        navigate({
            pathname: "/busqueda",
            search: createSearchParams(data).toString()
        });

    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    useEffect(() => {
        setComuna('');
        if (region !== '') {
            setArrayComunas([]);
            axiosApi.get(COMUNAS_GET_URI(region))
                .then(response => {
                    setArrayComunas(response?.data?.data);
                }).catch((error) => {
                    console.error(error)
                });
        } else {
            setComuna('');
            setArrayComunas([]);
        }


    }, [region])


    return (
        <div className="top1">
            <div className="container">
                <span className="t1">{isSuccess ? texto_principal?.data[0]?.titulo : ''}</span>
                <p className="tp">{isSuccess ? texto_principal?.data[0]?.contenido : ''}</p>

                <div className="buscar rounded">

                    <form className="row">
                        <div className="col-8">
                            <input type="text" className="form-control" id="inputPassword2" placeholder="" onChange={e => setPalabra(e.target.value)} onKeyUp={handleKeyDown} />
                        </div>
                        <div className="col-4 d-grid gap-2">
                            <button type="button" className="btn btn-primary mb-3" onClick={handleSubmit}><FontAwesomeIcon icon={faMagnifyingGlass} className="fa-solid fa-magnifying-glass"></FontAwesomeIcon> Buscar</button>
                        </div>


                        <div className="row mt-3">
                            <div className="col-md-15">
                                <select id="agente" className="form-select form-select-sm" onChange={e => setAgente(e.target.value)}>
                                    <option value={''}>Tipo de perfil</option>
                                    {perfilesLoad ? "" : perfiles.data.map((agentes, index) => <option key={index} value={agentes.idTipoPerfil}>{agentes.tipoPerfil}</option>)}

                                </select>
                            </div>
                            <div className="col-md-15">
                                <select id="categoria" className="form-select form-select-sm" onChange={e => setCategoria(e.target.value)}>
                                    <option value={''}>Categoría</option>
                                    {
                                        props.categorias.map((categorias, index) =>
                                            <option key={index}
                                                value={categorias.idCategoria}
                                            >{categorias.categoria}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-md-15">
                                <select id="region" className="form-select form-select-sm" onChange={e => setRegion(e.target.value)}>
                                    <option value={''}>Región</option>
                                    {props.regiones.map((region, index) => <option key={index} value={region.idRegion}>{region.region}</option>)}
                                </select>
                            </div>
                            <div className="col-md-15">
                                <select id="comuna" className="form-select form-select-sm" onChange={e => setComuna(e.target.value)} value={comuna}>
                                    <option value={''}>Comuna</option>
                                    {arrayComunas.map((comuna, index) => <option key={index} value={comuna.idComuna}>{comuna.comuna}</option>)}
                                </select>
                            </div>
                            <div className="col-md-15">
                                <select id="precio" className="form-select form-select-sm" onChange={e => setPrecio(e.target.value)}>
                                    <option value={''}>Rango de precio</option>
                                    {preciosLoad ? "" : precios.data.map((rango, index) => <option key={index} value={rango.idRango}>{rango.rango}</option>)}

                                </select>
                            </div>
                        </div>
                    </form>
                </div>



            </div>


        </div>
    )
}

export default Buscador;


Buscador.propTypes = {
    regiones: PropTypes.array.isRequired,
    categorias: PropTypes.array.isRequired,

}

Buscador.defaultProps = {
    regiones: [],
    categorias: [],
}