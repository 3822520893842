import { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { BUSQUEDA_CATEGORIA_URI, BUSQUEDA_GENERAL_URI, FRONTEND_URL } from "../api/axios/constants";
import FiltroForm from "../components/busquedaAssets/FiltroForm";
import ResultadoItem from "../components/busquedaAssets/ResultadoItem";
import SelectOrden from "../components/busquedaAssets/SelectOrden";
import Paginacion from "../components/utiles/Paginacion";
import Titulo from "../components/utiles/Titulo";
import { setTitle } from "../constants/utiles/Functions";
import PropTypes from 'prop-types'
import axios from "axios";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import ExportExcel from "../components/utiles/ExcelExport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDown } from "@fortawesome/free-solid-svg-icons";

const ResultadosBusqueda = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultadoBusqueda, setResultadoBusqueda] = useState([]);
    const [order, setOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState([]);
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);
    const perPage = 9;

    const [loadingDownload, setLoadingDownload] = useState(false);


    useEffect(() => {
        setTitle(props.title)
        setOrder('desc');
        const cancelToken = axios.CancelToken.source();
        setLoading(true);
        axiosApi.get(BUSQUEDA_GENERAL_URI, { cancelToken: cancelToken.token, params: handleInitialParams() })
            .then(response => {
                setResultadoBusqueda(response.data.data ? response.data.data.sort((a, b) => (a.idOferta < b.idOferta) ? 1 : (a.idOferta > b.idOferta) ? -1 : 0) : []);
                setFiltros(handleFilterTags(response.data));
                setCurrentPage(1);

                setLoading(false);
            }).catch((error) => {
                if (axios.isCancel(error)) {
                    console.log("cancelado");
                } else {
                    console.error(error)


                }
            });


        if (searchParams.get('modo_categoria') == "true") {
            axiosApi.get(BUSQUEDA_CATEGORIA_URI(searchParams.get('categoria')), { cancelToken: cancelToken.token })
                .then(response => {
                    const result = response.data.data ? response.data.data.sort((a, b) => (a.idOferta < b.idOferta) ? 1 : (a.idOferta > b.idOferta) ? -1 : 0) : []
                    setResultadoBusqueda(result);
                    setFiltros(handleFilterTags(response.data));
                    setCurrentPage(1);
                    setLoading(false);
                }).catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log("cancelado");
                    } else {
                        console.error(error)
                            ;
                        dispatch(setLoading(false));
                    }
                });
        }
        return () => {
            cancelToken.cancel()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);



    useEffect(() => {
        if (resultadoBusqueda) {
            let orderResult;
            if (order === "desc") {
                orderResult = resultadoBusqueda.sort((a, b) => (a.idOferta < b.idOferta) ? 1 : (a.idOferta > b.idOferta) ? -1 : 0);
            } else if (order === "asc") {
                orderResult = resultadoBusqueda.sort((a, b) => (a.idOferta > b.idOferta) ? 1 : (a.idOferta < b.idOferta) ? -1 : 0);
            }
            setResultadoBusqueda([...orderResult]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);



    const handleFilterTags = (data) => {
        let filters = [];
        if (data.idCategoria) {
            filters.push({ id: data.idCategoria, nombre: data.categoria, input: "categoria" });
        }
        if (data.idRegion) {
            filters.push({ id: data.idRegion, nombre: data.region, input: "region" });
        }
        if (data.idComuna) {
            filters.push({ id: data.idComuna, nombre: data.comuna, input: "comuna" });
        }
        if (data.idTipoPerfil) {
            filters.push({ id: data.idtipoPerfil, nombre: data.tipo_perfil, input: "tipoPerfil" });
        }
        if (data.idRango) {
            filters.push({ id: data.idRango, nombre: data.rangoPrecio, input: "rangoPrecio" });
        }

        if (data.duracion_funcion) {
            filters.push({ id: data.idDuracionFuncion, nombre: data.duracion_funcion, input: "duracion" });
        }


        if (data.publico) {
            filters.push({ id: data.idPublico, nombre: data.publico, input: "publicoObjetivo" });
        }
        if (data.mediacion) {
            filters.push({ id: data.mediacion, nombre: data.mediacion == "true" ? "Con mediación" : "Sin mediación", input: "mediacion" });
        }
        if (data.etiqueta) {
            filters.push({ id: data.idEtiqueta, nombre: data.etiqueta, input: "etiqueta" });
        }


        return filters;
    }

    const handleInitialParams = () => {
        return {
            texto_buscar: searchParams.get('palabra') ? decodeURIComponent(searchParams.get('palabra')) : null,
            id_tipo_perfil: searchParams.get('agente'),
            id_categoria: searchParams.get('categoria'),
            id_region: searchParams.get('id_region'),
            id_comuna: searchParams.get('comuna'),
            id_rango_precio: searchParams.get('rango_precio'),
            id_duracion_funcion: searchParams.get('duracion'),
            id_publico_objetivo: searchParams.get('publico_obj'),
            mediacion: searchParams.get('mediacion') == null ? null : searchParams.get('mediacion') == 1 ? true : false,
            id_etiqueta: searchParams.get('etiqueta'),
        }

    }

    const handlePageClick = (data) => {
        const page = data.selected + 1;

        setCurrentPage(page);
    }



    const downloadExcel = () => {
        setLoadingDownload(true);
        axiosApi.get(BUSQUEDA_GENERAL_URI)
            .then(response => {
                setLoadingDownload(false);

                const arrayExport = response.data.data.map((input) => {
                    const result = {
                        "Nombre de oferta": input.nombreOferta,
                        "Valor oferta": input.precio,
                        Región: input.region,
                        Comuna: input.comuna,
                        'Creado por' : input.creadoPor,
                        "Tipo de perfil": input.tipoPerfil,
                        "Descripción" : input.descripcion,
                        "Enlace de oferta" : FRONTEND_URL+ "/oferta/" + input.idOferta
                    }
                    return result;
                });

                ExportExcel(arrayExport, "Ofertas");

            }).catch((error) => {
                if (axios.isCancel(error)) {
                    console.log("cancelado");
                } else {
                    console.error(error)


                }
                setLoadingDownload(false);
            });

    }

    return (
        <Fragment>
            <Titulo>
                <div className="breadcrumbs">
                    <ul>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }} to="/busqueda">Resultados de búsqueda</Link></li>
                    </ul>
                </div>
                <div className="clear"></div>
                <span className="t1b">Resultados de búsqueda de Ofertas <button
                    type="button"
                    className="btn btn-danger btn-sm rbr"
                    onClick={downloadExcel}

                    disabled={loadingDownload}
                >
                    <FontAwesomeIcon icon={faCircleDown} /> Descargar
                    excel
                </button></span>


            </Titulo>


            <div className="gris2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <FiltroForm
                                comuna={searchParams.get('comuna')}
                                region={searchParams.get('id_region')}
                                categoria={searchParams.get('categoria')}
                                palabra={searchParams.get('palabra') ? decodeURIComponent(searchParams.get('palabra')) : null}
                                agente={searchParams.get('agente')}
                                rangoPrecio={searchParams.get('rango_precio')}
                                duracion={searchParams.get('duracion')}
                                publicoObjetivo={searchParams.get('publico_obj')}
                                mediacion={searchParams.get('mediacion')}
                                etiqueta={searchParams.get('etiqueta')}
                                setOrder={setOrder}
                                filtros={filtros}
                                loading={loading}
                            />
                        </div>
                        <div className="col-md-9">
                            <div>
                                <div className="rb">
                                    <div className="rbl">
                                        <span>Resultados de búsqueda</span>
                                        <p>{resultadoBusqueda.length} resultados</p>
                                    </div>
                                    <div className="rbr">

                                        <SelectOrden handleOrdering={setOrder} order={order} />



                                    </div>


                                </div>
                                <div className="clear"></div>
                                <div>
                                    {loading ? <div className="mt-4">
                                        <div style={{ textAlign: "center" }} >
                                            <Spinner ></Spinner>
                                        </div>
                                    </div> : resultadoBusqueda ? <div className="row mt-4">

                                        {resultadoBusqueda.slice((currentPage - 1) * perPage, currentPage * perPage).map((oferta, index) => <ResultadoItem
                                            key={(currentPage - 1) * 10 + index}
                                            id={oferta.idOferta}
                                            img={oferta.imagen}
                                            nombre={oferta.nombreOferta}
                                            agente={oferta.tipoPerfil}
                                            categoria={oferta.categoria}
                                            region={oferta.region}

                                        />)}
                                        {
                                            resultadoBusqueda.length > 0 ?
                                                <Paginacion
                                                    pageCount={Math.ceil(resultadoBusqueda.length / perPage)}
                                                    currentpage={currentPage}
                                                    handlePageClick={handlePageClick}
                                                /> : ""
                                        }
                                    </div>
                                        : ""}

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Fragment>
    );
}

export default ResultadosBusqueda;


ResultadosBusqueda.propTypes = {
    title: PropTypes.string.isRequired,
    palabra_clave: PropTypes.string,
}

ResultadosBusqueda.defaultProps = {
}
