import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import axiosApi from '../api/axios/axiosApi';
import { OFERTA_IMAGEN_BASE64 } from '../api/axios/constants';
import { truncateString } from '../constants/utiles/Functions';
import { getDateNow } from '../constants/utiles/DateFunctions';


// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  sub: {
    fontSize: 14,
    textAlign: 'center',
  }
  ,
  author: {
    fontSize: 11,
    minHeight: 30,
    marginBottom: 10,
    borderBottom: "0.5 solid black",
  },
  subDate: {
    fontSize: 11,
    textAlign: 'right',
    marginBottom: 25,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  textOferta: {
    margin: 1,
    fontSize: 11,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',

  },

  oferta: {
    maxHeight: 400,
    minHeight: 110,
    margin: 5,
    borderBottom: "0.5 solid black",
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between"
  },

  ofertaImagen: {
    height: 100,
    width: 150,
  }
  ,
  row: {
    display: "flex",

    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
  },


});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});


// Create Document Component
const OfertasProgramadasPDF = ({ data = [], user = {} }) => {
  const fetchImages = async (id) => {
    try {
      const response = await axiosApi.get(OFERTA_IMAGEN_BASE64(id)).then(response => {
        if (response.data.code === 200) {
          return "data:" + response.data.data[0].imagenExtension + ";base64," + response.data.data[0].imagenBase64;
        }

        return "";
      }).catch(error => {
        return "";

      });

      return response;

    } catch (error) {
      console.log(error);
      return "";
    }
  };


  return (
    <Document>
      <Page style={styles.body} wrap>
        <Text style={styles.header} fixed>
        </Text>
        <Text style={styles.title}>Selección de Ofertas en Catálogo Digital</Text>
        <Text style={styles.sub}>Ministerio de las Culturas, las Artes y el Patrimonio</Text>
        <Text style={styles.subDate}>{getDateNow("DD/MM/YYYY")}</Text>

        <Text style={styles.author}>Selección realizada por: {user.nombreCompleto}</Text>
        {data.map((values) =>

          <View key={values.idOferta} style={styles.oferta} wrap={false}>

            <View >
              <Text style={styles.textOferta}>Nombre de oferta : {truncateString(values.nombreOferta, 45)}</Text>
              <Text style={styles.textOferta}>Creado por : {truncateString(values.creadoPor, 45)}</Text>
              <Text style={styles.textOferta}>Tipo de perfil : {values.tipoPerfil}</Text>
              <Text style={styles.textOferta}>Ubicación : {values.region}, {values.comuna}</Text>
              <Link style={styles.textOferta} src={process.env.REACT_APP_FRONTEND_URL + "/oferta/" + values.idOferta}>{process.env.REACT_APP_FRONTEND_URL + "/oferta/" + values.idOferta}</Link>
            </View>
            <View   >
              <Image style={styles.ofertaImagen} src={fetchImages(values.idOferta)} ></Image>
            </View>

          </View>

        )

        }


        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  )
};

export default OfertasProgramadasPDF;