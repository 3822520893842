import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import axiosApi from '../../api/axios/axiosApi';
import { CONVOCATORIAS_CREADAS_URI, CONVOCATORIAS_PERF_DELETE_URI } from '../../api/axios/constants';
import ConvocatoriaCreadaItem from './ConvocatoriaCreadaItem';
import SubirConvocatoria from './SubirConvocatoria';

const Convocatorias = ({ user }) => {
    const [showForm, setShowForm] = useState(false);
    const navigate = useNavigate();
    const [listaConvocatorias, setListaConvocatorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idEliminar, setIdEliminar] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [open, setOpen] = useState();

    const handleCreateConvo = () => {
        setShowForm(true);
    }

    const handleCloseForm = () => {
        setShowForm(false);
        setSelectedId(null);
    }



    useEffect(() => {

        const cancelTokenGen = axios.CancelToken.source();
        if (user.idDirectorio && !showForm) {
            setLoading(true)
            axiosApi.get(CONVOCATORIAS_CREADAS_URI(user.idDirectorio), { cancelToken: cancelTokenGen.token })
                .then(response => {
                    setListaConvocatorias(response.data.data);
                    setLoading(false)
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log("cancelado");
                    } else {
                        console.error(error)
                    }
                });

        }

        return () => {
            cancelTokenGen.cancel();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, showForm]);


    const handleEliminarConvocatoria = (id) => {
        const toastId = toast.loading('Cargando...');
        setLoading(true)
        setOpen(false);
        axiosApi.delete(CONVOCATORIAS_PERF_DELETE_URI(id)).then(response => {
            setListaConvocatorias((current) =>
                current.filter((convocatoria) => convocatoria.idConvocatoria !== id)
            );
            setLoading(false)
            toast.success(response.data.message, {
                id: toastId,
            });

        }).catch(error => {
            console.error(error);
            setLoading(false)
        });



    }

    const handleSelectedId = (id) => {
        setSelectedId(id);
        handleCreateConvo();
    }

    return (<div >
        {showForm ? <SubirConvocatoria handleCloseForm={handleCloseForm} selectedId={selectedId} /> : <div>

            <div className="rb mb-4" style={{ display: "block", overflow: "hidden", width: "100%" }}>

                <div className="rbl">
                    <span>Mis Convocatorias</span>

                </div>

                <button type="button" className="btn btn-danger btn-sm" style={{ float: "right", margin: "0" }} onClick={handleCreateConvo}><FontAwesomeIcon icon={faPlus} /> Agregar convocatoria</button>
            </div>

            {
                loading ? <Spinner /> : listaConvocatorias.length > 0 ? listaConvocatorias.map((values, index) => <ConvocatoriaCreadaItem convocatoria={values} key={index} setIdEliminar={setIdEliminar} setOpen={setOpen} handleSelectedId={handleSelectedId}></ConvocatoriaCreadaItem>) : "No hay convocatorias creadas."
            }




        </div>}

        <Modal show={open} onHide={() => {
            setIdEliminar(null);
            setOpen(false)
        }} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Eliminar convocatoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>¿Seguro desea eliminar esta convocatoria? Presione "Eliminar" para continuar con la operación</span>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setOpen(false) }}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={() => { handleEliminarConvocatoria(idEliminar) }}>
                    Eliminar
                </Button>

            </Modal.Footer>


        </Modal>


    </div>);
}

export default Convocatorias;