import { faArrowRight, faCircleCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Titulo from "../components/utiles/Titulo";
import * as Yup from 'yup';
import { useState } from "react";
import { toast } from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik } from "formik";
import ValidationError from "../components/utiles/ValidationError";
import { useDispatch } from "react-redux";
import { useGetGenerosQuery, useGetRegionesQuery, useGetTipoEspaciosCulturalesQuery, useGetTipoOrganizacionesQuery, useGetTipoPerfilesQuery, useGetTipoPersonasQuery } from "../redux/features/ApiSlice";
import { useEffect } from "react";
import { checkIfFileIsTooBig, getBase64, handleInputRut, regexTelefono, setTitle, validateImageType } from "../constants/utiles/Functions";
import FieldComuna from "../components/form/FieldComuna";
import { getDateNow } from "../constants/utiles/DateFunctions";
import { format, validate } from 'rut.js';
import { REGISTRO_URI } from "../api/axios/constants";
import axiosApi from "../api/axios/axiosApi";
import { TIPO_USUARIO_ORGANIZACION, TIPO_USUARIO_PERSONA } from "../constants/Constants";

const RegistrarUsuario = (params) => {

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const [arrayComunas, setArrayComunas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: regiones = [],
        isLoading: regionesLoad,
    } = useGetRegionesQuery()

    const {
        data: generos = [],
        isLoading: generosLoad,
    } = useGetGenerosQuery();

    const {
        data: perfiles = [],
        isLoading: perfilesLoad,
    } = useGetTipoPerfilesQuery();

    const {
        data: tipoPersonas = [],
        isLoading: tipoPersonasLoad,
    } = useGetTipoPersonasQuery();
    const {
        data: tipoEspacios = [],
        isLoading: tipoEspaciosLoad,
    } = useGetTipoEspaciosCulturalesQuery();

    const {
        data: tipoOrg = [],
        isLoading: tipoOrgLoad,
    } = useGetTipoOrganizacionesQuery();

    useEffect(() => {
        if (!regionesLoad && !generosLoad && !perfiles && !tipoPersonasLoad && !tipoEspaciosLoad && !tipoOrgLoad) {
            setLoading(false)
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionesLoad, generosLoad, tipoPersonasLoad, tipoEspaciosLoad, tipoOrgLoad]);

    useEffect(() => {
        setTitle(params.title)
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadComunas = (region) => {
        setLoading(true);

        setArrayComunas(region)
        setLoading(false);



    }


    const initialValues = {
        nombre: '',
        rut: '',
        tipoPerfil: '',
        tipoPersona: '',
        tipoOrg: '',
        tipoEspacio: '',
        espacioCultural: '0',
        genero: '',
        fechaNacimiento: '',
        originario: '0',
        originarioDetalle: '',
        migrante: '0',
        migranteDetalle: '',
        discapacidad: '0',
        discapacidadDetalle: '',
        terceraEdad: '0',
        email: '',
        confirmEmail: '',
        pass: '',
        confirmPass: '',
        telefono: '',
        confirmTelefono: '',
        region: '',
        comuna: '',
        direccion: '',
        condiciones: false,
        imagen: '',
        web: '',
        youtube: '',
        facebook: '',
        instagram: '',
        otra_rrss: '',
    };



    const validationSchema = Yup.object({

        nombre: Yup.string().required('Campo requerido').max(200, 'El texto ingresado no debe sobrepasar los 200 caracteres'),
        rut: Yup.string().required('Campo requerido').test('rut-formato', 'Rut con formato invalido',
            (value) => {
                return validate(value)
            }
        ),
        genero: Yup.string().required('Debe seleccionar una opción de género'),
        fechaNacimiento: Yup.date().required('Campo requerido').max(now, 'Debe seleccionar una fecha menor a la de hoy'),
        email: Yup.string().email('Correo electronico ingresado invalido').required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        confirmEmail: Yup.string().email('Correo electronico ingresado invalido').required('Campo requerido').when("email", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("email")],
                "Ambos correos electronicos deben ser el mismo"
            )
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        pass: Yup.string().required('Campo requerido'),
        confirmPass: Yup.string().required('Campo requerido').when("pass", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("pass")],
                "Las contraseñas ingresadas deben ser iguales"
            )
        }),
        telefono: Yup.string().matches(regexTelefono, 'Formato de numero de telefono invalido').required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        confirmTelefono: Yup.string().matches(regexTelefono, 'Formato de numero de telefono invalido').required('Campo requerido').when("telefono", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("telefono")],
                "Ambos números de telefono deben ser el mismo"
            )
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        originarioDetalle: Yup.string().when("originario", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        migranteDetalle: Yup.string().when("migrante", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        discapacidadDetalle: Yup.string().when("discapacidad", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        region: Yup.string().required('Campo requerido'),
        comuna: Yup.string().required('Campo requerido'),
        tipoPerfil: Yup.string().required('Campo requerido'),
        tipoPersona: Yup.string().when("tipoPerfil", {
            is: TIPO_USUARIO_PERSONA,
            then: Yup.string().required('Campo requerido')
        }),
        tipoOrg: Yup.string().when("tipoPerfil", {
            is: TIPO_USUARIO_ORGANIZACION,
            then: Yup.string().required('Campo requerido')
        }),
        tipoEspacio: Yup.string().when("espacioCultural", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }),
        direccion: Yup.string().required('Campo requerido').max(200, 'El texto ingresado no debe sobrepasar los 200 caracteres'),
        imagen: Yup.mixed()
            .required('Imagen requerida')
            .test('is-correct-file', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
            .test(
                'is-big-file',
                'Solo se aceptan los siguientes formatos de archivo: .jpeg, .jpg y .gif',
                validateImageType
            ),
        condiciones: Yup.bool().isTrue('Necesita aceptar los terminos y condiciones'),
        web: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        youtube: Yup.string().max(200, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        facebook: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 200 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        instagram: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        otra_rrss: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
    })

    const handleOnSubmit = (values, { resetForm }) => {
        setLoading(true);

        const toastId = toast.loading('Cargando...');
        const data = {
            nombre: values.nombre,
            rut: values.rut,
            password: values.pass,
            fecha_nacimiento: values.fechaNacimiento,
            pueblo_originario: values.originario === '1' ? "true" : "false",
            pueblo_originario_detalle: values.originario === '1' ? values.originarioDetalle : null,
            poblacion_migrante: values.migrante === '1' ? "true" : "false",
            poblacion_migrante_detalle: values.migrante === '1' ? values.migranteDetalle : null,
            discapacidad: values.discapacidad === '1' ? "true" : "false",
            discapacidad_detalle: values.discapacidad === '1' ? values.discapacidadDetalle : null,
            tercera_edad: values.terceraEdad === '1' ? "true" : "false",
            email: values.email,
            telefono: values.telefono,
            direccion: values.direccion,
            id_comuna: values.comuna,
            id_genero: values.genero,
            id_tipo_perfil: values.tipoPerfil,
            espacio_cultural: values.espacioCultural === '1' ? "true" : "false",
            id_tipo_persona: values.tipoPerfil == TIPO_USUARIO_PERSONA ? values.tipoPersona : null,
            id_tipo_organizacion: values.tipoPerfil == TIPO_USUARIO_ORGANIZACION ? values.tipoOrg : null,
            id_tipo_espacio_cultural: values.espacioCultural === '1' ? values.tipoEspacio : null,
            web: values.web,
            youtube: values.youtube,
            facebook: values.facebook,
            instagram: values.instagram,
            otra_rrss: values.otra_rrss
        }
        getBase64(values.imagen)
            .then(result => {
                const base64result = result.split(',')[1];
                data['imagen_name'] = values.imagen.name;
                data['imagen_data'] = base64result;
                data['imagen_mime_type'] = values.imagen.type;
            }).then(() => {
                axiosApi.post(REGISTRO_URI, data
                )
                    .then(response => {
                        if (response.data.error === true) {
                            toast.error(response.data.message, {
                                id: toastId,
                            });

                        } else {
                            toast.success('Registro completado', {
                                id: toastId,
                            });


                            navigate(`/login-usuario`, { replace: true });

                        }
                        setLoading(false)
                    }).catch((error) => {
                        toast.error('Ha ocurrido un error al registrarse', {
                            id: toastId,
                        });

                        console.error(error);
                        setLoading(false)

                    });
                toast.success('Solicitud enviada', {
                    id: toastId,
                });
            });
    }




    return (<Fragment>
        <Titulo>
            <div className="breadcrumbs">
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link className="bc" style={{ borderRight: "solid 0px #ccc !important" }} >Iniciar sesión</Link></li>
                </ul>
            </div>
            <div className="clear"></div>
            <span className="t1b mb-4">Te damos la bienvenida al Catálogo digital</span>

            <p className="p1b">El Catálogo Digital para la programación cultural local es un registro web del Ministerio de las Culturas, las
                Artes y el Patrimonio, que busca generar contactos y redes entre agentes culturales, personas y organizaciones, programadores de
                actividades artísticas y oferentes de obras o proyectos artísticos culturales a lo largo de todo Chile.</p>

            <p>Es una herramienta en constante construcción colaborativa, abierta a la ciudadanía y las comunidades     </p>

            <p className="fw-bold">Aquí podrás acceder con tu cuenta registrada o con clave única, si tienes dificultades de acceso escríbenos al formulario de atención.</p>

        </Titulo>



        <div className="menu2">
            <div className="container">
                <ul>
                    <li><NavLink to="/login-usuario"><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faUserPlus}></FontAwesomeIcon> Iniciar sesión</NavLink></li>
                    <li><NavLink to="/login"><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faCircleCheck}></FontAwesomeIcon> Usar clave única</NavLink></li>
                    <li><NavLink className="m2c" to="/registrar-usuario"><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faUserPlus}></FontAwesomeIcon> Registrarme</NavLink></li>
                </ul>
            </div>
        </div>


        <div className="gris2">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <span style={{ color: "#888" }} className="txt1">¿Tienes problemas para registrarte?  <Link to='/ayuda/contacto'>Consulta aquí</Link></span>
                    </div>
                </div>

                <div className="row">
                    <div className="form rounded mb-4">
                        <span className="tform">Regístrate en Catálogo Digital</span>
                        <span class="pform">¿Ya has creado tu perfil? <Link className="lm" to='/login-usuario'>Ingresa aquí.</Link></span>

                        <div className="row justify-content-md-center mt-4">
                            <div className="col-md-9">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleOnSubmit}
                                >
                                    {(props) => (
                                        <Form className="row g-3">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="nombre" className="form-label">Nombre completo <span className="rojo">*</span></label>
                                                <Field type="text" className='form-control' id="nombre" name='nombre' />

                                                <ErrorMessage name="nombre" component={ValidationError} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="rut" className="form-label">RUT <span className="rojo">*</span></label>
                                                <Field type="rut" className="form-control" id="rut" name="rut" maxLength={13}
                                                    placeholder="Ej: 12.345.678-K"
                                                    pattern="[0-9]{1,3}.[0-9]{3}.[0-9]{3}-[0-9Kk]{1}"
                                                    onBlur={(event) => {
                                                        const formatted = format(props.values.rut);
                                                        props.setFieldValue("rut", formatted);
                                                        props.handleBlur(event);
                                                    }}

                                                    onKeyDown={handleInputRut}
                                                />
                                                <ErrorMessage name="rut" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">¿Espacio de cultura? <span className="rojo">*</span></label>
                                                <div className="clear"></div>
                                                <div className="form-check form-check-inline pt-2">
                                                    <Field className="form-check-input" type="radio" name="espacioCultural" id="espacio_cultural_option1" value={'1'} />
                                                    <label className="form-check-label" htmlFor="espacio_cultural_option1">Si</label>
                                                </div>
                                                <div className="form-check form-check-inline pt-2">
                                                    <Field className="form-check-input" type="radio" name="espacioCultural" id="espacio_cultural_option2" value={'0'} />
                                                    <label className="form-check-label" htmlFor="espacio_cultural_option2">No</label>
                                                </div>

                                                {props.values.espacioCultural === '1' ?
                                                    <div className="mb-0 animation_input mt-3">
                                                        <label htmlFor="tipoEspacio" className="form-label">Tipo de Espacio cultural <span className="rojo">*</span></label>
                                                        <Field name="tipoEspacio" as="select" className="form-control" >
                                                            <option value={''}>Seleccionar</option>
                                                            {tipoEspaciosLoad ? "" : tipoEspacios.data.map((tipo, index) => <option key={index} value={tipo.idEspacioCultural}>{tipo.espacioCultural}</option>)}

                                                        </Field>
                                                        <ErrorMessage name="tipoEspacio" component={ValidationError} />
                                                    </div> : ''
                                                }

                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="tipoPerfil" className="form-label">Tipo de perfil <span className="rojo">*</span></label>
                                                <Field name="tipoPerfil" as="select" className="form-control" >
                                                    <option value={''}>Seleccionar</option>
                                                    {perfilesLoad ? "" : perfiles.data.map((agentes, index) => <option key={index} value={agentes.idTipoPerfil}>{agentes.tipoPerfil}</option>)}

                                                </Field>
                                                <ErrorMessage name="tipoPerfil" component={ValidationError} />

                                                {props.values.tipoPerfil === TIPO_USUARIO_PERSONA ?
                                                    <div className="mb-0 animation_input mt-1">
                                                        <label htmlFor="tipoPersona" className="form-label">Tipo de persona <span className="rojo">*</span></label>
                                                        <Field name="tipoPersona" as="select" className="form-control" >
                                                            <option value={''}>Seleccionar</option>
                                                            {tipoPersonasLoad ? "" : tipoPersonas.data.map((tipo, index) => <option key={index} value={tipo.idTipoPersona}>{tipo.tipoPersona}</option>)}

                                                        </Field>
                                                        <ErrorMessage name="tipoPersona" component={ValidationError} />
                                                    </div> : ''
                                                }

                                                {props.values.tipoPerfil === TIPO_USUARIO_ORGANIZACION ?
                                                    <div className="mb-0 animation_input mt-1">
                                                        <label htmlFor="tipoOrg" className="form-label">Tipo de organización <span className="rojo">*</span></label>
                                                        <Field name="tipoOrg" as="select" className="form-control" >
                                                            <option value={''}>Seleccionar</option>
                                                            {tipoOrgLoad ? "" : tipoOrg.data.map((tipo, index) => <option key={index} value={tipo.idTipoOrganizacion}>{tipo.tipoOrganizacion}</option>)}

                                                        </Field>
                                                        <ErrorMessage name="tipoOrg" component={ValidationError} />
                                                    </div> : ''
                                                }
                                            </div>






                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="genero" className="form-label">Con qué género te identificas<span className="rojo">*</span></label>
                                                <Field name="genero" as="select" className="form-control" >
                                                    <option value='' className="text-muted">Seleccionar</option>
                                                    {
                                                        generosLoad ? "" : generos.data.map((genero, index) => <option key={index} value={genero.idGenero}>
                                                            {genero.genero}
                                                        </option>)
                                                    }
                                                </Field>
                                                <ErrorMessage name="genero" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="fechaNacimiento" className="form-label">Fecha de nacimiento <span className="rojo">*</span></label>
                                                <Field name="fechaNacimiento" type="date" className="form-control" max={getDateNow()} />
                                                <ErrorMessage name="fechaNacimiento" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label className="form-label" >¿Perteneces a algún pueblo originario?</label>
                                                <div className="clear"></div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="originario" id="originario_option1" value={'1'} />
                                                    <label className="form-check-label" htmlFor="originario_option1">Si</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="originario" id="originario_option2" value={'0'} />
                                                    <label className="form-check-label" htmlFor="originario_option2">No</label>
                                                </div>
                                                {props.values.originario === '1' ?
                                                    <div className="mb-0 animation_input">
                                                        <label htmlFor="originarioDetalle" className="form-label">¿Cuál? <span className="rojo">*</span></label>
                                                        <Field type="text" className='form-control' id="originarioDetalle" name='originarioDetalle' />
                                                        <ErrorMessage name="originarioDetalle" component={ValidationError} />
                                                    </div> : ''
                                                }
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">¿Perteneces a población migrante?</label>
                                                <div className="clear"></div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="migrante" id="migrante_option1" value={'1'} />
                                                    <label className="form-check-label" htmlFor="migrante_option1">Si</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="migrante" id="migrante_option2" value={'0'} />
                                                    <label className="form-check-label" htmlFor="migrante_option2">No</label>
                                                </div>

                                                {props.values.migrante === '1' ?
                                                    <div className="mb-0 animation_input mt-1">
                                                        <label htmlFor="migranteDetalle" className="form-label">¿Cuál? <span className="rojo">*</span></label>
                                                        <Field type="text" className='form-control' id="migranteDetalle" name='migranteDetalle' />
                                                        <ErrorMessage name="migranteDetalle" component={ValidationError} />
                                                    </div> : ''
                                                }
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">¿Posees alguna discapacidad?</label>
                                                <div className="clear"></div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="discapacidad" id="discapacidad_option1" value={'1'} />
                                                    <label className="form-check-label" htmlFor="discapacidad_option1">Si</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="discapacidad" id="discapacidad_option1" value={'0'} />
                                                    <label className="form-check-label" htmlFor="discapacidad_option1">No</label>
                                                </div>

                                                {props.values.discapacidad === '1' ?
                                                    <div className="mb-0 animation_input mt-1">
                                                        <label htmlFor="discapacidadDetalle" className="form-label">¿Cuál? <span className="rojo">*</span></label>
                                                        <Field type="text" className='form-control' id="discapacidadDetalle" name='discapacidadDetalle' />
                                                        <ErrorMessage name="discapacidadDetalle" component={ValidationError} />
                                                    </div> : ''
                                                }
                                            </div>



                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">¿Perteneces a la tercera edad?</label>
                                                <div className="clear"></div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="terceraEdad" id="tercera_edad_option1" value={'1'} />
                                                    <label className="form-check-label" htmlFor="tercera_edad_option1">Si</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Field className="form-check-input" type="radio" name="terceraEdad" id="tercera_edad_option2" value={'0'} />
                                                    <label className="form-check-label" htmlFor="tercera_edad_option2">No</label>
                                                </div>





                                            </div>


                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="email" className="form-label">Correo electrónico <span className="rojo">*</span></label>
                                                <Field type="email" className='form-control' id="email" name='email' />
                                                <ErrorMessage name="email" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="confirmEmail" className="form-label">Confirma tu correo electrónico<span className="rojo">*</span></label>
                                                <Field type="email" className='form-control' id="confirmEmail" name='confirmEmail' />
                                                <ErrorMessage name="confirmEmail" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="telefono" className="form-label">Teléfono <span className="rojo">*</span></label>
                                                <Field type="text" className='form-control' id="telefono" name="telefono" />
                                                <ErrorMessage name="telefono" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="confirmTelefono" className="form-label">Confirma tu teléfono <span className="rojo">*</span></label>
                                                <Field type="text" className='form-control' id="confirmTelefono" name="confirmTelefono" />
                                                <ErrorMessage name="confirmTelefono" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="region" className="form-label">Región <span className="rojo">*</span></label>

                                                <Field className='form-control' id="region" name="region" as='select' >
                                                    <option value='' className="text-muted">Seleccionar</option>
                                                    {
                                                        regionesLoad ? "" : regiones.data.map((region, index) => <option key={index} value={region.idRegion}>
                                                            {region.region}
                                                        </option>)
                                                    }


                                                </Field>
                                                <ErrorMessage name="region" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="comuna" className="form-label">Comuna <span className="rojo">*</span></label>
                                                <FieldComuna className='form-control' id="comuna" name="comuna" as='select' loadcomunas={loadComunas}>
                                                    <option value='' className="text-muted">Seleccionar</option>
                                                    {
                                                        arrayComunas.map((comuna, index) => <option key={index} value={comuna.idComuna}>
                                                            {comuna.comuna}
                                                        </option>)
                                                    }

                                                </FieldComuna>
                                                <ErrorMessage name="comuna" component={ValidationError} />
                                            </div>

                                            <div className="col-12 mb-3">
                                                <label htmlFor="direccion" className="form-label">Dirección <span className="rojo">*</span></label>
                                                <Field type="text" className='form-control' id="direccion" name="direccion" placeholder="1234 Main St" />
                                                <ErrorMessage name="direccion" component={ValidationError} />
                                            </div>

                                            <div className="col-12 mb-3">
                                                <label htmlFor="imagen" className="form-label">Imagen de perfil <span className="rojo">*</span></label>
                                                <input id="imagen" name="imagen" type="file" className='form-control' onChange={(event) => {
                                                    props.touched.imagen = true;
                                                    props.setFieldValue("imagen", event.currentTarget.files[0]);
                                                    setFile(URL.createObjectURL(event.target.files[0]));
                                                }} accept="image/png, image/jpeg, image/gif, image/jpg" />

                                                <ErrorMessage name="imagen" component={ValidationError} touched={props.touched.imagen} />
                                                {
                                                    (props.errors.imagen && props.touched.imagen) || !file ? "" : <div className="col-md-3 mt-3 pt-2 pb-2 card mx-auto"> <img src={file} className="rounded-circle mx-auto" style={{ width: "90%", borderRadius: "50%", verticalAlign: "middle", width: "200px", height: "200px" }} /></div>
                                                }

                                            </div>

                                            <div className="row col-12 mx-0 px-0">
                                                <div className="col-md-6 mb-3 px-2">
                                                    <label htmlFor="web" className="form-label">Página Web </label>
                                                    <Field type="text" className='form-control' id="web" name="web" placeholder="https://" />
                                                    <ErrorMessage name="web" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3 px-2">
                                                    <label htmlFor="youtube" className="form-label">Canal de youtube </label>
                                                    <Field type="text" className='form-control' id="youtube" name="youtube" placeholder="https://www.youtube.com/channel/" />
                                                    <ErrorMessage name="youtube" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3 px-2">
                                                    <label htmlFor="facebook" className="form-label">Perfil de Facebook </label>
                                                    <Field type="text" className='form-control' id="facebook" name="facebook" placeholder="https://www.facebook.com/" />
                                                    <ErrorMessage name="facebook" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3 px-2">
                                                    <label htmlFor="instagram" className="form-label">Perfil de instagram </label>
                                                    <Field type="text" className='form-control' id="instagram" name="instagram" placeholder="https://www.instagram.com/" />
                                                    <ErrorMessage name="instagram" component={ValidationError} />
                                                </div>
                                                <div className="col-md-6 mb-2 px-2">
                                                    <label htmlFor="otra_rrss" className="form-label">Otra RRSS </label>
                                                    <Field type="text" className='form-control' id="otra_rrss" name="otra_rrss" placeholder="https://" />
                                                    <ErrorMessage name="otra_rrss" component={ValidationError} />
                                                </div>

                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="pass" className="form-label">Contraseña <span className="rojo">*</span></label>
                                                <Field type="password" className='form-control' id="pass" name='pass' />
                                                <ErrorMessage name="pass" component={ValidationError} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="confirmPass" className="form-label">Confirma tu contraseña<span className="rojo">*</span></label>
                                                <Field type="password" className='form-control' id="confirmPass" name='confirmPass' />
                                                <ErrorMessage name="confirmPass" component={ValidationError} />
                                            </div>


                                            <div className="col-12 mb-3">
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" id="condiciones" name='condiciones' />
                                                    <label className="form-check-label" htmlFor="condiciones">
                                                        He leído y acepto los términos y condiciones de las <a href="https://www.cultura.gob.cl/politica-de-privacidad/" target="_blank">políticas de privacidad</a>
                                                    </label>
                                                </div>
                                                <ErrorMessage name="condiciones" component={ValidationError} />
                                            </div>

                                            <div className="col-12">
                                                <button type="submit" className="btn btn-danger btn-lg" disabled={loading} >Aceptar <FontAwesomeIcon className='ms-1' icon={faArrowRight}></FontAwesomeIcon> </button> <Link to='/' className="btn btn-link btn-lg">Cancelar</Link>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment >);
}

export default RegistrarUsuario;