import { faFileLines, faImage } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faCheckDouble, faCheckToSlot, faDollarSign, faHardDrive, faInfo, faLocationDot, faPenToSquare, faPuzzlePiece, faShield, faUsers, faVanShuttle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Form, Formik, isInteger } from 'formik';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Accordion, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import axiosApi from '../../api/axios/axiosApi';
import { DELETE_PARTICIPANTES_URI, OFERTA_PRIVADO_GET_URI, OFERTA_UPDATE_URI, OFERTA_URI } from '../../api/axios/constants';
import { TAB_MIS_OFERTAS } from '../../constants/ConstantsTabsPrivado';
import { checkIfFileIsTooBig, forEachValues, getBase64, validateDocType, validateImageType } from '../../constants/utiles/Functions';
import FieldCategorias from '../form/FieldCategorias';
import CaracteristicasEspacios from './SubirOfertaComponents/CaracteristicasEspacios';
import CriterioOferta from './SubirOfertaComponents/CriterioOferta';
import DetallesOferta from './SubirOfertaComponents/DetallesOferta';
import ImagenOferta from './SubirOfertaComponents/ImagenOferta';
import MediadorOferta from './SubirOfertaComponents/MediadorOferta';
import ParticipantesOferta from './SubirOfertaComponents/ParticipantesOferta';
import PrecioOferta from './SubirOfertaComponents/PrecioOferta';
import PublicoOferta from './SubirOfertaComponents/PublicoOferta';
import RespaldoOferta from './SubirOfertaComponents/RespaldoOferta';
import TransporteOferta from './SubirOfertaComponents/TransporteOferta';
import UbicacionOferta from './SubirOfertaComponents/UbicacionOferta';
import InfoExtraOferta from './SubirOfertaComponents/InfoExtraOferta';
import FieldEtiquetas from '../form/FieldEtiquetas';

const SubirOferta = ({ user, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [loadOferta, setLoadOferta] = useState(false);
    const navigate = useNavigate();
    const { id_extra } = useParams();
    const [oferta, setOferta] = useState({});
    const [deletedParticipantes, setDeletedParticipantes] = useState([]);
    const [savedParticipantes, setSavedParticipantes] = useState([]);

    useEffect(() => {
        if (id_extra) {
            setLoadOferta(true)
            if (user?.idDirectorio && id_extra) {

                const cancelToken = axios.CancelToken.source();
                axiosApi.get(OFERTA_PRIVADO_GET_URI(id_extra), { cancelToken: cancelToken.token }).then(response => {
                    if (response?.data.error == true) {
                        throw new Error(response.data.message);
                    }
                    setOferta(response?.data?.data[0])
                    setLoadOferta(false)
                    setSavedParticipantes(response.data.data[0].listadoParticipantes);

                    if (user.rut != response.data.data[0].rutDirectorio) {
                        navigate('/home-privado/' + TAB_MIS_OFERTAS, { replace: true })
                    };
                }).catch(error => {
                    if (axios.isCancel(error)) {
                        console.log("cancelado");
                    } else {
                        console.error(error);
                        setLoadOferta(false)
                        toast.error('Ha ocurrido un error al buscar la oferta a editar');
                        navigate('/home-privado/' + TAB_MIS_OFERTAS, { replace: true })
                    }

                });
                return () => {
                    cancelToken.cancel();
                }
            }
        }
        else {
            setLoadOferta(true)
            setOferta({})
            setDeletedParticipantes([])
            setSavedParticipantes([])
            setTimeout(() => {
                setLoadOferta(false)
            }, 300);

        }

    }, [id_extra, user.idDirectorio]);

    const initialValues = {
        criterio: oferta.idCriterio || '',
        categorias: oferta?.categorias?.length > 0 ? oferta.categorias.map((value) => value.idCategoria.toString()) : [],
        requeridos: oferta?.espacioRequeridos?.length > 0 ? oferta.espacioRequeridos.map((value) => value.idEspacio.toString()) : [],
        nombreOferta: oferta.nombreOferta || "",
        descripcion: oferta.descripcion || "",
        valorOferta: oferta.precio || "",
        ofertaProduccion: oferta?.valorIncluyeProduccion === undefined ? "" : oferta?.valorIncluyeProduccion ? "1" : "0",
        region: oferta.idRegion || "",
        comuna: oferta.idComuna || "",
        publico: oferta?.idPublico === undefined ? "" : oferta?.idPublico ? "1" : "0",
        mediador: oferta?.mediador === undefined ? "" : oferta?.mediador ? "1" : "0",
        detalleMediacion: oferta.mediacionDetalle || "",
        numeroPersonas: oferta?.participantes ? oferta?.participantes >= 0 ? oferta?.participantes : "" : "",
        pasajeros: oferta?.pasajeros === undefined ? "" : oferta?.pasajeros ? "1" : "0",
        carga: oferta?.carga === undefined ? "" : oferta?.carga ? "1" : "0",
        detalleCarga: oferta.cargaPeso || "",
        especifiqueOtro: oferta?.espacioRequeridoEspecifique || "",
        expositivo: oferta?.idExpositivo || "",
        dimensiones: oferta?.expositivoOtro || '',
        duracion: oferta?.idDuracionFuncion || "",
        duracionTotal: oferta?.idDuracionTotal || "",
        imagen: null,
        respaldo: null,
        participantes: [],
        youtube: oferta.videoPromocional || "",

        etiquetas: oferta?.etiquetas?.length > 0 ? oferta.etiquetas.map((value) => value.idEtiqueta.toString()) : [],
        contemplaIVA: typeof(oferta.contemplaIva) === 'undefined' || oferta.contemplaIva === null ? "" : oferta?.contemplaIva ? "1" : "0",

    };


    const validationSchema = Yup.object({
        criterio: Yup.string().required('Debe seleccionar un criterio'),
        categorias: Yup.array().required(
            "Debe seleccionar al menos 1 categoria"
        ).min(1, 'Debe seleccionar al menos 1 categoria').max(3, 'No puede seleccionar más de 3 opciones'),

        requeridos: Yup.array(),
        nombreOferta: Yup.string().required('Campo necesario').max(150, 'El texto ingresado no debe sobrepasar los 150 caracteres'),
        descripcion: Yup.string().max(65000, 'El texto ingresado no debe sobrepasar los 65000 caracteres'),
        valorOferta: Yup.number().required('Campo necesario').max(2000000000, 'El numero no puede ser superior a 2.000.000.000').typeError("Solo debe ingresar numeros"),
        ofertaProduccion: Yup.string().required('Campo requerido'),
        region: Yup.string().required('Campo requerido'),
        comuna: Yup.string().required('Campo requerido'),
        publico: Yup.string().required('Campo requerido'),
        mediador: Yup.string().required('Campo requerido'),
        detalleMediacion: Yup.string().when("mediador", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }),
        detalleMediacion: Yup.string().when("mediador", {
            is: '1',
            then: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres')
        }),
        numeroPersonas: Yup.number().required('Campo requerido').max(2000000000, 'El numero no puede ser superior a 2.000.000.000').typeError("Solo debe ingresar numeros"),
        pasajeros: Yup.boolean().required('Campo requerido'),
        carga: Yup.string().required('Campo requerido'),
        detalleCarga: Yup.string().when("carga", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }),
        especifiqueOtro: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        expositivo: Yup.string().required('Campo requerido'),
        dimensiones: Yup.string().when("expositivo", {
            is: '7',
            then: Yup.string().required('Campo requerido')
        }),
        duracion: Yup.string().required('Campo requerido'),
        duracionTotal: Yup.string().required('Campo requerido'),
        participantes: Yup.array(),
        imagen: id_extra ? Yup.mixed()
            .nullable()
            .test('is-correct-file', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
            .test(
                'is-big-file',
                'Solo se aceptan los siguientes formatos de archivo: .jpeg, .jpg y .gif',
                validateImageType
            ) : Yup.mixed()
                .nullable()
                .required('Imagen requerida')
                .test('is-correct-file', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
                .test(
                    'is-big-file',
                    'Solo se aceptan los siguientes formatos de archivo: .jpeg, .jpg y .gif',
                    validateImageType
                ),
        respaldo: Yup.mixed()
            .nullable()
            .test('respaldo-tamanio', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
            .test(
                'respaldo-formato',
                'Solo se aceptan los siguientes formatos de archivo: .pdf',
                validateDocType
            ),
        youtube: Yup.string().max(150, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        dossier: Yup.mixed()
            .nullable()
            .test('dossier-tamanio', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
            .test(
                'dossier-formato',
                'Solo se aceptan los siguientes formatos de archivo: .pdf',
                validateDocType
            ),

        etiquetas: Yup.array().required(
            "Debe seleccionar al menos 1 etiqueta"
        ).min(1, 'Debe seleccionar al menos 1 etiqueta'),
        contemplaIVA: Yup.string().required('Campo requerido'),

    })

    const handleOnSubmit = (values) => {
        setLoading(true)
        const toastId = toast.loading('Cargando...');
        const data = {
            id_directorio: user.idDirectorio,
            id_criterio: values.criterio,
            categorias: JSON.stringify(values.categorias),
            nombre_oferta: values.nombreOferta,
            descripcion: values.descripcion,
            precio: values.valorOferta,
            valor_incluye_pro: values.ofertaProduccion === '1' ? "true" : "false",
            exento_iva: false,
            id_comuna: values.comuna,
            mediador: values.mediador === '1' ? "true" : "false",
            mediacion_detalle: values.mediador === '1' ? values.detalleMediacion : null,
            id_publico: values.publico,
            total_participantes: values.numeroPersonas,
            pasajeros: values.pasajeros === '1' ? "true" : "false",
            carga: values.carga === '1' ? "true" : "false",
            carga_peso: values.carga === '1' ? values.detalleCarga : null,
            espacios_requeridos: JSON.stringify(values.requeridos),
            espacio_requerido_otro: values.especifiqueOtro,
            id_expositivo: values.expositivo,
            espacio_expositivo_otro: values.expositivo == '7' ? values.dimensiones : null,
            id_duracion_funcion: values.duracion,
            id_duracion_total: values.duracionTotal,
            participantes: JSON.stringify(values.participantes),
            video_promocional: values.youtube,
            etiquetas: JSON.stringify(values.etiquetas),
            contempla_iva: values.contemplaIVA === '1' ? "true" : "false",


        }


        let arrayFiles = [];

        if (values.imagen) {
            const imagen_func = () => getBase64(values.imagen)
                .then(result => {
                    const base64result = result.split(',')[1];
                    data['imagen_name'] = values.imagen.name;
                    data['imagen_data'] = base64result;
                    data['imagen_mime_type'] = values.imagen.type;

                })
                .catch(err => {
                    console.error(err);
                });


            arrayFiles.push(imagen_func);
        }

        if (values.respaldo) {
            const respaldo_func = () => getBase64(values.respaldo)
                .then(result => {
                    const base64result = result.split(',')[1];
                    data['adjunto_name'] = values.respaldo.name;
                    data['adjunto_data'] = base64result;
                    data['adjunto_mime_type'] = values.respaldo.type;
                })
                .catch(err => {
                    console.error(err);
                });
            arrayFiles.push(respaldo_func);
        }

        if (values.dossier) {
            const dossier_func = () => getBase64(values.dossier)
                .then(result => {
                    const base64result = result.split(',')[1];
                    data['dossier_name'] = values.dossier.name;
                    data['dossier_data'] = base64result;
                    data['dossier_mime_type'] = values.dossier.type;
                })
                .catch(err => {
                    console.error(err);
                });
            arrayFiles.push(dossier_func);
        }

        forEachValues(arrayFiles)
            .then(() => {
                if (id_extra) {
                    axiosApi.put(OFERTA_UPDATE_URI(id_extra), data)
                        .then(response => {
                            if (response.data.error === false) {
                                deletedParticipantes.forEach(id_participante => {
                                    axiosApi.delete(DELETE_PARTICIPANTES_URI(id_participante))
                                });
                                toast.success(response.data.message, {
                                    id: toastId,
                                });
                                navigate('/home-privado/' + TAB_MIS_OFERTAS);
                            } else {
                                toast.error('Ocurrio un error al intentar actualizar la oferta, intente nuevamente', {
                                    id: toastId,
                                });
                            }

                            setLoading(false)
                        }).catch(error => {
                            toast.error('Ocurrio un error al intentar actualizar la oferta, intente nuevamente', {
                                id: toastId,
                            });
                            setLoading(false)
                        })
                } else {
                    axiosApi.post(OFERTA_URI, data)
                        .then(response => {
                            if (response.data.error === false) {
                                toast.success(response.data.message, {
                                    id: toastId,
                                });
                                navigate('/home-privado/' + TAB_MIS_OFERTAS);
                            } else {
                                toast.error('Ocurrio un error al intentar ingresar la oferta, intente nuevamente', {
                                    id: toastId,
                                });
                            }

                            setLoading(false)
                        }).catch(error => {
                            toast.error('Ocurrio un error al intentar ingresar la oferta, intente nuevamente', {
                                id: toastId,
                            });
                            setLoading(false)
                        })
                }
            })
    }


    const handleDeleteParticipantes = (id_participante) => {
        deletedParticipantes.push(id_participante)
        setDeletedParticipantes(deletedParticipantes);
        setSavedParticipantes((current) =>
            current.filter((participante) => participante.idParticipante !== id_participante)
        );
    }




    return (<Fragment>

        <div className="rb">
            <div className="rbl mb-4">
                <span>{id_extra ? "Editar Oferta" : "Subir Oferta"} <a className="edit1"><FontAwesomeIcon icon={faPenToSquare} /></a></span>
            </div>
        </div>

        <div className="clear"></div>

        {
            loadOferta ? <div className="mt-1 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> : <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}

            >
                {props => (
                    <Form className="animation_input">

                        <Accordion defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]} alwaysOpen>

                            <Accordion.Item eventKey={1}>
                                <Accordion.Header>
                                    <span className="icon-acc i-morado rounded-circle">
                                        <FontAwesomeIcon icon={faFileLines} />
                                    </span>
                                    <span className="acc-tit">Cambiar criterio</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <CriterioOferta />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey={2}>
                                <Accordion.Header>
                                    <span className="icon-acc i-verde rounded-circle">
                                        <FontAwesomeIcon icon={faPuzzlePiece} />
                                    </span>
                                    <span className="acc-tit"> Categoria oferta</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <FieldCategorias ></FieldCategorias>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey={3}>
                                <Accordion.Header>
                                    <span className="icon-acc i-rojo rounded-circle">
                                        <FontAwesomeIcon icon={faCheckToSlot} />
                                    </span>
                                    <span className="acc-tit"> Detalles oferta</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <DetallesOferta />
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey={4}>
                                <Accordion.Header>
                                    <span className="icon-acc i-naranjo rounded-circle">
                                        <FontAwesomeIcon icon={faImage} />
                                    </span>
                                    <span className="acc-tit"> Imagen de la oferta</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ImagenOferta defaultImage={oferta?.imagen} />
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey={5}>
                                <Accordion.Header>
                                    <span className="icon-acc i-morado rounded-circle">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <span className="acc-tit"> Precio de la oferta</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <PrecioOferta />
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey={6}>
                                <Accordion.Header>
                                    <span className="icon-acc i-azul rounded-circle">
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </span>
                                    <span className="acc-tit"> Ubicación de la oferta</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <UbicacionOferta />
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey={7}>
                                <Accordion.Header>
                                    <span className="icon-acc i-rojo rounded-circle">
                                        <FontAwesomeIcon icon={faUsers} />
                                    </span>
                                    <span className="acc-tit"> Público</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <PublicoOferta />
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey={8}>
                                <Accordion.Header>
                                    <span className="icon-acc i-verde rounded-circle">
                                        <FontAwesomeIcon icon={faShield} />
                                    </span>
                                    <span className="acc-tit"> Mediación</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <MediadorOferta />
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey={9}>
                                <Accordion.Header>
                                    <span className="icon-acc i-verde rounded-circle">
                                        <FontAwesomeIcon icon={faPuzzlePiece} />
                                    </span>
                                    <span className="acc-tit"> Etiquetas</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <FieldEtiquetas ></FieldEtiquetas>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey={10}>
                                <Accordion.Header>
                                    <span className="icon-acc i-morado rounded-circle">
                                        <FontAwesomeIcon icon={faCheckDouble} />
                                    </span>
                                    <span className="acc-tit"> Participantes</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ParticipantesOferta savedParticipantes={savedParticipantes} handleDeleteParticipantes={handleDeleteParticipantes} />
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey={11}>
                                <Accordion.Header>
                                    <span className="icon-acc i-azul rounded-circle">
                                        <FontAwesomeIcon icon={faVanShuttle} />
                                    </span>
                                    <span className="acc-tit"> Requerimiento de transporte</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <TransporteOferta />
                                </Accordion.Body>
                            </Accordion.Item>



                            <Accordion.Item eventKey={12}>
                                <Accordion.Header>
                                    <span className="icon-acc i-rojo rounded-circle">
                                        <FontAwesomeIcon icon={faVanShuttle} />
                                    </span>
                                    <span className="acc-tit"> Características de espacio requerido</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <CaracteristicasEspacios></CaracteristicasEspacios>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey={13}>
                                <Accordion.Header>
                                    <span className="icon-acc i-verde rounded-circle">
                                        <FontAwesomeIcon icon={faHardDrive} />
                                    </span>
                                    <span className="acc-tit"> Medios de respaldo</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <RespaldoOferta respaldoDefault={oferta?.medioRespaldo} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey={14}>
                                <Accordion.Header>
                                    <span className="icon-acc i-verde rounded-circle">
                                        <FontAwesomeIcon icon={faInfo} />
                                    </span>
                                    <span className="acc-tit"> Información complementaria</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <InfoExtraOferta dossierDefault={oferta?.dossier_pdf} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <div className="col-12 mt-3">
                            <button type="submit" className="btn btn-danger btn-lg" disabled={loading}>Subir oferta <FontAwesomeIcon className='ms-1' icon={faArrowRight}></FontAwesomeIcon></button>
                        </div>

                    </Form>
                )
                }
            </Formik>

        }


    </Fragment>);
}

export default SubirOferta;