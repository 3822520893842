import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useGetConvoGuardadasDetailQuery } from "../../../redux/features/ApiSlice";
import SelectOrden from "../../busquedaAssets/SelectOrden";
import Paginacion from "../../utiles/Paginacion";
import ConvocatoriaItem from "../ConvocatoriaItem";

const ConvocatoriaElementos = (props) => {
    const [currentPageConvo, setCurrentPageConvo] = useState(1);
    const [listaConvocatorias, setListaConvocatorias] = useState('');
    const [orderConvo, setOrderConvo] = useState('desc');
    const perPage = 4;

    let skip = false;
    if (!props.user.idDirectorio) {
        skip = true;
    }
    const { data = [], isFetching, isLoading, isSuccess } = useGetConvoGuardadasDetailQuery(props.user.idDirectorio, { skip });



    useEffect(() => {
        if (isSuccess) {

            let queryConvo = [];
            data.forEach(element => {
                queryConvo.push(element)
            });

            const arrayOrder = queryConvo.sort((a, b) => (a.idConvocatoria < b.idConvocatoria) ? 1 : (a.idConvocatoria > b.idConvocatoria) ? -1 : 0);
            setListaConvocatorias(arrayOrder);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isSuccess]);



    useEffect(() => {
        if (listaConvocatorias) {
            let order;
            if (orderConvo === "desc") {
                order = listaConvocatorias.sort((a, b) => (a.idConvocatoria < b.idConvocatoria) ? 1 : (a.idConvocatoria > b.idConvocatoria) ? -1 : 0);
            } else if (orderConvo === "asc") {
                order = listaConvocatorias.sort((a, b) => (a.idConvocatoria > b.idConvocatoria) ? 1 : (a.idConvocatoria < b.idConvocatoria) ? -1 : 0);

            }
            setListaConvocatorias([...order]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderConvo]);

    const handlePageClickConvo = (data) => {
        const page = data.selected + 1;

        setCurrentPageConvo(page);
    }

    return (<div>
        <div className="rb mb-4" style={{ display: "block", overflow: "hidden", width: "100%" }}>
            <div className="rbl">
                <span>Convocatorias guardadas</span>
            </div>
            <div className="row g-3" style={{ float: "right", marginTop: "-10px" }}>
                {
                    !listaConvocatorias ? "" : <SelectOrden handleOrdering={setOrderConvo} order={orderConvo} />
                }

            </div>
        </div>
        <div className="clear"></div>


        {isFetching || isLoading || !listaConvocatorias ? <div className='row' ><Spinner className="mx-auto mb-4" /></div> :
            listaConvocatorias.slice((currentPageConvo - 1) * perPage, currentPageConvo * perPage).map((convocatoria, index) => <ConvocatoriaItem
                key={index}
                convocatoria={convocatoria}
            />)
        }
        {
            listaConvocatorias ? <div className="mt-3">
                <Paginacion
                    pageCount={Math.ceil(listaConvocatorias.length / perPage)}
                    currentpage={currentPageConvo}
                    handlePageClick={handlePageClickConvo}
                />
            </div> : ""
        }

    </div>)
}

export default ConvocatoriaElementos;