import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import ValidationError from "../../utiles/ValidationError";
import ParticipantesModal from "./ParticipantesModal";

const ParticipantesOferta = (props) => {
    const [open, setOpen] = useState(false);

    let {
        values: { participantes },
        setFieldValue
    } = useFormikContext();

    const handleAddParticipantes = (data) => {
        participantes.push(data)
        setFieldValue('participantes', participantes, false);



    }

    const handleRemoveParticipantes = (id) => {
        participantes.splice(id, 1)
        setFieldValue('participantes', participantes, false)

    }


    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                <div className="col-md-12">
                    <label htmlFor="numeroPersonas" className="form-label">Número de personas participando en la oferta <span className="rojo">*</span></label>
                    <Field type="number" className='form-control' id="numeroPersonas" name='numeroPersonas' />

                    <ErrorMessage name="numeroPersonas" component={ValidationError} />
                </div>

            </div>

            <div className="mt-3">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>

                            <th>Nombre</th>
                            <th>Cargo</th>
                            <th>Página web</th>
                            <th>Facebook</th>
                            <th>Instagram</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.savedParticipantes ? props.savedParticipantes.map((values, index) =>
                            <tr key={index}>

                                <td>{values.nombreParticipante}</td>
                                <td>{values.cargo}</td>
                                <td>{values.web}</td>
                                <td>{values.facebook}</td>
                                <td>{values.instagram}</td>
                                <td><a onClick={() => props.handleDeleteParticipantes(values.idParticipante)}><FontAwesomeIcon icon={faTrash} className='mx-2' /></a></td>


                            </tr>) : ""}
                        {participantes.map((values, index) =>
                            <tr key={index}>

                                <td>{values.nombre_participante}</td>
                                <td>{values.cargo}</td>
                                <td>{values.web}</td>
                                <td>{values.facebook}</td>
                                <td>{values.instagram}</td>
                                <td style={{textAlign: "center"}}><a onClick={() => handleRemoveParticipantes(index)} ><FontAwesomeIcon icon={faTrash} className='mx-2' /></a></td>


                            </tr>)}
                    </tbody>
                </Table>
            </div>

            <div className="mt-2">
                <Button variant="info" onClick={() => setOpen(true)}>Agregar Participante <FontAwesomeIcon icon={faPlusCircle} /></Button>
            </div>

        </div>

        <ParticipantesModal open={open} setOpen={setOpen} handleAddParticipante={handleAddParticipantes} />
    </div>)

}

export default ParticipantesOferta;