import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { useGetCategoriasQuery, useGetEtiquetasOfertaQuery } from "../../redux/features/ApiSlice";
import ValidationError from "../utiles/ValidationError";

const FieldEtiquetas = (props) => {

    const {
        values: { etiquetas },
    } = useFormikContext();
    const {
        data: etiquetasArray = [],
    } = useGetEtiquetasOfertaQuery();

    return (
        <div className="card">
            <div className="card-header">
                <strong>Seleccionar Etiquetas</strong>  (Mínimo 1 etiqueta)
            </div>
            <div className="card-body">
                <div className="row g-3">

                    {
                        etiquetasArray?.data ? etiquetasArray.data.map((etiqueta, index) => {
                            return (
                                <div className="col-md-4" key={index+"etiquetas"}>
                                    <div className="form-check">
                                        <label className="form-check-label" >
                                            <Field className="form-check-input" type="checkbox" name="etiquetas" value={etiqueta.idEtiqueta} {...props} checked={etiquetas.find((value) => etiqueta.idEtiqueta == value) ? true : false} />
                                            {etiqueta.etiqueta}
                                        </label>
                                    </div>
                                </div>
                            )
                        }) : ""
                    }

                </div>
                <ErrorMessage name="etiquetas" component={ValidationError} />
            </div>
        </div>
    )


    
}

export default FieldEtiquetas;



