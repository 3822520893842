import { Link } from "react-router-dom";
import { getDateFormatNoticia } from "../../constants/utiles/DateFunctions";
import Image from "../utiles/Image";

const ItemNoticia = ({ imagen, descripcion, titulo, fechaTiempo, tiempoLectura, categoria, idNoticia }) => {
    return (

        <div className="col-md-4 animation_input">
            <div className="noticia">
                <Link to={`/noticia/${idNoticia}`}>
                    <Image className="rounded mb-3" src={imagen} />
                </Link>
                <div className="ref-not">{getDateFormatNoticia(fechaTiempo)} / {tiempoLectura} min <span className="rounded">{categoria}</span></div>
                <span className="tit-not">{titulo}</span>
                <p className="p-not">{descripcion}</p>

            </div>
        </div>
    )
}

export default ItemNoticia;