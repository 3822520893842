import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useGetRegionesQuery } from "../../redux/features/ApiSlice";
import InputComuna from "../form/InputComuna";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const FiltroForm = (params) => {
    const [region, setRegion] = useState(params.filtros.region || "");
    const [comuna, setComuna] = useState(params.filtros.comuna || "");
    const [palabra, setPalabra] = useState(params.filtros.palabra || "");
    const [espacioCultura, setEspacioCultura] = useState(
        params.filtros.espacioCultura || ""
    );

    const [tags, setTags] = useState(params.tags);

    useEffect(() => {
        setRegion(params.filtros.region || "");
        setComuna(params.filtros.comuna || "");
        setPalabra(params.filtros.palabra || "");
        setEspacioCultura(params.filtros.espacioCultura || "");

        setTags(params.tags);
    }, [params.filtros]);

    const { data: regiones = [], isSuccess: regionesSuccess } =
        useGetRegionesQuery();

    const handleLimpiar = () => {
        setRegion("");
        setComuna("");
        setPalabra("");
        setEspacioCultura("");

        setTags({});

        params.handleClearFilter(true);
    };

    const handleSubmit = (data) => {
        params.handleFilter(
            data
                ? data
                : {
                    comuna: comuna,
                    region: region,
                    palabra: palabra,
                    espacioCultura: espacioCultura,
                }
        );
    };

    const handleTags = (filtros) => {
        let tags_html = [];

        for (const property in filtros) {
            if (filtros[property] !== "") {
                tags_html.push(
                    <li key={property} className="rounded">
                        {filtros[property]}{" "}
                        <a
                            onClick={() => removeTag(property)}
                            style={{ cursor: "pointer" }}
                        >
                            x
                        </a>
                    </li>
                );
            }
        }

        return <ul>{tags_html}</ul>;
    };

    const removeTag = (input) => {
        const data = {
            comuna: comuna,
            region: region,
            palabra: palabra,
            espacioCultura: espacioCultura,
        };
        switch (input) {
            case "region":
                setTags({ ...tags, region: "" });
                setRegion("");

                data.region = "";
                break;
            case "comuna":
                setTags({ ...tags, comuna: "" });
                setComuna("");

                data.comuna = "";
                break;
            case "espacioCultura":
                setTags({ ...tags, espacioCultura: "" });
                setEspacioCultura("");

                data.espacioCultura = "";
                break;
            default:
                break;
        }

        handleSubmit(data);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    return (
        <div className="filtros rounded">
            <div className="ft">
                <span>
                <FontAwesomeIcon className='me-1' icon={faFilter}></FontAwesomeIcon> Filtros
                </span>
            </div>
            <div className="animation_input">
                <div
                    className="loading_class"
                    style={{ display: params.loading ? "" : "none" }}
                >
                    <div style={{ position: "relative", top: "50%" }}>
                        <div style={{ textAlign: "center" }}>
                            <Spinner></Spinner>
                        </div>
                    </div>
                </div>
                <div className="ft2">
                    <p>Palabra clave</p>
                    <input
                        type="text"
                        className="form-control"
                        name="palabra"
                        id="palabra"
                        placeholder="Texto a buscar"
                        value={palabra}
                        onChange={(e) => setPalabra(e.target.value)}
                        onKeyUp={handleKeyDown}
                    />
                </div>
                <div className="ft2">
                    <div className="mt-1">
                        <button onClick={() => handleSubmit()} type="button" className="btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={faFilter} /> Aplicar filtros</button>
                        <button onClick={handleLimpiar} type="button" className="ft2l pe-auto btn btn-outline-secondary btn-sm"> Limpiar todo</button>
                    </div>

                    <div className="clear"></div>
                    {handleTags(tags)}

                    <div className="clear"></div>
                </div>

                <div className="ft3">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={region}
                        onChange={(e) => {
                            setRegion(e.target.value);
                            setComuna("");
                        }}
                    >
                        <option value={""}>Región</option>
                        {regionesSuccess
                            ? regiones.data.map((values, index) => (
                                <option key={index} value={values.idRegion}>
                                    {values.region}
                                </option>
                            ))
                            : ""}
                    </select>

                    <InputComuna
                        region={region}
                        handleComuna={setComuna}
                        comuna={comuna}
                    ></InputComuna>

                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={espacioCultura}
                        onChange={(e) => {
                            setEspacioCultura(e.target.value);
                        }}
                    >
                        <option value={""}>Espacio de cultura</option>
                        <option value={"1"}>Si</option>
                        <option value={"0"}>No</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default FiltroForm;

FiltroForm.propTypes = {
    loading: PropTypes.bool,
};

FiltroForm.defaultProps = {
    loading: false,
};
