import { faDownload, faEye, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileWord, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { BUSQUEDA_RECURSOS_URI } from "../api/axios/constants";
import SelectOrden from "../components/busquedaAssets/SelectOrden";
import FiltroForm from "../components/recursosAssets/FiltroForm";
import Paginacion from "../components/utiles/Paginacion";
import Titulo from "../components/utiles/Titulo";
import { getMonths, getYearsSinceStartYear } from "../constants/utiles/DateFunctions";
import { setTitle } from "../constants/utiles/Functions";
import { setLoading } from "../redux/features/PrincipalSlice";
import { Spinner } from "react-bootstrap";
import { useGetTextoQuery } from "../redux/features/ApiSlice";
import { ID_RECURSOS } from "../constants/Constants";

const Recursos = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [arrayRecursos, setArrayRecursos] = useState([]);
    const [order, setOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState([]);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const perPage = 10;

    useEffect(() => {
        setTitle(props.title)
    }, []);

    const {
        data: texto_recurso,
        isSuccess: isSuccessTexto
      } = useGetTextoQuery(ID_RECURSOS);

    useEffect(() => {
        setOrder('desc');
        const cancelToken = axios.CancelToken.source();
        setLoading(true);
        axiosApi.get(BUSQUEDA_RECURSOS_URI, { cancelToken: cancelToken.token, params: handleInitialParams() })
            .then(response => {
                const result = response.data.data ? response.data.data.sort((a, b) => (a.idRecurso < b.idRecurso) ? 1 : (a.idRecurso > b.idRecurso) ? -1 : 0) : [];
                setArrayRecursos(result);
                setFiltros(handleFilterTags(response.data));
                setCurrentPage(1);
                setLoading(false);
            }).catch((error) => {
                if (axios.isCancel(error)) {
                    console.log("cancelado");

                } else {
                    console.error(error)
                    setLoading(false);
                }

            });

        return () => {
            cancelToken.cancel()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);



    useEffect(() => {
        if (arrayRecursos) {
            let orderResult;
            if (order === "desc") {
                orderResult = arrayRecursos.sort((a, b) => (a.idRecurso < b.idRecurso) ? 1 : (a.idRecurso > b.idRecurso) ? -1 : 0);
            } else if (order === "asc") {
                orderResult = arrayRecursos.sort((a, b) => (a.idRecurso > b.idRecurso) ? 1 : (a.idRecurso < b.idRecurso) ? -1 : 0);
            }
            setArrayRecursos([...orderResult]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const handleFilterTags = (data) => {
        let filters = [];
        if (data.anio) {
            filters.push({ id: data.anio, nombre: data.anio, input: "anio" });
        }

        return filters;
    }

    const handleInitialParams = () => {
        return {
            texto_buscar: searchParams.get('palabra') ? decodeURIComponent(searchParams.get('palabra')) : null,
            anio: searchParams.get('anio'),
        }

    }


    const handlePageClick = (data) => {
        const page = data.selected + 1;

        setCurrentPage(page);
    }

    const handleExtension = (extension) => {
        switch (extension.trim()) {
            case 'pdf':
            case 'PDF':
                return <span className="extension-icon pdf-icon rounded-circle"><FontAwesomeIcon icon={faFilePdf} /></span>
            case 'xlsx':
            case 'xls':
            case 'csv':
                return <span className="extension-icon excel-icon rounded-circle"><FontAwesomeIcon icon={faFileExcel} /></span>
            case 'ppt':
            case 'pptx':
            case 'pptm':
                return <span className="extension-icon ppt-icon rounded-circle"><FontAwesomeIcon icon={faFilePowerpoint} /></span>
            case 'doc':
            case 'docx':
                return <span className="extension-icon word-icon rounded-circle"><FontAwesomeIcon icon={faFileWord} /></span>
            case 'jpg':
            case 'jpeg':
            case 'png':
                return <span className="extension-icon img-icon rounded-circle"><FontAwesomeIcon icon={faFileImage} /></span>
            default: return ''
        }

    }


    return (<div>
        <Titulo>
            <div className="breadcrumbs">
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }} to="/recursos">Recursos</Link></li>
                </ul>
            </div>
            <div className="clear"></div>
            <span className="t1b">{isSuccessTexto ? texto_recurso?.data[0].titulo : ''}</span>
            <p>{isSuccessTexto ? texto_recurso?.data[0].contenido : ''}</p>
        </Titulo>

        <div className="gris2">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <FiltroForm
                            palabra={searchParams.get('palabra') ? decodeURIComponent(searchParams.get('palabra')) : null}
                            anio={searchParams.get('anio')}
                            setOrder={setOrder}
                            filtros={filtros}
                            loading={loading}
                        />
                    </div>




                    <div className="col-md-9">
                        <div className="form rounded mb-4">
                            {
                                loading ? <div className="mt-4">
                                    <div style={{ textAlign: "center" }} >
                                        <Spinner ></Spinner>
                                    </div>
                                </div> :


                                    <div>
                                        <div className="menu3" style={{ margin: '0' }} >
                                            <div className="container">
                                                <div className="row g-3" style={{ float: "right", marginTop: "-50px" }}>
                                                    <SelectOrden handleOrdering={setOrder} order={order} />
                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                        </div>


                                        <table className="table table-striped">

                                            <tbody>
                                                {arrayRecursos.slice((currentPage - 1) * perPage, currentPage * perPage).map((values, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{handleExtension(values.tipoArchivo)}</td>
                                                            <td>{values.titulo} <p style={{ display: "block", margin: "0", fontSize: "13px", textDecoration: "none", color: "#999" }}>{values.descripcion}</p></td>
                                                            <td>
                                                                <div className="link-tabla">
                                                                    <a href={values.archivo} target='_blank' download rel="noreferrer"><FontAwesomeIcon icon={faDownload} /> Descargar</a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        <Paginacion
                                            pageCount={Math.ceil(arrayRecursos.length / perPage)}
                                            currentpage={currentPage}
                                            handlePageClick={handlePageClick}
                                        />
                                    </div>

                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>);
}

export default Recursos;