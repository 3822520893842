
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import axiosApi from '../../api/axios/axiosApi';
import { CONVOCATORIAS_PERF_UPDATE_URI, CONVOCATORIA_ADD_URI, CONVOCATORIA_PERF_GET_URI } from '../../api/axios/constants';
import { useGetCategoriaConvoQuery, useGetEtiquetasConvoQuery, useGetRegionesQuery } from '../../redux/features/ApiSlice';
import FieldComuna from '../form/FieldComuna';
import ValidationError from '../utiles/ValidationError';
import CondicionesModal from '../convocatoriaAssets/CondicionesModal';
import { ID_POLITICAS_CONVOCATORIAS } from '../../constants/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';



const SubirConvocatoria = (props) => {

    const { idDirectorio } = useSelector((state) => state.userActive);
    const [loading, setLoading] = useState(false);
    const [loadConvo, setLoadConvo] = useState(false);
    const [arrayComunas, setArrayComunas] = useState([]);
    const [convocatoria, setConvocatoria] = useState({});
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        const cancelTokenGen = axios.CancelToken.source();
        if (props.selectedId) {
            setLoadConvo(true)
            axiosApi.get(CONVOCATORIA_PERF_GET_URI(props.selectedId), { cancelToken: cancelTokenGen.token })
                .then(response => {
                    setConvocatoria(response.data.data[0]);
                    setLoadConvo(false)
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log("cancelado");
                    } else {
                        console.error(error)
                        setLoadConvo(false)
                    }
                });

        }

        return () => {
            cancelTokenGen.cancel();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedId]);
    const {
        data: arrayCategorias = [],
        isSuccess: categoriasSuccess
    } = useGetCategoriaConvoQuery();


    const {
        data: regiones = [],
        isLoading: regionesLoad,

    } = useGetRegionesQuery();


    const {
        data: convocatoriaArray = [],
    } = useGetEtiquetasConvoQuery();


    const loadComunas = (region) => {
        setArrayComunas(region)
    }


    const initialValues = {

        nombreConvocatoria: convocatoria.convocatoriaNombre || "",
        descripcion: convocatoria.descripcion || "",
        descripcionBreve: convocatoria.descripcionCorta || "",
        fechaInicio: convocatoria.fechaHoraInicio || "",
        fechaFin: convocatoria.fechaHoraFin || "",
        enlace: convocatoria.enlace || "",
        idCategoria: convocatoria.idCategoria || "",
        region: convocatoria.idRegion || "",
        comuna: convocatoria.idComuna || "",
        direccion: convocatoria.direccion || "",
        etiquetas: convocatoria?.etiquetas?.length > 0 ? convocatoria.etiquetas.map((value) => value.idEtiqueta.toString()) : [],
        condiciones: false,
    };


    const validationSchema = Yup.object({
        nombreConvocatoria: Yup.string().required('Campo necesario').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        descripcion: Yup.string().required('Campo necesario').max(65000, 'El texto ingresado no debe sobrepasar los 65000 caracteres'),
        descripcionBreve: Yup.string().required('Campo necesario').max(250, 'El texto ingresado no debe sobrepasar los 250 caracteres'),
        fechaInicio: Yup.date().required('Campo requerido').max(
            Yup.ref('fechaFin'),
            "Fecha inicio no puede ser superior a la fecha de fin"
        ).typeError('Formato de fecha invalida'),
        fechaFin: Yup.date().required('Campo requerido').min(
            Yup.ref('fechaInicio'),
            "Fecha fin no puede ser inferior a la fecha de inicio"
        ).typeError('Formato de fecha invalida'),
        enlace: Yup.string().url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').max(150, 'El texto ingresado no debe sobrepasar los 150 caracteres'),
        idCategoria: Yup.string().required('Campo requerido'),
        region: Yup.string().required('Campo requerido'),
        comuna: Yup.string().required('Campo requerido'),
        direccion: Yup.string().max(250, 'El texto ingresado no debe sobrepasar los 250 caracteres'),
        condiciones: Yup.bool().isTrue('Necesita aceptar los términos y condiciones'),
        etiquetas: Yup.array().required(
            "Debe seleccionar al menos 1 etiqueta"
        ).min(1, 'Debe seleccionar al menos 1 etiqueta'),
    })

    const handleOnSubmit = (values) => {
        setLoading(true)
        const toastId = toast.loading('Cargando...');
        const data = {
            id_directorio: idDirectorio,
            nombre_convocatoria: values.nombreConvocatoria,
            descripcion: values.descripcion,
            descripcion_breve: values.descripcionBreve,
            fecha_inicio: values.fechaInicio,
            fecha_fin: values.fechaFin,
            enlace: values.enlace,
            id_categoria: values.idCategoria,
            direccion: values.direccion,
            id_comuna: values.comuna,
            etiquetas: JSON.stringify(values.etiquetas),
        }

        if (props.selectedId) {
            axiosApi.put(CONVOCATORIAS_PERF_UPDATE_URI(props.selectedId), data)
                .then(response => {
                    if (response.data.error === false) {
                        toast.success(response.data.message, {
                            id: toastId,
                        });
                        props.handleCloseForm()
                    } else {
                        toast.error("Ocurrio un error inesperado, intente nuevamente", {
                            id: toastId,
                        });
                    }


                    setLoading(false)
                }).catch(error => {
                    toast.error('Ocurrio un error al intentar editar la convocatoria, intente nuevamente', {
                        id: toastId,
                    });
                    setLoading(false)
                })
        } else {
            axiosApi.post(CONVOCATORIA_ADD_URI, data)
                .then(response => {
                    if (response.data.error === false) {
                        toast.success(response.data.message, {
                            id: toastId,
                        });
                        props.handleCloseForm()
                    } else {
                        toast.error("Ocurrio un error inesperado, intente nuevamente", {
                            id: toastId,
                        });
                    }


                    setLoading(false)
                }).catch(error => {
                    toast.error('Ocurrio un error al intentar ingresar la convocatoria, intente nuevamente', {
                        id: toastId,
                    });
                    setLoading(false)
                })
        }





    }


    return (<div >
        <div className="rb">
            <div className="rbl mb-4 col-md-12">
                {
                    (props.selectedId) ? <span>Edición convocatoria </span> : <span>Subir convocatoria </span>
                }
            </div>
        </div>


        {
            loadConvo ? <div className="mt-1 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> :
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleOnSubmit}

                >
                    {propsForm => (
                        <Form className="col-md-12 animation_input">

                            <div className="col-md-12 mb-2">
                                <label htmlFor="nombreConvocatoria" className="form-label">Nombre convocatoria <span className="rojo">*</span></label>
                                <Field type="text" className='form-control' id="nombreConvocatoria" name='nombreConvocatoria' />
                                <ErrorMessage name="nombreConvocatoria" component={ValidationError} />
                            </div>

                            <div className="col-md-12 mb-2" >
                                <label htmlFor="descripcionBreve" className="form-label">Descripción breve <span className="rojo">*</span></label>
                                <Field as="textarea" className='form-control' id="descripcionBreve" name="descripcionBreve" />
                                <ErrorMessage name="descripcionBreve" component={ValidationError} />
                            </div>

                            <div className="col-md-12 mb-2">
                                <label htmlFor="descripcion" className="form-label">Descripción <span className="rojo">*</span></label>
                                <Field as="textarea" className='form-control' id="descripcion" name="descripcion" />
                                <ErrorMessage name="descripcion" component={ValidationError} />
                            </div>

                            <div className="col-md-12 mb-2">
                                <label htmlFor="enlace" className="form-label">Enlace web</label>
                                <Field type="text" className='form-control' id="enlace" name='enlace' />
                                <ErrorMessage name="enlace" component={ValidationError} />

                            </div>


                            <div className="col-md-12 mb-2">
                                <label htmlFor="idCategoria" className="form-label">Categoria <span className="rojo">*</span></label>
                                <Field className='form-control' id="categoria" name="idCategoria" as='select' >
                                    <option value='' className="text-muted">Seleccionar</option>
                                    {
                                        !categoriasSuccess ? "" : arrayCategorias.data.map((categoria, index) => <option key={index} value={categoria.idCategoria}>
                                            {categoria.categoria}
                                        </option>)
                                    }


                                </Field>
                                <ErrorMessage name="idCategoria" component={ValidationError} />
                            </div>

                            <label htmlFor="direccion" className="form-label" >Etiquetas </label>
                            <div className="card mb-2">
                                <div className="card-header ">
                                    <span className='form-label'>Seleccionar Etiquetas</span>  (Mínimo 1 etiqueta)
                                </div>
                                <div className="card-body">
                                    <div className="row g-3">

                                        {
                                            convocatoriaArray?.data ? convocatoriaArray.data.map((etiqueta, index) => {
                                                return (
                                                    <div className="col-md-4" key={index + "etiquetas"}>
                                                        <div className="form-check">
                                                            <label className="form-check-label" >
                                                                <Field className="form-check-input" type="checkbox" name="etiquetas" value={etiqueta.idEtiquetConv} {...props} checked={propsForm.values.etiquetas.find((value) => etiqueta.idEtiquetConv == value) ? true : false} />
                                                                {etiqueta.etiquetaConv}
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            }) : ""
                                        }


                                    </div>
                                    <ErrorMessage name="etiquetas" component={ValidationError} />
                                </div>
                            </div>

                            <div className="col-md-12 mb-2">
                                <label htmlFor="fechaInicio" className="form-label">Fecha inicio<span className="rojo">*</span></label>
                                <Field name="fechaInicio" type="datetime-local" className="form-control" max={propsForm.values.fechaFin} />
                                <ErrorMessage name="fechaInicio" component={ValidationError} />
                            </div>


                            <div className="col-md-12 mb-2">
                                <label htmlFor="fechaFin" className="form-label">Fecha fin <span className="rojo">*</span></label>
                                <Field name="fechaFin" type="datetime-local" className="form-control" min={propsForm.values.fechaInicio} />
                                <ErrorMessage name="fechaFin" component={ValidationError} />
                            </div>

                            <div className="col-md-12 mb-2">
                                <label htmlFor="region" className="form-label">Región <span className="rojo">*</span></label>
                                <Field className='form-control' id="region" name="region" as='select' >
                                    <option value='' className="text-muted">Seleccionar</option>
                                    {
                                        regionesLoad ? "" : regiones.data.map((region, index) => <option key={index} value={region.idRegion}>
                                            {region.region}
                                        </option>)
                                    }


                                </Field>
                                <ErrorMessage name="region" component={ValidationError} />
                            </div>

                            <div className="col-md-12 mb-2">
                                <label htmlFor="comuna" className="form-label">Comuna <span className="rojo">*</span></label>
                                <FieldComuna className='form-control' id="comuna" name="comuna" as='select' loadcomunas={loadComunas} >
                                    <option value='' className="text-muted">Seleccionar</option>
                                    {
                                        arrayComunas.map((comuna, index) => <option key={index} value={comuna.idComuna} >
                                            {comuna.comuna}
                                        </option>)

                                    }

                                </FieldComuna>
                                <ErrorMessage name="comuna" component={ValidationError} />
                            </div>

                            <div className="col-md-12 mb-2">
                                <label htmlFor="direccion" className="form-label" >Dirección </label>
                                <Field type="text" className='form-control' id="direccion" name="direccion" placeholder="1234 Main St" />
                                <ErrorMessage name="direccion" component={ValidationError} />
                            </div>
                        
                            <div className="col-12 mb-3">
                                <div className="form-check">
                                    <Field className="form-check-input" type="checkbox" id="condiciones" name='condiciones' />
                                    <label className="form-check-label" htmlFor="condiciones">
                                        <span>He leído y acepto los términos y condiciones de las&nbsp;</span>
                                    </label>
                                    <span onClick={(e) => setOpen(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>políticas de privacidad</span>
                                </div>
                                <ErrorMessage name="condiciones" component={ValidationError} />
                            </div>

                            <div className="col-md-12 mt-5">
                                <div className="float-start"><button onClick={() => props.handleCloseForm()} className="btn btn-link btn-lg ">Cancelar</button></div>
                                <div className="float-end"><button type="submit" className="btn btn-danger btn-lg" disabled={loading} >Aceptar <FontAwesomeIcon  className='ms-1' icon={faArrowRight}></FontAwesomeIcon></button></div>

                            </div>


                        </Form>
                    )
                    }
                </Formik>

        }
        <CondicionesModal open={open} setOpen={setOpen} textId={ID_POLITICAS_CONVOCATORIAS} />
    </div>
    );
}

export default SubirConvocatoria;