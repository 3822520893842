import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import { checkIfFileVideo, checkIfFileVideo1Min,  validateVideoType } from "../../constants/utiles/Functions";

import * as Yup from 'yup';
import { toast } from "react-hot-toast";
import ValidationError from "../utiles/ValidationError";

import { Spinner } from "react-bootstrap";
import axiosApi from "../../api/axios/axiosApi";
import { PUT_VIDEO_URI, USUARIO_GET_URI_ID_DIR } from "../../api/axios/constants";
import axios from "axios";
import ReactPlayer from "react-player";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetTextoQuery } from "../../redux/features/ApiSlice";
import { ID_VIDEO_PRESENTACION } from "../../constants/Constants";

const VideoPresentacion = ({ user }) => {
    const [fileVideo, setfileVideo] = useState(null);

    const [userLoad, setUserLoad] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (user.idDirectorio) {
            axiosApi.get(USUARIO_GET_URI_ID_DIR(user.idDirectorio), { cancelToken: cancelToken.token })
                .then(response => {

                    if (response.data.error === false && response.data?.data[0]?.video_presentacion) {
                        setfileVideo(response.data.data[0].video || null);
                    }
                    setLoading(false);
                    setUserLoad(false);
        
                }).catch(error => {

                    setLoading(false)
                })
        }

        return () => {
            cancelToken.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    const initialValues = {
        video: null,

    };


    const validationSchema = Yup.object({
        video: Yup.mixed()
            .nullable()
            .test('video-tamanio', 'El archivo no puede sobrepasar los 100 mb', checkIfFileVideo)
            .test(
                'video-formato',
                'Solo se aceptan los siguientes formatos de archivo: .mp4 y .mov',
                validateVideoType
            )
            .test(
                'video-duracion',
                'El video dura más de un minuto',
                checkIfFileVideo1Min
            ),
    })

    const handleOnSubmit = (values) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');
        var formData = new FormData();
        formData.append("adjunto_data", values.video);

        axiosApi.post(PUT_VIDEO_URI(user.idDirectorio), formData).then(response => {
            if (response.data.error === false) {
                toast.success(response.data.message, {
                    id: toastId,
                });

            } else {
                toast.error('Ocurrio un error al guardar el video, intente nuevamente', {
                    id: toastId,
                });
            }

            setLoading(false)
        }).catch(error => {
            toast.error('Ocurrio un error al guardar el video, intente nuevamente', {
                id: toastId,
            });
            setLoading(false)
        })




    }

    const {
        data: texto_video,
        isSuccess
      } = useGetTextoQuery(ID_VIDEO_PRESENTACION);
    


    return (<div>

        <div className="rb">
            <div className="rbl mb-4" style={{float:'none'}}>
                <span>Video de presentación </span>
            </div>
        </div>

        {isSuccess ? <p style={{ wordBreak: "break-word", fontWeight: 300}}>{texto_video?.data[0].contenido}</p> : ''}
        
 
        <div className="clear"></div>

        {
            userLoad ? <div className="mt-1 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> :

                <div className="animation_input">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}

                    >
                        {props => (
                            <Form className="">
                                <div>
                                    <div className="row mb-2">
                                        <div className="col-sm-5" >
                                            <label htmlFor="video" className=" col-form-label ">Subir video de presentación</label>
                                            <p className="fw-lighter lh-1 mb-1 " >Formatos soportados: mp4 y mov</p>
                                            <p className="fw-lighter lh-1 mb-1 " >Tiempo limite para video: 1 min.</p>
                                        </div>
                                        <div className="col-sm-7">
                                            <input id="video" name="video" type="file" className={`form-control  ${props.errors.video ? "is-invalid" : ""}`} onChange={(event) => {
                                                props.touched.video = true;
                                                props.setFieldValue("video", event.currentTarget.files[0]);
                                                setfileVideo(URL.createObjectURL(event.target.files[0]));
                                            }} accept="video/mp4,video/quicktime" />

                                            <ErrorMessage name="video" component={ValidationError} />

                                        </div>

                                        {
                                            (props.errors.video && props.touched.video) || !fileVideo ? "" :
                                                <div className="pt-3 mx-auto " >
                                                    <ReactPlayer
                                                        url={fileVideo}
                                                        controls  
                                                        width={'100%'}              
                                                        height={'100%'}              
                                                    ></ReactPlayer>
                                                  

                                                </div>

                                        }
                                    </div>


                                </div>
                                <div className="col-12 mt-5">
                                    <button type="submit" className="btn btn-danger btn-lg" disabled={loading}>Guardar <FontAwesomeIcon className='ms-1' icon={faArrowRight}></FontAwesomeIcon> </button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div >
        }


        <div className="clear"></div>
    </div >)
}

export default VideoPresentacion;