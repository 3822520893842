
import { createApi } from '@reduxjs/toolkit/query/react'
import {
    CANTIDADES_TOTALES_URI,
    CATALOGOS_CATEGORIAS_URI,
    CATEGORIAS_GET_URI,
    COMUNAS_GET_URI,
    CONVOCATORIA_CATEGORIAS_URI,
    CONVOCATORIA_GUARDADAS_URI,
    CONVOCATORIA_GUARDARFAV_URI,
    CONVOCATORIAS_ETIQUETAS_URI,
    CRITERIOS_GET_URI,
    CRITERIOS_PDF_URI,
    DURACION_FUNCION_GET_URI,
    DURACION_TOTAL_GET_URI,
    ESPACIOS_EXPOSITIVOS_URI,
    ESPACIOS_REQUERIDOS_URI,
    FAQ_URI,
    GENEROS_GET_URI,
    GET_PERFILES_GUARDADOS_URI,
    GET_TIPO_ESPACIOS_URI,
    GET_TIPO_ORGANIZACION_URI,
    GET_TIPO_PERSONAS_URI,
    GUARDAR_PERFIL_URI,
    NOTICIAS_CATEGORIAS_URI,
    OFERTA_DEL_GUARDADAS_URI,
    OFERTA_DEL_LIKES_URI,
    OFERTA_ETIQUETAS_URI,
    OFERTA_GUARDADAS_LIKES_URI,
    OFERTA_GUARDADAS_URI,
    OFERTA_GUARDARFAV_URI,
    OFERTA_SET_LIKE,
    PERFILES_GET_URI,
    PUBLICO_URI,
    RANGO_PRECIOS_URI,
    REDES_SOCIALES_URI,
    REGIONES_GET_URI,
    TEXTOS_URI
} from '../../api/axios/constants'
import axiosBaseQuery from '../axiosBaseQuery'

export const ApiSlice = createApi({
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Convocatorias', 'Perfiles'],
    endpoints(build) {
        return {
            getFaqs: build.query({
                query: () => ({ url: `/${FAQ_URI}`, method: 'get' }),
            }),
            getCategorias: build.query({
                query: () => ({ url: `/${CATEGORIAS_GET_URI}`, method: 'get' }),
            }),
            getTipoPerfiles: build.query({
                query: () => ({ url: `/${PERFILES_GET_URI}`, method: 'get' }),
            }),
            getGeneros: build.query({
                query: () => ({ url: `/${GENEROS_GET_URI}`, method: 'get' }),
            }),

            getRegiones: build.query({
                query: () => ({ url: `/${REGIONES_GET_URI}`, method: 'get' }),
            }),
            getComunasRegion: build.query({
                query: (region) => {
                    if (region) {
                        return { url: `/${COMUNAS_GET_URI(region)}`, method: 'get' }
                    } else {
                        return []
                    }
                },
            }),
            getTotales: build.query({
                query: () => ({ url: `/${CANTIDADES_TOTALES_URI}`, method: 'get' })
            }),


            getCriterios: build.query({
                query: () => ({ url: `/${CRITERIOS_GET_URI}`, method: 'get' }),
            }),
            getDuracionFuncion: build.query({
                query: () => ({ url: `/${DURACION_FUNCION_GET_URI}`, method: 'get' }),
            }),

            getDuracionTotal: build.query({
                query: () => ({ url: `/${DURACION_TOTAL_GET_URI}`, method: 'get' }),
            }),

            getEspaciosExpositivos: build.query({
                query: () => ({ url: `/${ESPACIOS_EXPOSITIVOS_URI}`, method: 'get' }),
            }),

            getEspaciosRequeridos: build.query({
                query: () => ({ url: `/${ESPACIOS_REQUERIDOS_URI}`, method: 'get' }),
            }),
            getPrecios: build.query({
                query: () => ({ url: `/${RANGO_PRECIOS_URI}`, method: 'get' }),
            }),

            getPublico: build.query({
                query: () => ({ url: `/${PUBLICO_URI}`, method: 'get' })
            }),


            getEtiquetasOferta: build.query({
                query: () => ({ url: `/${OFERTA_ETIQUETAS_URI}`, method: 'get' })
            }),

            getEtiquetasConvo: build.query({
                query: () => ({ url: `/${CONVOCATORIAS_ETIQUETAS_URI}`, method: 'get' })
            }),
       
            getCategoriaNoticias: build.query({
                query: () => ({ url: `/${NOTICIAS_CATEGORIAS_URI}`, method: 'get' })
            }),

            getRedesSociales: build.query({
                query: () => ({ url: `/${REDES_SOCIALES_URI}`, method: 'get' })
            }),


            getCategoriaConvo: build.query({
                query: () => ({ url: `/${CONVOCATORIA_CATEGORIAS_URI}`, method: 'get' })
            }),


            getTipoPersonas: build.query({
                query: () => ({ url: `/${GET_TIPO_PERSONAS_URI}`, method: 'get' }),
            }),
            getTipoOrganizaciones: build.query({
                query: () => ({ url: `/${GET_TIPO_ORGANIZACION_URI}`, method: 'get' }),
            }),
            getTipoEspaciosCulturales: build.query({
                query: () => ({ url: `/${GET_TIPO_ESPACIOS_URI}`, method: 'get' }),
            }),

            getCategoriasCatalogo: build.query(
                { query: () => ({ url: `/${CATALOGOS_CATEGORIAS_URI}`, method: 'get' }) }
            ),


            getConvoGuardadas: build.query({
                query: (idDirectorio) => {

                    return { url: `/${CONVOCATORIA_GUARDADAS_URI(idDirectorio)}`, method: 'get' }

                },
                transformResponse: (response) => response.data.map(values => values.idConvocatoria),
                providesTags: ['Convocatorias']
            }),

            addConvoGuardadas: build.mutation({
                query: (body) => ({
                    url: `/${CONVOCATORIA_GUARDARFAV_URI}`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['Convocatorias'],

            }),
            getConvoGuardadasDetail: build.query({
                query: (idDirectorio) => {

                    return { url: `/${CONVOCATORIA_GUARDADAS_URI(idDirectorio)}`, method: 'get' }

                },
                transformResponse: (response) => response.data.map(values => values),
                providesTags: ['Convocatorias']
            }),



            getPerfilesGuardados: build.query({
                query: (idDirectorio) => {

                    return { url: `/${GET_PERFILES_GUARDADOS_URI(idDirectorio)}`, method: 'get' }

                },
                transformResponse: (response) => response.data.map(values => values.idDirectorio),
                providesTags: ['Perfiles']
            }),

            addPerfilesGuardados: build.mutation({
                query: (body) => ({
                    url: `/${GUARDAR_PERFIL_URI}`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['Perfiles'],

            }),
            getPerfilesGuardadosDetail: build.query({
                query: (idDirectorio) => {
                    return { url: `/${GET_PERFILES_GUARDADOS_URI(idDirectorio)}`, method: 'get' }
                },
                transformResponse: (response) => response.data.map(values => values),
                providesTags: ['Perfiles']
            }),

            getPdfCriterios: build.query(
                { query: () => ({ url: `/${CRITERIOS_PDF_URI}`, method: 'get' }) }
            ),


            getOfertasGuardados: build.query({
                query: (idDirectorio) => {

                    return { url: `/${OFERTA_GUARDADAS_URI(idDirectorio)}`, method: 'get' }

                },
                transformResponse: (response) => response.data,
                providesTags: ['Ofertas']
            }),

            addOfertasGuardados: build.mutation({
                query: (body) => ({
                    url: `/${OFERTA_GUARDARFAV_URI}`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['Ofertas'],

            }),
            deleteOfertasGuardados: build.mutation({
                query: (idDirectorio) => ({
                    url: `/${OFERTA_DEL_GUARDADAS_URI(idDirectorio)}`,
                    method: 'DELETE',

                }),
                invalidatesTags: ['Ofertas'],

            }),
            getOfertasGuardadosDetail: build.query({
                query: (idDirectorio) => {
                    return { url: `/${OFERTA_GUARDADAS_URI(idDirectorio)}`, method: 'get' }
                },
                transformResponse: (response) => response.data.map(values => values),
                providesTags: ['Ofertas']
            }),

          

            getLikesGuardados: build.query({
                query: (idDirectorio) => {

                    return { url: `/${OFERTA_GUARDADAS_LIKES_URI(idDirectorio)}`, method: 'get' }

                },
                transformResponse: (response) => response.data,
                providesTags: ['Likes']
            }),

            addLikesGuardados: build.mutation({
                query: (body) => ({
                    url: `/${OFERTA_SET_LIKE}`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['Likes'],

            }),
            deleteLikesGuardados: build.mutation({
                query: (idLike) => ({
                    url: `/${OFERTA_DEL_LIKES_URI(idLike)}`,
                    method: 'DELETE',

                }),
                invalidatesTags: ['Likes'],

            }),

            getTexto: build.query({
                query: (idDirectorio) => ({ url: `/${TEXTOS_URI(idDirectorio)}`, method: 'get' }),
            }),

            
        }
    },
})


export const {
    useGetFaqsQuery,
    useGetRegionesQuery,
    useGetCategoriasQuery,
    useGetTipoPerfilesQuery,
    useGetGenerosQuery,
    useGetComunasRegionQuery,
    useGetTotalesQuery,
    useGetCriteriosQuery,
    useGetDuracionFuncionQuery,
    useGetDuracionTotalQuery,
    useGetEspaciosExpositivosQuery,
    useGetEspaciosRequeridosQuery,
    useGetPublicoQuery,
    useGetCategoriaNoticiasQuery,
    useGetRedesSocialesQuery,
    useGetCategoriaConvoQuery,
    useGetPreciosQuery,
    useGetTipoPersonasQuery,
    useGetTipoOrganizacionesQuery,
    useGetTipoEspaciosCulturalesQuery,
    useGetConvoGuardadasQuery,
    useAddConvoGuardadasMutation,
    useGetConvoGuardadasDetailQuery,
    useGetPerfilesGuardadosQuery,
    useAddPerfilesGuardadosMutation,
    useGetPerfilesGuardadosDetailQuery,
    useGetCategoriasCatalogoQuery,
    useGetPdfCriteriosQuery,
    useGetEtiquetasConvoQuery,
    useGetOfertasGuardadosQuery,
    useAddOfertasGuardadosMutation,
    useDeleteOfertasGuardadosMutation,
    useGetOfertasGuardadosDetailQuery,
    useGetEtiquetasOfertaQuery,
    useGetLikesGuardadosQuery,
    useAddLikesGuardadosMutation,
    useDeleteLikesGuardadosMutation,
    useGetTextoQuery
} = ApiSlice;