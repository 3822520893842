import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { truncateString } from "../../../constants/utiles/Functions";
import Image from "../../utiles/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
const OfertaItem = ({ id, idOferta, img, nombre, agente, categoria, region, deleteOferta }) => {



    return (
        <div className="col-md-4 mb-4 animation_input" style={{ minHeight: "340px" }} >
            <div className="oferta rounded">
                <Image src={img} className={"rounded-top"}></Image>

                <div className="ic">
                    <Link to={`/oferta/${idOferta}`} style={{ textDecoration: "none" }}>
                        <span className="tipoc1 rounded">{agente}</span>
                        <div className="clear"></div>
                        <span className="tc1">{truncateString(nombre, 45)}</span>
                        <span className="pc1">{categoria}</span>
                        <span className="rc1">{region}</span>
                    </Link>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button className="btn btn-danger btn-sm" onClick={(e) => deleteOferta(id)} title="Quitar oferta de la programación"><FontAwesomeIcon icon={faTrash} /> Quitar</button>

                    </div>


                </div>

            </div>

        </div>
    );
}

export default OfertaItem;

OfertaItem.propTypes = {
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    agente: PropTypes.string.isRequired,
    categoria: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
}

OfertaItem.defaultProps = {
    id: 0,
    img: '',
    nombre: '',
    agente: '',
    categoria: '',
    region: ''
}