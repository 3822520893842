import { Link, createSearchParams, useNavigate } from "react-router-dom";
import Map from "../utiles/Map";
import { ID_AMPLIFICACION, ID_DIMENSIONES_OTRO, ID_ILUMINACION, ID_OTRO, ID_REPRO_VIDEO_CINE } from "../../constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowPointer, faEnvelope, faFileLines, faSave, faTrashAlt, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useAddLikesGuardadosMutation, useAddOfertasGuardadosMutation, useDeleteOfertasGuardadosMutation, useGetLikesGuardadosQuery, useGetOfertasGuardadosQuery } from "../../redux/features/ApiSlice";
import { Fragment, useState } from "react";
import SesionModal from "../convocatoriaAssets/SesionModal";

const OfertaDetalles = ({ oferta }) => {

    const { idDirectorio } = useSelector((state) => state.userActive);

    let skip = false;
    if (!idDirectorio) {
        skip = true;
    }
    const { data = [] } = useGetOfertasGuardadosQuery(idDirectorio, { skip });
    const [addOfertasGuardados] = useAddOfertasGuardadosMutation();
    const [deleteOfertaGuardados] = useDeleteOfertasGuardadosMutation();
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleGuardarOferta = () => {

        if (idDirectorio) {
            const toastId = toast.loading('Cargando...');
            addOfertasGuardados({
                id_oferta: oferta.idOferta,
                id_directorio: idDirectorio
            }).unwrap().then((response) => {
                toast.success(response.message, {
                    id: toastId,
                })
            }).catch(error => {
                toast.error(error.message, {
                    id: toastId,
                })
            });
        } else {
            setOpen(true)
        }


    }

    const handleDeleteOferta = (Programacion) => {
        if (idDirectorio) {
            const toastId = toast.loading('Cargando...');
            deleteOfertaGuardados(Programacion.idProgramacion).unwrap().then((response) => {
                toast.success(response.message, {
                    id: toastId,
                })
            }).catch(error => {
                toast.error(error.message, {
                    id: toastId,
                })
            });
        } else {
            setOpen(true)
        }


    }


    const handleSubmit = (id) => {
        navigate({
            pathname: "/busqueda",
            search: createSearchParams({ etiqueta: id }).toString()
        });

    }



    return (<div className="cont-not">
        <div className="row">
            <div className="col-md-6 mt-4">
                <p><strong className="mb-2">Precio de la oferta</strong> {oferta.precio} {oferta?.contemplaIva !== null ? oferta?.contemplaIva === true ? "(Contempla IVA)" : "(Exento de IVA)" : ""}<br />
                    Incluye producción: {oferta.precioIncluyeProduccion ? "Sí" : "No"}</p>

                <p><strong>Público</strong> {oferta.publicoDirigidio}</p>

                <p><strong>Duración de la oferta</strong> {oferta.duracionOferta}</p>

                <p><strong>Permanencia de la oferta</strong> {oferta.permanenciaOferta}</p>

                <p><strong>Pasajeros</strong> {oferta.pasajeros ? "Sí" : "No"}</p>

                <p><strong>Carga</strong> {oferta.carga ? <Fragment>Sí <br></br>{oferta.cargaPeso}</Fragment> : "No"}
                </p>

                <p><strong>Espacio</strong> {oferta.idEspacioEspositivo == ID_DIMENSIONES_OTRO ? oferta.expositivoOtro : oferta.espacio}</p>

                <p><strong>Cuenta con actividad de mediación</strong> {oferta.mediacion ? <Fragment>Sí <br></br>{oferta.mediacionDetalle}</Fragment> : "No"}</p>

                <p><strong>Requiere reproducción de video/cine</strong> {oferta.espacioRequeridos.find(i => i.idespacio == ID_REPRO_VIDEO_CINE) ? "Sí" : "No"}</p>

                <p><strong>Requiere iluminación</strong> {oferta.espacioRequeridos.find(i => i.idespacio == ID_ILUMINACION) ? "Sí" : "No"}</p>

                <p><strong>Requiere amplificación</strong> {oferta.espacioRequeridos.find(i => i.idespacio == ID_AMPLIFICACION) ? "Sí" : "No"}</p>

                <p><strong>Requiere otro</strong> {oferta.espacioRequeridos.find(i => i.idespacio == ID_OTRO) ? <span>Sí <br /> {oferta.espacioRequeridoOtro}</span> : "No"}</p>

                {
                    oferta.dossier_pdf ?
                        <p><strong>Documento dossier</strong>
                            <FontAwesomeIcon className={'mx-1'} icon={faFileLines} /> <a href={oferta.dossier_pdf} target={'_blank'} rel="noreferrer"> Descargar
                            </a></p> : ""
                }

                {
                    oferta.videoPromocional ?
                        <p><strong>Link video promocional youtube</strong>
                            <FontAwesomeIcon className={'mx-1'} icon={faVideoCamera} /> <a href={oferta.videoPromocional} target={'_blank'} rel="noreferrer"> Ver
                            </a></p> : ""
                }
            </div>


            <div className="col-md-6 mt-4">
                {oferta.listadoParticipantes.length > 0 ?

                    <div><strong className="mb-2">Participantes</strong> {oferta.participantes} <br />
                        <ul className="participantes">
                            {oferta.listadoParticipantes.map((values, index) =>
                                <li key={index}>{values.nombreParticipante} {values.cargo ? `/ ${values.cargo}` : ""}
                                    {values.web ? <a href={values.web} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faArrowPointer} /></a> : ""}
                                    {values.facebook ? <a href={values.facebook} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a> : ""}
                                    {values.instagram ? <a href={values.instagram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a> : ""}
                                </li>
                            )}

                        </ul>
                    </div> : " "}



                <p><strong>Ubicación de la oferta</strong> <br />
                    {oferta.comuna}, {oferta.region} <br /></p>
                <div className="mb-3">
                    <Map direccion={`comuna de ${oferta.comuna}, ${oferta.region}`}></Map>
                </div>



                {oferta.etiquetas.length > 0 ?

                    <div><strong >Etiquetas</strong> <br />
                        <div className="mt-2">
                            {oferta.etiquetas.map((values, index) =>
                                <span onClick={(e) => handleSubmit(values.idEtiqueta)} className="badge rounded-pill  me-1 " style={{ backgroundColor: "#dc3545", cursor: "pointer" }} key={index + "etiquetas"}>{values.etiqueta}</span>

                            )}
                        </div>
                    </div> : " "}

            </div>

            <div className="col-12 mt-4">
                <ul className="of-bots">
                    <li><Link className="btn rounded of-cont inicio" to={`/detalle-usuario/${oferta.idDirectorio}`} >Contactar <FontAwesomeIcon className='ms-1' icon={faEnvelope}></FontAwesomeIcon> </Link></li>
                    {
                        data.length > 0 && data.find(element => { return (element.idOferta == oferta.idOferta) }) ?
                            <li><button className="btn rounded of-cont boton-ofertas inicio" onClick={(e) => handleDeleteOferta(data.find(element => { return (element.idOferta == oferta.idOferta) }))}>Eliminar de programación <FontAwesomeIcon className='ms-1' icon={faTrashAlt}></FontAwesomeIcon></button></li> :

                            <li><button className="btn rounded of-cont boton-ofertas inicio" onClick={handleGuardarOferta}>Agregar a programación <FontAwesomeIcon className='ms-1' icon={faSave}></FontAwesomeIcon></button></li>
                    }

                </ul>
            </div>
        </div>
        <SesionModal open={open} setOpen={setOpen} />
    </div>



    )
}

export default OfertaDetalles;